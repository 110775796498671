/* eslint-disable react/no-array-index-key */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useNavigate } from 'react-router';

import {
  OrderVoidTypeEnum,
  OutboundSectionSettingLockOrderEditsAfterEnum,
} from 'common/enums';
import { DropdownListItemType } from 'common/models';
import { OrderChecklistModel } from 'common/models/orders/orderchecklists';
import { snackActions } from 'config/snackbar.js';
import useQuery from 'helpers/useQuery/useQuery';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import warehousecustomer from 'pages/ordercustomers/warehousecustomer';
import { ItemUnitOfMeasureType } from 'pages/receiving/receipts/subpage';
import { FormTemplateTypeEnum } from 'pages/shared/printformtemplate';
import {
  getCarrierDDL,
  getCartonCodeDDL,
  getCartonCodes,
} from 'services/api/cartoncodes/cartoncodes.api';
import {
  getOrderCarriers,
  getOrderCarrierservices,
} from 'services/api/integrations/shipment/shipment.api';
import {
  getCustomersLookup,
  getFacilitiesWithLocationByCustomerId,
  getCountriesLookup,
  getOrderBillingsLookup,
  getWarehouseCustomerShippingAccountsLookup,
  getGenerateOrderNo,
  getSkuLookup,
  getPrevAddressesLookup,
  getOrderContactDetail,
  getOrderDetail,
  getOrderPickTicketLineItemListNew,
  updateSalesOrder,
  createSalesOrder,
  createOrderPickTicket,
  changeStatusSalesOrder,
  duplicateOrderLineItem,
  cancelOrderLineItem,
  deleteOrderLineItem,
  deleteOrderPickTicketItem,
  refreshSalesOrderInventory,
  getPickTicketDetail,
  getShipmentLabels,
  updateOrderPickTicket,
  backOrderForExisting,
  getSkuLookupWithwarehouseCustomer,
  getOrderNotes,
  getOrderSourceList,
} from 'services/api/salesorders/salesorders-new.api';
import { getUserOperators } from 'services/api/user/users.api';
import { getBillingChargesDetailByModule } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import { OrderLineItemTabValue } from '..';

import { ChecklistModal, CompleteOrderManuallyModal } from '../models';

type AutocompleteBase = {
  packageType?: string;
  label?: string;
  value?: number;
  dimensions?: string;
  isExternalPackage?: boolean;
  carrier?: string;
  serviceCode?: string;
};
export type CustomerType = AutocompleteBase;
export type FacilityType = AutocompleteBase;
export type CountryType = AutocompleteBase;
export type OrderBillingType = AutocompleteBase;
export type CartonCodeDDLType = AutocompleteBase;
export type WarehouseCustomerShippingAccountType = AutocompleteBase;
export type ShipToSearchPrevAddressType = AutocompleteBase;
export type BillToSearchPrevAddressType = AutocompleteBase;
export type OrderSourceType = {
  label?: string;
  value?: string;
};

type ExtendSkuType = {
  description: string;
};
type QueryStringType = {
  form: string;
  id: number;
  isReturn?: boolean;
};
type SkuType = ExtendSkuType & AutocompleteBase;

type ExtendCarrierType = {
  isShippingRate: boolean;
  serviceCode?: string;
};
export type CarrierType = ExtendCarrierType & AutocompleteBase;

export interface AutoCompleteOptionType {
  value?: number;
  label?: string;
}

type PageViewerType = {
  isNew: () => boolean;
  isView?: () => boolean;
  isEdit?: () => boolean;
  isViewOrEdit: () => boolean;
};
enum FormQueryStringStatus {
  View = 'view',
  New = 'new',
  Edit = 'edit',
}
export enum OrderStatus {
  New = 1,
  Open = 2,
  Picked = 3,
  Shipment = 4,
  Complete = 5,
  Partial = 6,
  Cancelled = 7,
  Return = 10,
  ReadyForPickup = 11,
}
export enum UOMType {
  Primary = 1,
  Other = 2,
  Pallet = 3,
}
enum ModalTitleStatus {
  Add = 'Add',
  Edit = 'Edit',
  Delete = 'Delete',
}
interface IFlagStatusType {
  status: number;
  statusStr: string;
  isHold: boolean;
  isBackOrder: boolean;
  isPartial: boolean;
  isReturn: boolean;
  statusProgress: number;
  statusProgressTexts: string[];
  statusProgressPercent: number;
}
export interface IContactDetailType {
  orderContactDetailId?: number;
  contactDetailType?: number;
  codeId?: string;
  recipientName?: string;
  companyName?: string;
  phone?: string;
  email?: string;
  address1?: string;
  address2?: string;
  country?: number;
  countryStr?: string;
  stateProvinceRegion?: string;
  cityTownship?: string;
  zipPostalCode?: string;
  retailerId?: string;
  departmentNumber?: string;
}
interface ILineItemType {
  lineId?: number | string;
  itemId?: number | string | null;
  sku?: string;
  description?: string;
  primaryUOM: number;
  primaryUOMAbbr: string;
  itemUnitOfMeasureId?: number;
  otherUOMStr: string;
  otherUOMAbbr: string;
  otherUOMCount: number;
  uomType: number;
  qty?: number | string;
  totalCases?: number | string;
  qtyShipped?: number;
  statusStr?: string;
  onHand?: number;
  available?: number;
  backOrder?: string;
  backOrderDateTime?: any;
  systemNote?: string;
  lotId?: number;
  lotNo?: string;
  lotExpiration?: any;
  sellPrice?: any;

  // Kit on Demand
  isKitOnDemand: boolean;
  isKitOnDemandComponent: boolean;
  orderLineItemKitOnDemandId?: number | string;
  itemKitOnDemandId?: number;
  itemKitOnDemandQty?: number;
  // for new order line item. by default we don't have id yet
  // create random at front end. this should match the value at OrderLineItemKitOnDemandId
  tempOrderLineItemId?: number | string;

  // Serial No
  isSerialNo: boolean;
  serialNumbers: string[];

  // Order By LPN
  isOrderByLPN: boolean;
  palletInventoryId?: number;
  lpn?: string;
  packagingType?: number;
}

interface IPickTicketType {
  pickTicketId?: number;
  pickId?: string;
  status?: number;
  statusStr?: string;
  issues?: string;
}

interface IPickTicketItemType {
  orderLineItemId?: number;
  sku?: string;
  description?: string;
  uom?: number;
  uomStr?: string;
  quantity?: number;
}

interface IPackageLineItemType {
  packageLineItemId?: number;
  sku?: string;
  description?: string;
  serialNo?: string;
  uom?: string;
  lotNo?: string;
  lotExpirationDate?: string;
  qty?: number;
}

interface IPackageType {
  packageId?: number;
  packageCount?: number;
  cartonCodeName?: string;
  length?: string;
  width?: string;
  height?: string;
  weight?: string;
  tracking?: string;
  trackingUrl?: string;
  labelUrl?: string;
  orderPackagesLineItems?: IPackageLineItemType[] | [];
}

interface IPackagePalletsType {
  palletId?: number;
  palletLength?: number;
  palletHeight?: number;
  palletWidth?: number;
  palletWeight?: number;
  palletCubicDimension?: number;
  boxes?: string;
}

interface ISalesOrderFormType {
  orderId?: number;
  invoiceId?: number;
  customerId?: number;
  customerLocationId?: number;
  customerFacilityId?: number;
  warehouseCustomerId?: number;
  onHold?: boolean;
  orderNo?: string;
  returnNo?: string;
  referenceNo?: string;
  orderSource?: string;
  purchaseOrder?: string;
  backOrderDateTime?: any;
  creationDate?: any;
  creationTime?: any;
  creationDateTime?: any;
  shipByDateTime?: any;
  cancelByDateTime?: any;
  refreshDateTime?: any;
  shipTo?: IContactDetailType;
  billTo?: IContactDetailType;
  useShipToDetailsForBillTo?: boolean;
  carrier?: string;
  warehouseCustomerShippingAccountId?: number;
  accountZipPostalCode?: string;
  deliveryType?: string;
  shippingCost?: number;
  trackingNo?: string;
  billingType?: number;
  scacCode?: string;
  shipDate?: Date;
  warehouseNotes?: string;
  carrierNotes?: string;
  subtotalLineItems?: string;
  totalLineItems?: string;
  lineItems?: ILineItemType[];
  pickTickets?: IPickTicketType[];
  orderBillingChargeSectionAdhocs?: any;
  isShippingRate?: boolean;
  carrierId?: number;
  isLabelProvided: boolean;
  itemAsPackageId?: number;
  itemAsPackageName?: string;
  assignedByUserId?: number;
  assignedByUserName?: string;
  orderPackages?: IPackageType[] | [];
  orderPallets?: IPackagePalletsType[] | [];
  isReturn?: boolean;
  returnOriginalOrderId?: number;
  originalOrderNo?: string;
  createReturnButtonVisible: boolean;
  returnOverrideMaximumReturnDays: boolean;
  returnMaxAllowedReturnDays?: number;
  carrierService: string;
  carrierPredefinedPackage?: string;
  completeDateTime?: string;
  isAllPickTicketComplete: boolean;
  isOrderLineItemQtyMatchPickTicketLineItemQty: boolean;
  totalShipmentCost?: number;
  shipmentCost?: number;
  orderChecklists: OrderChecklistModel[];
  isAddressInvalid?: boolean;
  channel?: string;
  lockOrderEditsAfter?: OutboundSectionSettingLockOrderEditsAfterEnum;
  isFba?: string;
  isPrime?: string;
  latestDeliveryDate?: Date;
  integrationChannel?: string;
}
interface ISalesOrderType {
  apiBackOrderResponse: any;
  modalTitle: string;
  isFormDisable: boolean;
  isButtonDisable: boolean;
  isOnHoldValue: boolean;
  isShipByDateHasValue: boolean;
  pageViewer: PageViewerType;
  currentUser: any;
  optionCustomers: CustomerType[];
  optionOrderSource: OrderSourceType[];
  optionFacilities: FacilityType[];
  optionCountries: CountryType[];
  optionSkus: SkuType[];
  orderItemRows: ILineItemType[];
  orderPickTicketItemRows: IPickTicketType[];
  orderPickTicketItemModalRows: IPickTicketItemType[];
  optionOrderBillings: OrderBillingType[];
  optionCarriers: CarrierType[];
  carrierValue: CarrierType;
  setCarrierValue: React.Dispatch<React.SetStateAction<CarrierType>>;
  carrierServiceValue: CarrierType;
  setCarrierServiceValue: React.Dispatch<React.SetStateAction<CarrierType>>;
  setOrderNotesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  loadOrderNotes: (id?: number) => void;
  orderNotes: any[];
  orderNotesModalOpen: boolean;
  optionWarehouseCustomerShippingAccounts: WarehouseCustomerShippingAccountType[];
  salesOrderForm: ISalesOrderFormType;
  salesOrderFlagStatus: IFlagStatusType;
  salesOrderFormErrors: any;
  singleOrderLineItemRow: any;
  singleOrderPickTicketItemRow: any;
  setOrderPickTicketItemModalRows: any;
  setSingleOrderPickTicketItemRow: any;
  billingAdhocChargesData: any;
  loadBillingChargesDetailByModule: any;
  errorBillingAdhocItemValues: any;
  handleChangeBillingChargeSection: any;
  handleRemoveBillingChargeSection: any;
  dataBillingChargeSectionSelected: any;
  viewLoadData: any;
  setIsBillingChargeDeleteHasIdOrder: any;
  onLoadSkuLookupWithwarehouseCustomer: any;
  setOptionSkus: any;
  createSalesOrderOnClick: (e: React.SyntheticEvent) => void;
  printFormTemplateOnClick: (
    e?: React.SyntheticEvent,
    formType?: FormTemplateTypeEnum,
  ) => Promise<void>;
  createOrderPickTicketNewOnClick: (e: React.SyntheticEvent) => void;
  createOrderPickTicketAndChangeStatusOnClick: (
    e: React.SyntheticEvent,
  ) => void;
  setOptionsUOM: React.Dispatch<React.SetStateAction<ItemUnitOfMeasureType[]>>;
  optionsUOM: ItemUnitOfMeasureType[];
  setUomValue: React.Dispatch<React.SetStateAction<ItemUnitOfMeasureType>>;
  uomValue: ItemUnitOfMeasureType;

  updateOrderPickTicketAndChangeStatusOnClick: (
    e: React.SyntheticEvent,
  ) => void;

  // operator
  optionOperator: any;
  setOptionOperator: any;
  operatorValue: any;
  setOperatorValue: any;

  changeStatusSalesOrderOnClick: (e: React.SyntheticEvent, id: number) => void;
  deleteOrderPickTicketOnClick: (e: React.SyntheticEvent, id: number) => void;
  duplicateOrderLineItemOnClick: (e: React.SyntheticEvent, row: any) => void;
  cancelOrderLineItemOnClick: (e: React.SyntheticEvent, id: number) => void;
  deleteOrderLineItemOnClick: (e: React.SyntheticEvent, id: number) => void;
  refreshSalesOrderInventoryOnClick: (e: React.SyntheticEvent) => void;
  setSalesOrderForm: React.Dispatch<React.SetStateAction<ISalesOrderFormType>>;
  setOrderItemRows: React.Dispatch<React.SetStateAction<ILineItemType[]>>;
  setOrderCreationOfPickTicketItemRows: React.Dispatch<
    React.SetStateAction<IPickTicketItemType[]>
  >;
  setModalTitle: React.Dispatch<React.SetStateAction<string>>;
  setButtonDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setApiBackOrderResponse: React.Dispatch<React.SetStateAction<any>>;
  setSingleOrderLineItemRow: React.Dispatch<React.SetStateAction<any>>;
  setQueryStringItemOrders: React.Dispatch<
    React.SetStateAction<QueryStringType>
  >;
  queryStringItemOrders: QueryStringType;
  setIsOnHoldValue: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShipByDateHasValue: React.Dispatch<React.SetStateAction<boolean>>;
  salesOrderFormOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoCompleteOnChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: AutocompleteBase,
    custom?: any,
  ) => void;

  setTemporaryOrderId: React.Dispatch<React.SetStateAction<number>>;
  temporaryOrderId: number;
  setReturnOrderItemRows: React.Dispatch<
    React.SetStateAction<[] | ILineItemType[]>
  >;
  returnOrderItemRows: [] | ILineItemType[];
  isPickTicketEdit: any;
  setIsPickTicketEdit: any;
  setReturnQuantityLineItem: React.Dispatch<any>;
  returnQuantityLineItem: any;
  setPackageCartonCodeValue: React.Dispatch<
    React.SetStateAction<AutocompleteBase>
  >;
  setOrderSourceValue: React.Dispatch<React.SetStateAction<OrderSourceType>>;
  orderSourceValue: OrderSourceType;
  onLoadOrderCarrierServices: (carrierId: any) => any;
  optionCarrierServices: [] | CarrierType[];
  packageCartonCodeValue: AutocompleteBase;
  setOrderPackageLineItems: React.Dispatch<
    React.SetStateAction<[] | IPackageLineItemType[]>
  >;
  orderPackageLineItems: [] | IPackageLineItemType[];
  setOrderPackageName: React.Dispatch<React.SetStateAction<string>>;
  orderPackageName: string;
  valueSecondTab: string;
  handleChangeSecond: (event: React.SyntheticEvent, newValue: string) => void;
  setFormTemplateType: React.Dispatch<
    React.SetStateAction<FormTemplateTypeEnum>
  >;
  formTemplateType: FormTemplateTypeEnum;

  // Checklist
  checklistModal: ChecklistModal;
  setChecklistModal: React.Dispatch<React.SetStateAction<ChecklistModal>>;

  // Void the Pack
  voidType: OrderVoidTypeEnum;
  setVoidType: React.Dispatch<React.SetStateAction<OrderVoidTypeEnum>>;
  isVoidThePackModal: boolean;
  setIsVoidThePackModal: React.Dispatch<React.SetStateAction<boolean>>;

  // Serial No
  optionsSerialNumber: DropdownListItemType<string>[];
  setOptionsSerialNumber: React.Dispatch<
    React.SetStateAction<DropdownListItemType<string>[]>
  >;
  serialNumbersValue: DropdownListItemType<string>[];
  setSerialNumbersValue: React.Dispatch<
    React.SetStateAction<DropdownListItemType<string>[]>
  >;
}
// ----------------------------------------

export {
  type ILineItemType,
  type IPickTicketItemType,
  FormQueryStringStatus,
  ModalTitleStatus,
};
type SalesOrderProviderProps = {
  children: React.ReactNode;
};

const SalesOrderContext = createContext<ISalesOrderType>({} as ISalesOrderType);

export const useSalesOrderContext = () => useContext(SalesOrderContext);

export const SalesOrderContextProvider = ({
  children,
}: SalesOrderProviderProps) => {
  const navigate = useNavigate();
  const query = useQuery();
  const initialStateOfSalesOrderForm: ISalesOrderFormType = {
    // customerFacilityId: 0,
    // warehouseCustomerId: 0,
    // orderNo: undefined || '',
    // referenceNo: undefined || '',
    creationDate: new Date(),
    creationTime: moment(new Date()).format('HH:mm'),
    useShipToDetailsForBillTo: false,
    shipTo: { contactDetailType: 1 },
    billTo: {
      contactDetailType: 2,
    },
    lineItems: [],
    carrierService: null,
    createReturnButtonVisible: true,
    returnOverrideMaximumReturnDays: false,
    returnMaxAllowedReturnDays: null,
    isAllPickTicketComplete: false,
    isOrderLineItemQtyMatchPickTicketLineItemQty: false,
    orderChecklists: [],
    isLabelProvided: false,
  };
  const initialStateOfQueryParams: QueryStringType = {
    form: query.get('form'),
    id: Number(query.get('id')),
    isReturn: Boolean(query.get('isReturn')),
  };

  // #region All useContexts
  const {
    onOpenPickTicketSalesOrderModal,
    onClosePickTicketSalesOrderModal,
    onOpenPrintFormTemplateModal,
    onOpenConfirmDeleteDialog,
    updateLoading,
    setCompleteOrderManuallyModal,
  } = useContext(GlobalContext);

  const { currentUser, outboundSectionSettings, isWarehouseCustomerAccount } =
    useContext(AuthContext);
  // #endregion

  // #region All useStates
  const [optionCustomers, setOptionCustomers] = useState<
    CustomerType[] | null | []
  >([]);

  const [optionOrderSource, setOptionOrderSource] = useState<
    OrderSourceType[] | null | []
  >([]);
  const [optionFacilities, setOptionFacilities] = useState<
    CustomerType[] | null | []
  >([]);

  const [optionCountries, setOptionCountries] = useState<
    CountryType[] | null | []
  >([]);

  const [optionOrderBillings, setOptionOrderBillings] = useState<
    OrderBillingType[] | null | []
  >([]);

  const [optionCarriers, setOptionCarriers] = useState<
    CarrierType[] | null | []
  >([]);
  const [optionCarrierServices, setOptionCarrierServices] = useState<
    CarrierType[] | null | []
  >([]);
  const [carrierValue, setCarrierValue] = useState<CarrierType>(null);
  const [carrierServiceValue, setCarrierServiceValue] =
    useState<CarrierType>(null);
  const [orderNotesModalOpen, setOrderNotesModalOpen] = useState(false);
  const [orderNotes, setOrderNotes] = useState([]);

  const [
    optionWarehouseCustomerShippingAccounts,
    setOptionWarehouseCustomerShippingAccounts,
  ] = useState<WarehouseCustomerShippingAccountType[] | null | []>([]);

  const [optionSkus, setOptionSkus] = useState<SkuType[] | null | []>([]);
  const [salesOrderForm, setSalesOrderForm] = useState<ISalesOrderFormType>(
    initialStateOfSalesOrderForm,
  );
  const [salesOrderFlagStatus, setSalesOrderFlagStatus] =
    useState<IFlagStatusType>({} as IFlagStatusType);
  const [salesOrderFormErrors, setSalesOrderFormErrors] = useState({});
  const [orderItemRows, setOrderItemRows] = useState<ILineItemType[] | []>([]);
  const [orderPickTicketItemRows, setOrderPickTicketItemRows] = useState<
    IPickTicketType[] | []
  >([]);
  const [shipmentLabelRows, setShipmentLabelRows] = useState<[]>([]);
  const [
    orderCreationOfPickTicketItemRows,
    setOrderCreationOfPickTicketItemRows,
  ] = useState<IPickTicketItemType[] | []>([]);

  const [orderPickTicketItemModalRows, setOrderPickTicketItemModalRows] =
    useState<IPickTicketItemType[] | []>([]);
  const [queryStringItemOrders, setQueryStringItemOrders] = useState(
    initialStateOfQueryParams,
  );
  const [isFormDisable, setIsFormDisable] = useState<boolean>(false);
  const [isButtonDisable, setButtonDisable] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string | null>(null);
  const [singleOrderLineItemRow, setSingleOrderLineItemRow] =
    useState<any>(null);
  const [singleOrderPickTicketItemRow, setSingleOrderPickTicketItemRow] =
    useState<any>(null);
  const [apiBackOrderResponse, setApiBackOrderResponse] = useState<any>(null);
  const [isOnHoldValue, setIsOnHoldValue] = useState<boolean>(false);
  const [isShipByDateHasValue, setIsShipByDateHasValue] =
    useState<boolean>(true);

  // operator
  const [operatorValue, setOperatorValue] = useState<AutoCompleteOptionType>({
    value: null,
    label: '',
  });

  // return
  const [temporaryOrderId, setTemporaryOrderId] = useState<number>(null);
  const [returnOrderItemRows, setReturnOrderItemRows] = useState<
    ILineItemType[] | []
  >([]);
  const [returnQuantityLineItem, setReturnQuantityLineItem] =
    useState<any>(null);

  //-----------
  const [optionOperator, setOptionOperator] = useState([]);

  const [isPickTicketEdit, setIsPickTicketEdit] = useState<boolean>(false);

  const [optionsUOM, setOptionsUOM] = useState<ItemUnitOfMeasureType[]>([]);
  const [uomValue, setUomValue] = useState<ItemUnitOfMeasureType>(null);

  const [packageCartonCodeValue, setPackageCartonCodeValue] =
    useState<CartonCodeDDLType>(null);

  const [orderSourceValue, setOrderSourceValue] =
    useState<OrderSourceType>(null);

  const [orderPackageLineItems, setOrderPackageLineItems] = useState<
    IPackageLineItemType[] | []
  >([]);
  const [orderPackageName, setOrderPackageName] = useState('');

  // TEMPLATE FORMS -----------------------------------------------------------------

  const [formTemplateType, setFormTemplateType] =
    useState<FormTemplateTypeEnum | null>(null);

  // Billing Charge Section - Adhoc -------------------------------------------------------------------------

  const [valueSecondTab, setValueSecondTab] = React.useState('1');
  const handleChangeSecond = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setValueSecondTab(newValue);
  };

  const initialStateOfBillingChargeSection = [
    {
      warehouseCustomerBillingChargeSectionId: '',
      warehouseCustomerAdhocChargeId: '',
      chargeName: '',
      rate: '',
      qty: '',
      total: '',
      notes: '',
    },
  ];

  const [billingAdhocChargesData, setBillingAdhocChargesData] = useState([]);
  const [errorBillingAdhocItemValues, setErrorBillingAdhocItemValues] =
    useState(initialStateOfBillingChargeSection);

  const [
    dataBillingChargeSectionSelected,
    setDataBillingChargeSectionSelected,
  ] = useState({
    warehouseCustomerBillingChargeSectionId: '',
  });

  const [isBillingChargeDeleteHasIdOrder, setIsBillingChargeDeleteHasIdOrder] =
    useState<boolean>(false);

  const loadBillingChargesDetailByModule = async (warehouseCustomerId) => {
    const result = await getBillingChargesDetailByModule(
      warehouseCustomerId,
      'Adhoc',
      'Order',
    );

    if (!isWarehouseCustomerAccount) {
      setBillingAdhocChargesData(
        result.adhocCharges.map((v: any) => ({
          warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
          chargeName: v.chargeName,
          rate: v.rate,
          required: v.required,
        })),
      );
    }
  };

  const validateBillingCharge = (selectedItemId = null) => {
    const temp: any = {};

    let tempBillingAdhocChargesData = billingAdhocChargesData;

    if (selectedItemId != null) {
      tempBillingAdhocChargesData = tempBillingAdhocChargesData.filter(
        (x) => x.warehouseCustomerAdhocChargeId === selectedItemId,
      );
    }

    temp.billingAdhocChargesData = [];
    tempBillingAdhocChargesData.forEach((element, index) => {
      const TempData = {
        rate: element.rate ? '' : 'This field is required',
        qty: element.qty ? '' : 'This field is required',
      };

      if (!element.required) {
        TempData.rate = '';
        TempData.qty = '';
      }

      temp.billingAdhocChargesData = [
        ...temp.billingAdhocChargesData,
        TempData,
      ];
    });

    setErrorBillingAdhocItemValues({
      ...temp.billingAdhocChargesData,
    });

    return temp.billingAdhocChargesData.every((e) =>
      Object.values(e).every((x) => x === ''),
    );
  };

  const handleChangeBillingChargeSection = (e, index) => {
    const clone = [...billingAdhocChargesData];

    const obj = clone[index];
    if (e.target.name === 'rate') {
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      const validateDecimal = decimalValidationHelper(e.target.value);

      obj.rate = validateDecimal.trim();
    }
    if (e.target.name === 'qty') {
      if (e.target.value < 0) {
        e.target.value = 0;
      }
      const validateDecimal = decimalValidationHelper(e.target.value);

      obj.qty = validateDecimal.trim();
    }

    if (e.target.name === 'notes') {
      obj.notes = e.target.value;
    }

    if (
      (obj.rate !== null || obj.rate !== '') &&
      (obj.qty !== null || obj.qty !== '')
    ) {
      const checkRate = obj.rate.includes('$')
        ? obj.rate.replace('$', '')
        : obj.rate;

      obj.total = `$${(Number(checkRate) * Number(obj.qty)).toFixed(2)}`;
    } else {
      obj.total = '0';
    }

    clone[index] = obj;
    setBillingAdhocChargesData([...clone]);
  };

  const handleRemoveBillingChargeSection = (model) => {
    if (
      !validateBillingCharge(model.warehouseCustomerAdhocChargeId) ||
      model.required
    ) {
      return;
    }

    if (model.warehouseCustomerBillingChargeSectionId !== undefined) {
      setDataBillingChargeSectionSelected((prev) => ({
        ...prev,
        warehouseCustomerBillingChargeSectionId:
          model.warehouseCustomerBillingChargeSectionId,
      }));
      onOpenConfirmDeleteDialog();
    } else {
      const filteredArray = billingAdhocChargesData.filter(
        (v) =>
          v.warehouseCustomerAdhocChargeId !==
          model.warehouseCustomerAdhocChargeId,
      );

      setBillingAdhocChargesData(filteredArray);
      setIsBillingChargeDeleteHasIdOrder(false);
    }
  };

  // Checklist
  const [checklistModal, setChecklistModal] = useState<ChecklistModal>(null);

  // Void the Pack
  const [voidType, setVoidType] = useState<OrderVoidTypeEnum>(
    OrderVoidTypeEnum.VoidThePack,
  );
  const [isVoidThePackModal, setIsVoidThePackModal] = useState<boolean>(false);

  // Complete orders manually

  // Serial No
  const [optionsSerialNumber, setOptionsSerialNumber] = useState<
    DropdownListItemType<string>[]
  >([]);
  const [serialNumbersValue, setSerialNumbersValue] = useState<
    DropdownListItemType<string>[]
  >([]);

  // -------------------------------------------------------------------------
  // #endregion

  const pageViewer: PageViewerType = {
    isNew: () => queryStringItemOrders.form === FormQueryStringStatus.New,
    isView: () =>
      queryStringItemOrders.form === FormQueryStringStatus.View &&
      queryStringItemOrders.id > 0,
    isEdit: () =>
      queryStringItemOrders.form === FormQueryStringStatus.Edit &&
      queryStringItemOrders.id > 0,
    isViewOrEdit: () =>
      (queryStringItemOrders.form === FormQueryStringStatus.View ||
        queryStringItemOrders.form === FormQueryStringStatus.Edit) &&
      queryStringItemOrders.id > 0,
  };
  const onLoadSkuLookup = async () => {
    try {
      const skuLookupFromApi = await getSkuLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return skuLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadSkuLookupWithwarehouseCustomer = async (warehouseCustomerId) => {
    try {
      const skuLookupFromApi = await getSkuLookupWithwarehouseCustomer(
        currentUser.Claim_CustomerId,
        warehouseCustomerId,
      );

      return skuLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadFacilitiesLookup = async () => {
    try {
      const facilitiesLookupFromApi =
        await getFacilitiesWithLocationByCustomerId(
          currentUser.Claim_CustomerId,
        );
      return facilitiesLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadCountriesLookup = async () => {
    try {
      const countriesLookupFromApi = await getCountriesLookup();
      return countriesLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderBillingsLookup = async () => {
    try {
      const countriesLookupFromApi = await getOrderBillingsLookup();
      return countriesLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadWarehouseCustomerShippingAccountsLookup = async () => {
    try {
      const warehouseCustomerShippingAccountsLookupFromApi =
        await getWarehouseCustomerShippingAccountsLookup(
          parseInt(currentUser.Claim_CustomerId, 10),
        );
      return warehouseCustomerShippingAccountsLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadCustomersLookup = async () => {
    try {
      const warehouseCustomersLookupFromApi = await getCustomersLookup(
        currentUser.Claim_CustomerId,
        '',
      );

      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderSourceLookup = async (warehouseCustomerId) => {
    try {
      const response = await getOrderSourceList(warehouseCustomerId);

      return response;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderDetail = async (id: number) => {
    try {
      const warehouseCustomersLookupFromApi = await getOrderDetail(id);
      return warehouseCustomersLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderPickItemTickets = async (id: number) => {
    try {
      const pickTicketFromApi = await getOrderPickTicketLineItemListNew(id);

      return pickTicketFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderCarriers = async (warehouseCustomerId: number) => {
    try {
      const carriersFromApi = await getOrderCarriers(warehouseCustomerId);
      return carriersFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadOrderCarrierServices = async (carrierId: any) => {
    try {
      if (carrierId === 4) {
        setOptionCarrierServices(
          [
            { text: 'Cheapest', value: 'Cheapest' },
            { text: 'Fastest', value: 'Fastest' },
          ].map((c: any) => ({
            label: c.text,
            value: c.text,
            isShippingRate: true,
          })),
        );
      } else if (carrierId === 5) {
        setOptionCarrierServices(
          [
            { text: 'LTL or Truckload', value: 'LTL or Truckload' },
            { text: 'Will Call', value: 'Will Call' },
            { text: 'Pack and Hold', value: 'Pack and Hold' },
          ].map((c: any) => ({
            label: c.text,
            value: c.text,
            isShippingRate: true,
          })),
        );
      } else {
        const carriersFromApi = await getOrderCarrierservices(carrierId);

        setOptionCarrierServices(
          carriersFromApi.map((c: any) => ({
            label: c.text,
            value: c.serviceCode,
            isShippingRate: c.isShippingRate,
            serviceCode: c.serviceCode,
          })),
        );
      }

      return null;
    } catch (err) {
      return err;
    }
  };

  const setStateValue = (
    name: string,
    prev: ISalesOrderFormType,
    dataset: any,
    value: any,
  ) => {
    if (name === 'shipTo') return { ...prev.shipTo, [dataset.state]: value };
    if (name === 'billTo') return { ...prev.billTo, [dataset.state]: value };

    return prev;
  };

  const salesOrderFormValidation = () => {
    const temp: any = {};
    if (salesOrderForm.warehouseCustomerId === undefined) {
      temp.customer = 'This field is required';
    } else {
      temp.customer = '';
    }

    temp.carrier = salesOrderForm.carrierId ? '' : 'This field is required';

    if (salesOrderForm.customerFacilityId === undefined) {
      temp.facility = 'This field is required';
    } else {
      temp.facility = '';
    }

    if (
      salesOrderForm?.shipTo?.recipientName === undefined ||
      salesOrderForm?.shipTo?.recipientName === ''
    ) {
      temp.shipToRecipient = 'This field is required';
    } else {
      temp.shipToRecipient = '';
    }

    if (
      salesOrderForm?.shipTo?.address1 === undefined ||
      salesOrderForm?.shipTo?.address1 === ''
    ) {
      temp.shipToAddress1 = 'This field is required';
    } else {
      temp.shipToAddress1 = '';
    }

    if (salesOrderForm?.shipTo?.country === undefined) {
      temp.shipToCountry = 'This field is required';
    } else {
      temp.shipToCountry = '';
    }

    if (
      salesOrderForm?.shipTo?.cityTownship === undefined ||
      salesOrderForm?.shipTo?.cityTownship === ''
    ) {
      temp.shipToCityTownship = 'This field is required';
    } else {
      temp.shipToCityTownship = '';
    }

    if (
      salesOrderForm?.shipTo?.zipPostalCode === undefined ||
      salesOrderForm?.shipTo?.zipPostalCode === ''
    ) {
      temp.shipToZipPostalCode = 'This field is required';
    } else {
      temp.shipToZipPostalCode = '';
    }

    if (
      salesOrderForm?.billTo?.recipientName === undefined ||
      salesOrderForm?.billTo?.recipientName === ''
    ) {
      temp.billToRecipient = 'Please complete Bill To details';
    } else {
      temp.billToRecipient = '';
    }

    if (
      salesOrderForm?.billTo?.address1 === undefined ||
      salesOrderForm?.billTo?.address1 === ''
    ) {
      temp.billToAddress1 = 'Please complete Bill To details';
    } else {
      temp.billToAddress1 = '';
    }

    if (salesOrderForm?.billTo?.country === undefined) {
      temp.billToCountry = 'Please complete Bill To details';
    } else {
      temp.billToCountry = '';
    }

    if (
      salesOrderForm?.billTo?.cityTownship === undefined ||
      salesOrderForm?.billTo?.cityTownship === ''
    ) {
      temp.billToCityTownship = 'Please complete Bill To details';
    } else {
      temp.billToCityTownship = '';
    }

    if (
      salesOrderForm?.billTo?.zipPostalCode === undefined ||
      salesOrderForm?.billTo?.zipPostalCode === ''
    ) {
      temp.billToZipPostalCode = 'Please complete Bill To details';
    } else {
      temp.billToZipPostalCode = '';
    }

    setSalesOrderFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const shipmentCostValidation = () => {
    const temp: any = {};

    temp.shipmentCost =
      salesOrderForm.shipmentCost || salesOrderForm.shipmentCost >= 0
        ? ''
        : 'This field is required';

    temp.totalShipmentCost =
      salesOrderForm.totalShipmentCost || salesOrderForm.totalShipmentCost >= 0
        ? ''
        : 'This field is required';

    setSalesOrderFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const salesOrderFormOnChange = (e: any): void => {
    e.preventDefault();
    const { name, value, dataset } = e.target;
    setSalesOrderForm((prev) => ({
      ...prev,
      [name]:
        dataset.state !== undefined
          ? setStateValue(name, prev, dataset, value)
          : value,
    }));
  };

  const autoCompleteOnChange = (
    e: any,
    item: AutocompleteBase,
    custom: any = null,
  ): void => {
    e.preventDefault();

    const { dataset } = e.target;
    if (!custom) {
      setSalesOrderForm((prev) => ({
        ...prev,
        [dataset.name]:
          dataset.state !== undefined
            ? setStateValue(dataset.name, prev, dataset, item.value)
            : item.value,
      }));
    } else {
      setSalesOrderForm((prev) => ({
        ...prev,
        [custom.name]:
          custom.value !== undefined
            ? setStateValue(
                custom.name,
                prev,
                custom.data.state,
                custom.data.value,
              )
            : custom.value,
      }));
    }
  };

  // #region Sale Order Event Handlers
  const printFormTemplateOnClick = useCallback(
    async (
      e?: React.SyntheticEvent,
      formType?: FormTemplateTypeEnum,
    ): Promise<void> => {
      e.preventDefault();
      onOpenPrintFormTemplateModal();
      setFormTemplateType(formType);
    },
    [salesOrderForm.orderId],
  );

  const loadOrderNotes = async (id?: number) => {
    const response = await getOrderNotes(id ?? salesOrderForm.orderId);

    setOrderNotes(response);
  };

  const createSalesOrderOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    if (!salesOrderFormValidation()) {
      if (
        salesOrderForm.warehouseCustomerId === null ||
        salesOrderForm.warehouseCustomerId === undefined ||
        salesOrderForm.shipTo.recipientName === '' ||
        salesOrderForm.shipTo.recipientName === undefined ||
        salesOrderForm.shipTo.address1 === '' ||
        salesOrderForm.shipTo.address1 === undefined ||
        salesOrderForm.shipTo.country === null ||
        salesOrderForm.shipTo.country === undefined ||
        salesOrderForm.shipTo.cityTownship === '' ||
        salesOrderForm.shipTo.cityTownship === undefined ||
        salesOrderForm.shipTo.zipPostalCode === '' ||
        salesOrderForm.shipTo.zipPostalCode === undefined
      ) {
        snackActions.error('Please complete required fields');
      } else if (
        salesOrderForm.billTo.recipientName === null ||
        salesOrderForm.billTo.recipientName === undefined ||
        salesOrderForm.billTo.address1 === null ||
        salesOrderForm.billTo.address1 === undefined ||
        salesOrderForm.billTo.country === null ||
        salesOrderForm.billTo.country === undefined ||
        salesOrderForm.billTo.cityTownship === null ||
        salesOrderForm.billTo.cityTownship === undefined ||
        salesOrderForm.billTo.zipPostalCode === null ||
        salesOrderForm.billTo.zipPostalCode === undefined
      ) {
        snackActions.error('Please complete Bill To details');
      }

      return;
    }
    if (billingAdhocChargesData.length > 0) {
      if (!validateBillingCharge()) {
        snackActions.error(`Please complete rate and quantity fields`);
        handleChangeSecond(e, OrderLineItemTabValue.BillingCharge);
        return;
      }
    }

    if (pageViewer.isEdit()) {
      if (!shipmentCostValidation()) {
        return;
      }
    }

    const lineItems: ILineItemType[] = salesOrderForm?.lineItems?.map(
      (x: ILineItemType) => ({
        ...x,
        tempOrderLineItemId: x.isKitOnDemand ? x.lineId : null,
        lineId: null,
      }),
    );

    const PAYLOAD: ISalesOrderFormType = {
      customerId: parseInt(currentUser.Claim_CustomerId, 10),
      customerFacilityId: salesOrderForm.customerFacilityId,
      warehouseCustomerId: salesOrderForm.warehouseCustomerId,
      onHold: salesOrderForm.onHold,
      orderNo:
        salesOrderForm?.isReturn === true
          ? salesOrderForm.returnNo
          : salesOrderForm.orderNo,
      referenceNo: salesOrderForm.referenceNo,
      orderSource: salesOrderForm.orderSource,
      purchaseOrder: salesOrderForm.purchaseOrder,
      completeDateTime:
        salesOrderForm.completeDateTime &&
        moment(`${salesOrderForm.completeDateTime}`).format('MM/DD/YYYY'),
      creationDateTime:
        salesOrderForm.creationDate &&
        moment(
          `${moment(salesOrderForm.creationDate).format('MM/DD/YYYY')} ${
            salesOrderForm.creationTime
          }`,
        ).format('MM/DD/YYYY HH:mm'),
      shipByDateTime: salesOrderForm.shipByDateTime
        ? moment(salesOrderForm.shipByDateTime).format('L')
        : null,
      cancelByDateTime: salesOrderForm.cancelByDateTime
        ? moment(salesOrderForm.cancelByDateTime).format('L')
        : null,
      shipTo: {
        orderContactDetailId: salesOrderForm.shipTo?.orderContactDetailId,
        companyName: salesOrderForm.shipTo.companyName,
        recipientName: salesOrderForm.shipTo.recipientName,
        email: salesOrderForm.shipTo.email,
        phone: salesOrderForm.shipTo.phone,
        address1: salesOrderForm.shipTo.address1,
        address2: salesOrderForm.shipTo.address2,
        cityTownship: salesOrderForm.shipTo.cityTownship,
        stateProvinceRegion: salesOrderForm.shipTo.stateProvinceRegion,
        zipPostalCode: salesOrderForm.shipTo.zipPostalCode,
        country: salesOrderForm.shipTo.country,
      },
      billTo: {
        orderContactDetailId: salesOrderForm.billTo.orderContactDetailId,
        companyName: salesOrderForm.billTo.companyName,
        recipientName: salesOrderForm.billTo.recipientName,
        email: salesOrderForm.billTo.email,
        phone: salesOrderForm.billTo.phone,
        address1: salesOrderForm.billTo.address1,
        address2: salesOrderForm.billTo.address2,
        cityTownship: salesOrderForm.billTo.cityTownship,
        stateProvinceRegion: salesOrderForm.billTo.stateProvinceRegion,
        zipPostalCode: salesOrderForm.billTo.zipPostalCode,
        country: salesOrderForm.billTo.country,
      },
      useShipToDetailsForBillTo: salesOrderForm.useShipToDetailsForBillTo,
      itemAsPackageId: packageCartonCodeValue
        ? packageCartonCodeValue.value
        : null,
      carrierService: salesOrderForm.carrierService,
      warehouseCustomerShippingAccountId: null,
      accountZipPostalCode: salesOrderForm.accountZipPostalCode,
      deliveryType: salesOrderForm.deliveryType,
      shippingCost: salesOrderForm.shippingCost,
      trackingNo: salesOrderForm.trackingNo,
      billingType: salesOrderForm.billingType,
      scacCode: salesOrderForm.scacCode,
      warehouseNotes: salesOrderForm.warehouseNotes,
      carrierNotes: salesOrderForm.carrierNotes,
      // lineItems: salesOrderForm?.lineItems?.map(({ lineId, ...rest }) => rest),
      lineItems,
      orderBillingChargeSectionAdhocs: billingAdhocChargesData,
      isShippingRate: salesOrderForm.isShippingRate,
      carrierId: salesOrderForm.carrierId,
      carrier: salesOrderForm.carrier,
      isLabelProvided: salesOrderForm.isLabelProvided,
      isReturn: salesOrderForm?.isReturn,
      returnOriginalOrderId: salesOrderForm?.returnOriginalOrderId,
      createReturnButtonVisible: salesOrderForm.createReturnButtonVisible,
      returnOverrideMaximumReturnDays:
        salesOrderForm.returnOverrideMaximumReturnDays,
      returnMaxAllowedReturnDays: salesOrderForm.returnMaxAllowedReturnDays,
      isAllPickTicketComplete: salesOrderForm.isAllPickTicketComplete,
      isOrderLineItemQtyMatchPickTicketLineItemQty:
        salesOrderForm.isOrderLineItemQtyMatchPickTicketLineItemQty,
      orderChecklists: [],
    };

    if (packageCartonCodeValue?.isExternalPackage) {
      PAYLOAD.itemAsPackageId = null;
      PAYLOAD.carrierPredefinedPackage = packageCartonCodeValue.label;
    } else {
      PAYLOAD.carrierPredefinedPackage = null;
    }
    updateLoading(true);

    if (
      pageViewer.isNew() ||
      (pageViewer.isNew() && salesOrderForm?.isReturn === true)
    ) {
      try {
        if (salesOrderForm?.isReturn === true) {
          PAYLOAD.lineItems = returnOrderItemRows;
        }

        createSalesOrder(PAYLOAD)
          .then((response) => {
            if (salesOrderForm?.isReturn !== true) {
              // create pick ticket
              if (!outboundSectionSettings.isRequiredApprovalBeforePicking) {
                onLoadOrderPickItemTickets(response.orderId).then(
                  (responsePickTicket) => {
                    setOrderCreationOfPickTicketItemRows(responsePickTicket);

                    const PayloadPickTicket: any = {
                      OrderId: response.orderId,
                      UserId: operatorValue?.value ?? null,
                      LineItems: responsePickTicket,
                    };

                    createOrderPickTicket(PayloadPickTicket)
                      .then(() => {
                        snackActions.success(
                          'Successfully created new orders.',
                        );

                        navigate(`?form=view&id=${response.orderId}`);
                        navigate(0);
                      })
                      .catch(() => {
                        // got an error, just redirect to self
                        // and order status should remain new
                        navigate(`?form=view&id=${response.orderId}`);
                        navigate(0);
                      });
                  },
                );
              } else {
                snackActions.success('Successfully created new orders.');

                navigate(`?form=view&id=${response.orderId}`);
                navigate(0);
              }
            } else {
              snackActions.success('Successfully returned orders.');

              navigate(`?form=view&id=${response.orderId}`);
              navigate(0);
            }
          })
          .catch((err) => {
            snackActions.error(err);
          });
      } catch (err) {
        snackActions.error(err);
      }
    }
    if (pageViewer.isEdit()) {
      try {
        PAYLOAD.orderId = salesOrderForm.orderId;
        PAYLOAD.shipmentCost = salesOrderForm.shipmentCost;
        PAYLOAD.totalShipmentCost = salesOrderForm.totalShipmentCost;

        await updateSalesOrder(PAYLOAD);
        setQueryStringItemOrders((prevState) => ({
          ...prevState,
          form: 'view',
        }));
        navigate(`?form=view&id=${queryStringItemOrders.id}`);
        snackActions.success('Successfully updated orders.');
      } catch (err) {
        snackActions.error(err);
      }
    }
  };
  const createOrderPickTicketNewOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    const response = await onLoadOrderPickItemTickets(salesOrderForm.orderId);
    onOpenPickTicketSalesOrderModal();
    setButtonDisable(false);
    setOrderPickTicketItemModalRows(response);

    getUserOperators()
      .then((opt) => {
        setOptionOperator(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );
        if (salesOrderForm.assignedByUserId) {
          const data = [...opt];

          const newValue = data.find(
            (row) => row.value === salesOrderForm.assignedByUserId,
          );

          if (newValue) {
            setOperatorValue((v) => ({
              ...v,
              value: newValue.value,
              label: newValue.text,
            }));
          }
        }
      })
      .catch();
  };
  const changeStatusSalesOrderOnClick = async (
    e: React.SyntheticEvent,
    id: number,
  ): Promise<void> => {
    e.preventDefault();

    const statusId = id;

    // if (salesOrderForm.isLabelProvided && statusId === OrderStatus.Complete) {
    if (statusId === OrderStatus.Complete) {
      setCompleteOrderManuallyModal((prevState) => ({
        ...prevState,
        orderId: salesOrderForm.orderId,
        warehousecustomerId: salesOrderForm.warehouseCustomerId ?? 0,
        carrier: carrierValue ?? null,
        service: salesOrderForm?.carrierService
          ? {
              value: salesOrderForm?.carrierService,
              label: salesOrderForm?.carrierService,
            }
          : null,
        package: packageCartonCodeValue,
        trackingNo: null,
        shippingCost: null,
        isPalletShipment: { value: 1, label: 'No' },
        noOfPallet: null,
        length: null,
        width: null,
        height: null,
        palletWeight: null,
        totalWeight: null,
        carrierError: '',
        serviceError: '',
        trackingNoError: '',
        shippingCostError: '',
      }));
    } else {
      const PAYLOAD = {
        customerId: salesOrderForm.customerId,
        customerLocationId: salesOrderForm.customerLocationId,
        customerFacilityId: salesOrderForm.customerFacilityId,
        warehouseCustomerId: salesOrderForm.warehouseCustomerId,
        orderId: salesOrderForm.orderId,
        statusId,
      };

      try {
        const response = await changeStatusSalesOrder(PAYLOAD);
        setSalesOrderFlagStatus((prevState) => ({
          ...prevState,
          status: response.status,
          statusStr: response.statusStr,
          isHold: response.isHold,
          isBackOrder: response.isBackOrder,
          isPartial: response.isPartial,
          statusProgress: response.statusProgress,
          statusProgressTexts: response.statusProgressTexts,
          statusProgressPercent: response.statusProgressPercent,
        }));
        snackActions.success('Status updated successfully.');

        if (statusId === OrderStatus.Complete) {
          navigate(`?form=view&id=${response.orderId}`);
          navigate(0);
        }
      } catch (err) {
        snackActions.error(err);
      }
    }
  };

  const refreshSalesOrderInventoryOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    e.preventDefault();

    const PAYLOAD = {
      orderId: salesOrderForm.orderId,
      customerId: salesOrderForm.customerId,
      warehouseCustomerId: salesOrderForm.warehouseCustomerId,
    };

    try {
      const o = await refreshSalesOrderInventory(PAYLOAD);
      setSalesOrderForm((prevState) => ({
        ...prevState,
        orderId: o.orderId,
        customerId: o.customerId,
        customerLocationId: o.customerLocationId,
        customerFacilityId: o.customerFacilityId,
        warehouseCustomerId: o.warehouseCustomerId,
        onHold: o.isHold,
        orderNo: o.orderNo,
        referenceNo: o.referenceNo,
        purchaseOrder: o.purchaseOrder,
        creationDate: o?.creationDateTime
          ?.toLocaleString()
          .split(',')[0] as Date,
        creationTime: moment(o?.creationDateTime).format('HH:mm'),
        creationDateTime: o?.creationDateTime,
        shipByDateTime: o?.shipByDateTime
          ?.toLocaleString()
          .split(',')[0] as Date,
        cancelByDateTime: o?.cancelByDateTime
          ?.toLocaleString()
          .split(',')[0] as Date,
        backOrderDateTime: o?.backOrderDateTime,
        refreshDateTime: o?.refreshDateTime,
        shipTo: o.shipTo,
        billTo: o.billTo,
        useShipToDetailsForBillTo: o.useShipToDetailsForBillTo,
        isShippingRate: o.isShippingRate,
        carrierId: o.carrierId,
        isLabelProvided: o.isLabelProvided,
        carrier: o.carrier,
        accountZipPostalCode: o.accountZipPostalCode,
        deliveryType: o.deliveryType,
        shippingCost: o.shippingCost,
        trackingNo: o.trackingNo,
        billingType: o.billingType,
        scacCode: o.scacCode,
        shipDate: o.shipDate,
        warehouseNotes: o.warehouseNotes,
        carrierNotes: o.carrierNotes,
      }));

      setSalesOrderFlagStatus((prevState) => ({
        ...prevState,
        status: o.status,
        statusStr: o.statusStr,
        isHold: o.isHold,
        isBackOrder: o.isBackOrder,
        isPartial: o.isPartial,
      }));

      setOrderItemRows(
        o.lineItems.map((v: any, index) => ({
          lineNumber: index + 1,
          lineId: v.orderLineItemId,
          itemId: v.itemId,
          sku: v.sku,
          description: v.description,
          primaryUOM: v.primaryUOM,
          primaryUOMAbbr: v.primaryUOMAbbr,
          itemUnitOfMeasureId: v.itemUnitOfMeasureId,
          otherUOMStr: v.otherUOMStr,
          otherUOMAbbr: v.otherUOMAbbr,
          otherUOMCount: v.otherUOMCount,
          uomType: v.uomType,
          qtyEach: v.qtyEach,
          qty: v.qtyOrdered,
          totalCases: v.totalCases,
          qtyShipped: v.qtyShipped,
          status: v.status,
          statusStr: v.statusStr,
          onHand: v.onHand,
          available: v.available,
          backOrder: v.backOrder,
          backOrderDateTime: v.backOrderDateTime,
          systemNote: v.systemNote,
          lotId: v.lotId,
          lotNo: v.lotNo,
          lotExpiration: v.lotExpiration,
          sellPrice: v.sellPrice,
        })),
      );

      snackActions.success('Successfully refresh the sales orders.');
    } catch (err) {
      snackActions.error(err);
    }
  };
  // #endregion

  const duplicateItem = async (orderLineItemId): Promise<void> => {
    try {
      const PAYLOAD = {
        orderLineItemId,
      };
      const response = await duplicateOrderLineItem(PAYLOAD);
      const value: ILineItemType = {
        lineId: response.orderLineItemId,
        itemId: response.itemId,
        sku: response.sku,
        description: response.description,
        primaryUOM: response.primaryUOM,
        primaryUOMAbbr: response.primaryUOMAbbr,
        itemUnitOfMeasureId: response.itemUnitOfMeasureId,
        otherUOMStr: response.otherUOMStr,
        otherUOMAbbr: response.otherUOMAbbr,
        otherUOMCount: response.otherUOMCount,
        uomType: response.uomType,
        qty: response.qtyOrdered,
        totalCases: response.totalCases,
        qtyShipped: response.qtyShipped,
        statusStr: response.statusStr,
        onHand: response.onHand,
        available: response.available,
        backOrder: response.backOrder,
        backOrderDateTime: response.backOrderDateTime,
        systemNote: response.systemNote,
        isKitOnDemand: response.isKitOnDemand,
        isKitOnDemandComponent: response.isKitOnDemandComponent,
        orderLineItemKitOnDemandId: response.orderLineItemKitOnDemandId,
        itemKitOnDemandId: response.itemKitOnDemandId,
        itemKitOnDemandQty: response.itemKitOnDemandQty,
        isSerialNo: response.isSerialNo,
        serialNumbers: response.serialNumbers,
        isOrderByLPN: response.isOrderByLPN,
        palletInventoryId: response.palletInventoryId,
        lpn: response.lpn,
      };

      setOrderItemRows((prev) => [...prev, value]);
      snackActions.success('Order line duplicated successfully.');

      // reload self, setOrderItemRows will not work
      // because setOrderItemRows will only add one row
      // but bundle have many rows
      if (value.isKitOnDemand) {
        navigate(0);
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  // #region Line Item Event Handlers
  const duplicateOrderLineItemOnClick = useCallback(
    async (e: React.SyntheticEvent, row: any): Promise<void> => {
      e.preventDefault();

      const BACKORDER_PAYLOAD = {
        OrderId: salesOrderForm.orderId,
        ItemId: row.itemId,
        Qty: row.qty,
        UOM: row.uom,
        UOMType: row.uomType,
      };

      const backOrderForExistingResponse = await backOrderForExisting(
        BACKORDER_PAYLOAD,
      );

      const { status, data } = backOrderForExistingResponse;
      switch (status) {
        case 200:
          if (data !== '') {
            setApiBackOrderResponse({
              data,
              click: () => {
                duplicateItem(row.lineId);
              },
            });
          } else {
            duplicateItem(row.lineId);
          }
          break;

        default:
          break;
      }
    },
    [orderItemRows],
  );
  const cancelOrderLineItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: number): Promise<void> => {
      e.preventDefault();

      if (pageViewer.isEdit()) {
        const PAYLOAD = {
          orderLineItemId: id,
        };

        try {
          const response = await cancelOrderLineItem(PAYLOAD);
          const value: ILineItemType = {
            lineId: response.orderLineItemId,
            itemId: response.itemId,
            sku: response.sku,
            description: response.description,
            primaryUOM: response.primaryUOM,
            primaryUOMAbbr: response.primaryUOMAbbr,
            itemUnitOfMeasureId: response.itemUnitOfMeasureId,
            otherUOMStr: response.otherUOMStr,
            otherUOMAbbr: response.otherUOMAbbr,
            otherUOMCount: response.otherUOMCount,
            uomType: response.uomType,
            qty: response.qtyOrdered,
            totalCases: response.totalCases,
            qtyShipped: response.qtyShipped,
            statusStr: response.statusStr,
            onHand: response.onHand,
            available: response.available,
            backOrder: response.backOrder,
            backOrderDateTime: response.backOrderDateTime,
            systemNote: response.systemNote,
            isKitOnDemand: response.isKitOnDemand,
            isKitOnDemandComponent: response.isKitOnDemandComponent,
            orderLineItemKitOnDemandId: response.orderLineItemKitOnDemandId,
            itemKitOnDemandId: response.itemKitOnDemandId,
            itemKitOnDemandQty: response.itemKitOnDemandQty,
            isSerialNo: response.isSerialNo,
            serialNumbers: response.serialNumbers,
            isOrderByLPN: response.isOrderByLPN,
            palletInventoryId: response.palletInventoryId,
            lpn: response.lpn,
          };

          setOrderItemRows((prev) =>
            prev.map((row) =>
              row.lineId === id
                ? {
                    ...row,
                    ...value,
                  }
                : row,
            ),
          );
          snackActions.success('Order line canceled successfully.');
        } catch (err) {
          snackActions.error(err);
        }
      }
    },
    [orderItemRows],
  );
  const deleteOrderLineItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: number): Promise<void> => {
      e.preventDefault();
      if (pageViewer.isEdit()) {
        const PAYLOAD = {
          orderLineItemId: id,
        };
        try {
          const response = await deleteOrderLineItem(PAYLOAD);
          setOrderItemRows((prev) =>
            prev.filter(
              (o: ILineItemType) => o?.lineId !== response.orderLineItemId,
            ),
          );
          snackActions.success('Order line removed successfully.');

          // reload self, setOrderItemRows will not work
          // because setOrderItemRows will only add one row
          // but bundle have many rows
          if (response.isKitOnDemand) {
            navigate(0);
          }
        } catch (err) {
          snackActions.error(err);
        }
      }
    },
    [orderItemRows],
  );
  // #endregion

  // #region Pick Ticket Event Handlers
  const createOrderPickTicketAndChangeStatusOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    const PAYLOAD: any = {
      OrderId: salesOrderForm.orderId,
      UserId: operatorValue?.value ?? null,
      LineItems: orderCreationOfPickTicketItemRows,
    };

    try {
      const response = await createOrderPickTicket(PAYLOAD);
      setQueryStringItemOrders((prevState) => ({
        ...prevState,
        form: 'view',
      }));

      setOrderPickTicketItemRows(response);
      onClosePickTicketSalesOrderModal();
      setIsPickTicketEdit(false);

      snackActions.success('Successfully created new order pick ticket.');
    } catch (err) {
      snackActions.error(err);
    }
  };
  const updateOrderPickTicketAndChangeStatusOnClick = async (
    e: React.SyntheticEvent,
  ): Promise<void> => {
    const updateLineItems = orderCreationOfPickTicketItemRows.map((c: any) => ({
      pickTicketLineItemId: c.orderLineItemId,
      pickTicketId: c.pickTicketId,
      sku: c.sku,
      description: c.description,
      uomStr: c.uomStr,
      lotId: c.lotId,
      quantity: c.quantity,
    }));

    const PAYLOAD: any = {
      pickTicketId: updateLineItems[0].pickTicketId,
      UserId: operatorValue?.value ?? null,
      LineItems: updateLineItems,
    };

    try {
      const response = await updateOrderPickTicket(PAYLOAD);
      setQueryStringItemOrders((prevState) => ({
        ...prevState,
        form: 'view',
      }));

      setOrderPickTicketItemRows(response);
      onClosePickTicketSalesOrderModal();
      setIsPickTicketEdit(false);

      snackActions.success('Successfully updated order pick ticket.');
    } catch (err) {
      snackActions.error(err);
    }
  };
  const deleteOrderPickTicketOnClick = useCallback(
    async (e: React.SyntheticEvent, id: number): Promise<void> => {
      e.preventDefault();

      const DELETE_PAYLOAD = {
        pickTicketId: id,
      };

      try {
        const response = await deleteOrderPickTicketItem(DELETE_PAYLOAD);
        setQueryStringItemOrders((prevState) => ({
          ...prevState,
          form: 'edit',
        }));
        setOrderPickTicketItemRows(response);
        snackActions.success('Pick ticket item deleted successfully.');
        navigate(0);
      } catch (err) {
        snackActions.error(err);
      }
    },
    [orderPickTicketItemRows],
  );
  // #endregion
  const viewLoadData = async () => {
    if (pageViewer.isViewOrEdit()) {
      onLoadOrderDetail(queryStringItemOrders.id).then(
        (o: ISalesOrderFormType & IFlagStatusType) => {
          setIsOnHoldValue(o.isHold);
          setSalesOrderForm((prevState) => ({
            ...prevState,
            orderId: o.orderId,
            invoiceId: o.invoiceId,
            customerId: o.customerId,
            customerLocationId: o.customerLocationId,
            customerFacilityId: o.customerFacilityId,
            warehouseCustomerId: o.warehouseCustomerId,
            onHold: o.isHold,
            orderNo: o.orderNo,
            referenceNo: o.referenceNo,
            orderSource: o.orderSource,
            purchaseOrder: o.purchaseOrder,
            completeDateTime: o.completeDateTime,
            carrierService: o.carrierService,
            creationDate: moment(o?.creationDateTime).format('MM/DD/YYYY'),
            creationTime: moment(o?.creationDateTime).format('HH:mm'),
            creationDateTime: o?.creationDateTime,
            shipByDateTime: o?.shipByDateTime
              ? new Date(o?.shipByDateTime)
              : null,
            cancelByDateTime: o?.cancelByDateTime
              ? new Date(o?.cancelByDateTime)
              : null,
            backOrderDateTime: o?.backOrderDateTime,
            refreshDateTime: o?.refreshDateTime,
            isAddressInvalid: o?.isAddressInvalid,
            channel: o?.channel,
            shipTo: {
              orderContactDetailId: o.shipTo.orderContactDetailId,
              companyName: o.shipTo.companyName,
              recipientName: o.shipTo.recipientName,
              email: o.shipTo.email,
              phone: o.shipTo.phone,
              address1: o.shipTo.address1,
              address2: o.shipTo.address2,
              cityTownship: o.shipTo.cityTownship,
              stateProvinceRegion: o.shipTo.stateProvinceRegion,
              zipPostalCode: o.shipTo.zipPostalCode,
              country: o.shipTo.country,
              countryStr: o.shipTo.countryStr,
            },
            billTo: {
              orderContactDetailId: o.billTo.orderContactDetailId,
              companyName: o.billTo.companyName,
              recipientName: o.billTo.recipientName,
              email: o.billTo.email,
              phone: o.billTo.phone,
              address1: o.billTo.address1,
              address2: o.billTo.address2,
              cityTownship: o.billTo.cityTownship,
              stateProvinceRegion: o.billTo.stateProvinceRegion,
              zipPostalCode: o.billTo.zipPostalCode,
              country: o.billTo.country,
              countryStr: o.billTo.countryStr,
            },
            useShipToDetailsForBillTo: o.useShipToDetailsForBillTo,
            isShippingRate: o.isShippingRate,
            carrierId: o.carrierId,
            isLabelProvided: o.isLabelProvided,
            assignedByUserId: o.assignedByUserId,
            assignedByUserName: o.assignedByUserName,
            carrier: o.carrier,
            accountZipPostalCode: o.accountZipPostalCode,
            deliveryType: o.deliveryType,
            shippingCost: o.shippingCost,
            trackingNo: o.trackingNo,
            billingType: o.billingType,
            scacCode: o.scacCode,
            shipDate: o.shipDate,
            warehouseNotes: o.warehouseNotes,
            carrierNotes: o.carrierNotes,
            subtotalLineItems: o.subtotalLineItems,
            totalLineItems: o.totalLineItems,
            isReturn: o.isReturn,
            returnOriginalOrderId: o.returnOriginalOrderId,
            createReturnButtonVisible: o.createReturnButtonVisible,
            returnOverrideMaximumReturnDays: o.returnOverrideMaximumReturnDays,
            returnMaxAllowedReturnDays: o.returnMaxAllowedReturnDays,
            isAllPickTicketComplete: o.isAllPickTicketComplete,
            isOrderLineItemQtyMatchPickTicketLineItemQty:
              o.isOrderLineItemQtyMatchPickTicketLineItemQty,
            originalOrderNo: o.originalOrderNo,
            totalShipmentCost: o.totalShipmentCost,
            shipmentCost: o.shipmentCost,
            isFba: o.isFba,
            isPrime: o.isPrime,
            latestDeliveryDate: o.latestDeliveryDate,
            orderPackages: o.orderPackages.map((v, index) => ({
              packageId: v.packageId,
              packageCount: index + 1,
              packageTypeName: v.packageTypeName,
              length: v.length,
              width: v.width,
              height: v.height,
              weight: v.weight,
              tracking: v.tracking,
              trackingUrl: v.trackingUrl,
              totalShipmentCost: v.totalShipmentCost,
              labelUrl: v.labelUrl,
              orderPackagesLineItems: v.orderPackagesLineItems.map((i) => ({
                packageLineItemId: i.packageLineItemId,
                sku: i.sku,
                description: i.description,
                serialNo: i.serialNo,
                uom: i.uom,
                purchaseOrderNo: i.purchaseOrderNo,
                lotNo: i.lotNo,
                lotExpirationDate: i.lotExpirationDate,
                qty: i.qty,
              })),
            })),
            orderChecklists: o.orderChecklists.map(
              (v: OrderChecklistModel) => ({
                orderChecklistId: v.orderChecklistId,
                name: v.name,
              }),
            ),
            orderPallets: o.orderPallets,
            lockOrderEditsAfter: o.lockOrderEditsAfter,
          }));

          setSalesOrderFlagStatus((prevState) => ({
            ...prevState,
            status: o.status,
            statusStr: o.statusStr,
            isHold: o.isHold,
            isBackOrder: o.isBackOrder,
            isPartial: o.isPartial,
            statusProgress: o.statusProgress,
            statusProgressTexts: o.statusProgressTexts,
            statusProgressPercent: o.statusProgressPercent,
          }));
          setOrderItemRows(
            o.lineItems.map((v: any, index) => ({
              lineNumber: index + 1,
              lineId: v.orderLineItemId,
              itemId: v.itemId,
              sku: v.sku,
              description: v.description,
              packagingType: v.packagingType,
              primaryUOM: v.primaryUOM,
              primaryUOMAbbr: v.primaryUOMAbbr,
              itemUnitOfMeasureId: v.itemUnitOfMeasureId,
              otherUOMStr: v.otherUOMStr,
              otherUOMAbbr: v.otherUOMAbbr,
              otherUOMCount: v.otherUOMCount,
              uomType: v.uomType,
              qtyEach: v.qtyEach,
              qty: v.qtyOrdered,
              totalCases: v.totalCases,
              qtyShipped: v.qtyShipped,
              status: v.status,
              statusStr: v.statusStr,
              onHand: v.onHand,
              available: v.available,
              backOrder: v.backOrder,
              backOrderDateTime: v.backOrderDateTime,
              systemNote: v.systemNote,
              lotId: v.lotId,
              lotNo: v.lotNo,
              lotExpiration: v.lotExpiration,
              sellPrice: v.sellPrice,
              isKitOnDemand: v.isKitOnDemand,
              isKitOnDemandComponent: v.isKitOnDemandComponent,
              orderLineItemKitOnDemandId: v.orderLineItemKitOnDemandId,
              itemKitOnDemandId: v.itemKitOnDemandId,
              itemKitOnDemandQty: v.itemKitOnDemandQty,
              isSerialNo: v.isSerialNo,
              serialNumbers: v.serialNumbers,
              isOrderByLPN: v.isOrderByLPN,
              palletInventoryId: v.palletInventoryId,
              lpn: v.lpn,
            })),
          );
          setOrderPickTicketItemRows(
            o.pickTickets.map((v: IPickTicketType) => ({
              pickTicketId: v.pickTicketId,
              pickId: v.pickId,
              status: v.status,
              statusStr: v.statusStr,
              issues: v.issues,
            })),
          );

          setBillingAdhocChargesData(
            o.orderBillingChargeSectionAdhocs.map((v: any) => ({
              warehouseCustomerBillingChargeSectionId:
                v.warehouseCustomerBillingChargeSectionId,
              warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
              chargeName: v.chargeName,
              rate: v.rate,
              qty: v.qty,
              total: v.total,
              notes: v.notes,
              required: v.required,
            })),
          );

          const filteredReceiptBillingChargeSectionAdhocs =
            o.orderBillingChargeSectionAdhocs.filter(
              (v) => v.warehouseCustomerBillingChargeSectionId !== undefined,
            );

          if (o.carrierService) {
            setCarrierServiceValue({
              value: 0,
              label: o.carrierService,
              isShippingRate: false,
            });
          }

          if (o.carrierPredefinedPackage) {
            setPackageCartonCodeValue((prevState) => ({
              ...prevState,
              value: null,
              label: o.carrierPredefinedPackage,
            }));
          } else {
            setPackageCartonCodeValue((prevState) => ({
              ...prevState,
              value: o.itemAsPackageId,
              label: o.itemAsPackageName,
            }));
          }

          if (pageViewer.isView()) {
            setBillingAdhocChargesData(
              o.orderBillingChargeSectionAdhocs.map((v: any) => ({
                warehouseCustomerBillingChargeSectionId:
                  v.warehouseCustomerBillingChargeSectionId,
                warehouseCustomerAdhocChargeId:
                  v.warehouseCustomerAdhocChargeId,
                chargeName: v.chargeName,
                rate: v.rate,
                qty: v.qty,
                total: v.total,
                notes: v.notes,
                required: v.required,
              })),
            );
          } else if (pageViewer.isEdit()) {
            setBillingAdhocChargesData(
              o.orderBillingChargeSectionAdhocs.map((v: any) => ({
                warehouseCustomerBillingChargeSectionId:
                  v.warehouseCustomerBillingChargeSectionId,
                warehouseCustomerAdhocChargeId:
                  v.warehouseCustomerAdhocChargeId,
                chargeName: v.chargeName,
                rate: v.rate,
                qty: v.qty,
                total: v.total,
                notes: v.notes,
                required: v.required,
              })),
            );

            setCarrierValue({
              value: o.carrierId,
              label: o.carrier,
              isShippingRate: false,
            });
          }

          if (o.warehouseCustomerId !== undefined) {
            const optSkus = onLoadSkuLookupWithwarehouseCustomer(
              o.warehouseCustomerId,
            );
            optSkus
              .then((opt) => {
                setOptionSkus(
                  opt.map((c: any) => ({
                    label: c.sku,
                    value: c.itemId,
                    description: c.description,
                  })),
                );
              })
              .catch();
          }
        },
      );
    }

    if (pageViewer.isNew() && queryStringItemOrders.isReturn === true) {
      const param =
        temporaryOrderId !== null ? temporaryOrderId : queryStringItemOrders.id;
      onLoadOrderDetail(param).then(
        (o: ISalesOrderFormType & IFlagStatusType) => {
          const newData = {
            customerId: o.customerId,
            customerLocationId: o.customerLocationId,
            customerFacilityId: o.customerFacilityId,
            warehouseCustomerId: o.warehouseCustomerId,
            returnNo: o.orderNo,
            orderNo: o.orderNo,
            referenceNo: o.referenceNo,
            orderSource: o.orderSource,
            purchaseOrder: o.purchaseOrder,
            creationDate: new Date(),
            creationTime: moment(new Date()).format('HH:mm'),
            shipByDateTime: o?.shipByDateTime
              ? new Date(o?.shipByDateTime)
              : null,
            cancelByDateTime: o?.cancelByDateTime
              ? new Date(o?.cancelByDateTime)
              : null,
            shipTo: o.shipTo,
            billTo: o.billTo,
            useShipToDetailsForBillTo: o.useShipToDetailsForBillTo,
            isShippingRate: o.isShippingRate,
            isLabelProvided: o.isLabelProvided,
            assignedByUserId: o.assignedByUserId,
            isReturn: true,
            returnOriginalOrderId: param,
            createReturnButtonVisible: o.createReturnButtonVisible,
            returnOverrideMaximumReturnDays: o.returnOverrideMaximumReturnDays,
            returnMaxAllowedReturnDays: o.returnMaxAllowedReturnDays,
            isAllPickTicketComplete: o.isAllPickTicketComplete,
            isOrderLineItemQtyMatchPickTicketLineItemQty:
              o.isOrderLineItemQtyMatchPickTicketLineItemQty,
            carrierService: o.carrierService,
            orderChecklists: o.orderChecklists.map(
              (v: OrderChecklistModel) => ({
                orderChecklistId: v.orderChecklistId,
                name: v.name,
              }),
            ),
          };

          setOrderItemRows(
            o.lineItems.map((v: any, index) => ({
              lineNumber: index + 1,
              lineId: v.orderLineItemId,
              itemId: v.itemId,
              sku: v.sku,
              description: v.description,
              primaryUOM: v.primaryUOM,
              primaryUOMAbbr: v.primaryUOMAbbr,
              itemUnitOfMeasureId: v.itemUnitOfMeasureId,
              otherUOMStr: v.otherUOMStr,
              otherUOMAbbr: v.otherUOMAbbr,
              otherUOMCount: v.otherUOMCount,
              uomType: v.uomType,
              qtyEach: v.qtyEach,
              qty: v.qtyOrdered,
              totalCases: v.totalCases,
              qtyShipped: v.qtyShipped,
              status: v.status,
              statusStr: v.statusStr,
              onHand: v.onHand,
              available: v.available,
              backOrder: v.backOrder,
              backOrderDateTime: v.backOrderDateTime,
              systemNote: v.systemNote,
              lotId: v.lotId,
              lotNo: v.lotNo,
              lotExpiration: v.lotExpiration,
              sellPrice: v.sellPrice,
              isKitOnDemand: v.isKitOnDemand,
              isKitOnDemandComponent: v.isKitOnDemandComponent,
              orderLineItemKitOnDemandId: v.orderLineItemKitOnDemandId,
              itemKitOnDemandId: v.itemKitOnDemandId,
              itemKitOnDemandQty: v.itemKitOnDemandQty,
              isSerialNo: v.isSerialNo,
              serialNumbers: v.serialNumbers,
              isOrderByLPN: v.isOrderByLPN,
              palletInventoryId: v.palletInventoryId,
              lpn: v.lpn,
            })),
          );

          setSalesOrderForm(newData);
          setCarrierValue(null);
          setPackageCartonCodeValue(null);
        },
      );
    }
  };

  useEffect(() => {
    if (salesOrderForm.orderSource && optionOrderSource.length > 0) {
      const sourceValue = optionOrderSource.find(
        (x: OrderSourceType) => x.label === salesOrderForm.orderSource,
      );
      setOrderSourceValue(sourceValue);
    }
  }, [salesOrderForm.orderSource, optionOrderSource]);

  useEffect(() => {
    const optCustomers = onLoadCustomersLookup();
    const optFacilities = onLoadFacilitiesLookup();
    const optCountries = onLoadCountriesLookup();
    const optOrderBillings = onLoadOrderBillingsLookup();
    const optWarehouseCustomerShippingAccounts =
      onLoadWarehouseCustomerShippingAccountsLookup();

    if (salesOrderForm.warehouseCustomerId === undefined) {
      const optSkus = onLoadSkuLookup();
      optSkus
        .then((opt) => {
          setOptionSkus(
            opt.map((c: any) => ({
              label: c.sku,
              value: c.itemId,
              description: c.description,
            })),
          );
        })
        .catch();
    } else {
      const optSkus = onLoadSkuLookupWithwarehouseCustomer(
        salesOrderForm.warehouseCustomerId,
      );
      optSkus
        .then((opt) => {
          setOptionSkus(
            opt.map((c: any) => ({
              label: c.sku,
              value: c.itemId,
              description: c.description,
            })),
          );
        })
        .catch();
    }

    optCustomers
      .then((opt) => {
        setOptionCustomers(
          opt.map((c: any) => ({
            label: c.name,
            value: c.warehouseCustomerId,
          })),
        );
      })
      .catch();

    optFacilities
      .then((opt) => {
        setOptionFacilities(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );
      })
      .catch();

    optCountries
      .then((opt) => {
        setOptionCountries(
          opt.map((c: any) => ({
            label: c.name,
            value: c.countryId,
          })),
        );
      })
      .catch();

    optOrderBillings.then((opt) => {
      setOptionOrderBillings(
        opt.map((c: any) => ({
          label: c.name,
          value: c.id,
        })),
      );
    });

    optWarehouseCustomerShippingAccounts
      .then((opt) => {
        setOptionWarehouseCustomerShippingAccounts(
          opt.map((c: any) => ({
            label: c.sku,
            value: c.itemId,
          })),
        );
      })
      .catch();
  }, []);

  useEffect(() => {
    if (salesOrderForm.warehouseCustomerId !== undefined) {
      const optCarriers = onLoadOrderCarriers(
        salesOrderForm.warehouseCustomerId,
      );

      const optOrderSources = onLoadOrderSourceLookup(
        salesOrderForm.warehouseCustomerId || 0,
      );

      optOrderSources
        .then((opt) => {
          const initialOption = [
            {
              label: 'Manual',
              value: 'Manual',
            },
          ];
          const option = opt.map((c: any) => ({
            label: c.text,
            value: c.value,
          }));

          setOptionOrderSource([...initialOption, ...option]);
        })
        .catch();

      optCarriers.then((opt) => {
        setOptionCarriers(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
            isShippingRate: c.isShippingRate,
          })),
        );

        // to fix the carrier if the value is coming from the import which is other and best way don't have an id
        if (salesOrderForm.carrier) {
          const getCarrier = opt
            .map((c: any) => ({
              label: c.text,
              value: c.value,
              isShippingRate: c.isShippingRate,
            }))
            .find((c: any) => c.label === salesOrderForm.carrier);

          if (getCarrier) {
            setCarrierValue(getCarrier);
            setSalesOrderForm((prev) => ({
              ...prev,
              carrierId: getCarrier.value,
              carrier: getCarrier.label,
              isShippingRate: getCarrier.isShippingRate,
            }));
          }
        }
      });
    }
  }, [salesOrderForm.warehouseCustomerId]);

  useEffect(() => {
    if (pageViewer.isView()) {
      setIsFormDisable(true);
    }
    if (pageViewer.isEdit()) {
      setIsFormDisable(false);
      navigate(`?form=edit&id=${queryStringItemOrders.id}`);
    }
    if (pageViewer.isNew()) {
      setIsFormDisable(false);
    }

    viewLoadData();
  }, [queryStringItemOrders]);
  useEffect(() => {
    if (salesOrderForm?.carrierId) {
      onLoadOrderCarrierServices(salesOrderForm.carrierId);
    }
  }, [salesOrderForm.carrierId]);

  /// Billing Charge Section useeffect ------------------------------------
  const callBackForBillingChargeDelete = async () => {
    onLoadOrderDetail(queryStringItemOrders.id).then(
      (o: ISalesOrderFormType & IFlagStatusType) => {
        const filteredReceiptBillingChargeSectionAdhocs =
          o.orderBillingChargeSectionAdhocs.filter(
            (v) => v.warehouseCustomerBillingChargeSectionId !== undefined,
          );

        setBillingAdhocChargesData(
          filteredReceiptBillingChargeSectionAdhocs.map((v: any) => ({
            warehouseCustomerBillingChargeSectionId:
              v.warehouseCustomerBillingChargeSectionId,
            warehouseCustomerAdhocChargeId: v.warehouseCustomerAdhocChargeId,
            chargeName: v.chargeName,
            rate: v.rate,
            qty: v.qty,
            total: v.total,
            notes: v.notes,
            required: v.required,
          })),
        );
      },
    );

    setIsBillingChargeDeleteHasIdOrder(false);
  };

  useEffect(() => {
    if (isBillingChargeDeleteHasIdOrder) {
      callBackForBillingChargeDelete();
    }
  }, [isBillingChargeDeleteHasIdOrder]);

  /// ------------------------------------------------------------------------------------------------------------

  const value: ISalesOrderType = useMemo(
    () => ({
      apiBackOrderResponse,
      dataBillingChargeSectionSelected,
      billingAdhocChargesData,
      currentUser,
      errorBillingAdhocItemValues,
      isOnHoldValue,
      isShipByDateHasValue,
      isFormDisable,
      isButtonDisable,
      pageViewer,
      modalTitle,
      optionCustomers,
      optionOrderSource,
      optionFacilities,
      optionCountries,
      optionSkus,
      optionOrderBillings,
      optionWarehouseCustomerShippingAccounts,
      orderPickTicketItemModalRows,
      orderPickTicketItemRows,
      orderItemRows,
      salesOrderForm,
      salesOrderFormErrors,
      salesOrderFlagStatus,
      singleOrderLineItemRow,
      singleOrderPickTicketItemRow,
      optionCarriers,
      carrierValue,
      carrierServiceValue,
      setCarrierServiceValue,
      setOrderNotesModalOpen,
      orderNotesModalOpen,
      packageCartonCodeValue,
      orderPackageLineItems,
      orderPackageName,
      setOrderPackageName,
      setOrderPackageLineItems,
      temporaryOrderId,
      returnOrderItemRows,
      returnQuantityLineItem,
      setReturnQuantityLineItem,
      setReturnOrderItemRows,
      setTemporaryOrderId,
      setPackageCartonCodeValue,
      setOrderSourceValue,
      orderSourceValue,
      setCarrierValue,
      onLoadSkuLookupWithwarehouseCustomer,
      autoCompleteOnChange,
      setModalTitle,
      setIsOnHoldValue,
      setIsShipByDateHasValue,
      setButtonDisable,
      setOrderItemRows,
      setSalesOrderForm,
      setApiBackOrderResponse,
      setQueryStringItemOrders,
      setSingleOrderLineItemRow,
      setSingleOrderPickTicketItemRow,
      setOrderPickTicketItemModalRows,
      setOrderCreationOfPickTicketItemRows,
      salesOrderFormOnChange,
      setOptionSkus,
      createSalesOrderOnClick,
      loadOrderNotes,
      orderNotes,
      printFormTemplateOnClick,
      createOrderPickTicketNewOnClick,
      createOrderPickTicketAndChangeStatusOnClick,
      updateOrderPickTicketAndChangeStatusOnClick,
      deleteOrderPickTicketOnClick,
      deleteOrderLineItemOnClick,
      duplicateOrderLineItemOnClick,
      cancelOrderLineItemOnClick,
      changeStatusSalesOrderOnClick,
      refreshSalesOrderInventoryOnClick,
      loadBillingChargesDetailByModule,
      handleChangeBillingChargeSection,
      handleRemoveBillingChargeSection,
      viewLoadData,
      setIsBillingChargeDeleteHasIdOrder,
      optionOperator,
      setOptionOperator,
      operatorValue,
      setOperatorValue,
      isPickTicketEdit,
      setIsPickTicketEdit,
      setOptionsUOM,
      optionsUOM,
      setUomValue,
      uomValue,
      queryStringItemOrders,
      onLoadOrderCarrierServices,
      optionCarrierServices,
      valueSecondTab,
      handleChangeSecond,
      formTemplateType,
      setFormTemplateType,
      checklistModal,
      setChecklistModal,
      voidType,
      setVoidType,
      isVoidThePackModal,
      setIsVoidThePackModal,
      optionsSerialNumber,
      setOptionsSerialNumber,
      serialNumbersValue,
      setSerialNumbersValue,
    }),
    [
      formTemplateType,
      apiBackOrderResponse,
      billingAdhocChargesData,
      modalTitle,
      errorBillingAdhocItemValues,
      isOnHoldValue,
      isShipByDateHasValue,
      isFormDisable,
      isButtonDisable,
      optionCustomers,
      optionOrderSource,
      optionFacilities,
      optionCountries,
      optionOrderBillings,
      optionWarehouseCustomerShippingAccounts,
      optionSkus,
      orderItemRows,
      salesOrderForm,
      salesOrderFlagStatus,
      queryStringItemOrders,
      packageCartonCodeValue,
      salesOrderFormValidation,
      setIsOnHoldValue,
      setIsShipByDateHasValue,
      setOrderPickTicketItemModalRows,
      setSingleOrderPickTicketItemRow,
      optionsUOM,
      uomValue,
      orderPackageLineItems,
      orderPackageName,
      temporaryOrderId,
      returnOrderItemRows,
      returnQuantityLineItem,
      valueSecondTab,
      checklistModal,
      setChecklistModal,
      voidType,
      setVoidType,
      isVoidThePackModal,
      setIsVoidThePackModal,
      optionsSerialNumber,
      setOptionsSerialNumber,
      serialNumbersValue,
      setSerialNumbersValue,
    ],
  );
  return (
    <SalesOrderContext.Provider value={value}>
      {children}
    </SalesOrderContext.Provider>
  );
};
