import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';

import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import Card from 'components/card';
import Header from 'components/header';
import TabPanel, { a11yProps } from 'components/tabpanel';
import { snackActions } from 'config/snackbar.js';
import BinCategory from 'helpers/bincategoryhelper';
import FileInputModal from 'pages/shared/addfilemodal';
import BillTypeWarningModal from 'pages/shared/billingtypewarning';
import LocationPrintBarcode, {
  IPrintBarcodeLocation,
} from 'pages/shared/binprintbarcode';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import confirmsalesorderlineitemmodal from 'pages/shared/confirmsalesorderlineitemmodal';
import CreateBinModal from 'pages/shared/createbinmodal';
import CustomerFacilityZoneModal from 'pages/shared/customerfacilityzonemodal';
import {
  getZoneStorageManagement,
  getStorageManagementDetails,
  addImportedZonesAndBins,
  bulkDelete,
} from 'services/api/customerfacilities/customerfacilities.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Typography, Box, Tabs, Tab, useMediaQuery, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium';

import ImportGlobalMessageModal from '../shared/import-global-message-modal';
import { MainContainer, ContentContainer } from '../styles';

type ImportedZonesBins = {
  customerId?: number;
  customerLocationId?: number;
  customerFacilityId?: number;
  binZone?: number;
  zoneName: string;
  binName: string;
  category: string;
  sequenceNo?: number;
  aisle?: string;
  row?: string;
  shelf?: string;
  level?: string;
};

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          flex: '1',
          width: '100% !important',
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    </GridToolbarContainer>
  );
}

function BinManagement() {
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    loading,
    updateData,
    updateLoading,
    handleUpdateData,
    onOpenCreateBinModal,
    onCloseInputFilenModal,
    onOpenFacilityZoneModal,
    onOpenConfirmDeleteDialog,
    onOpenBinPrintBarcodeModal,
    isBulkDeleteLocationModalOpen,
    setIsImportGlobalErrorModalOpen,
    setImportGlobalErrorMessageList,
    setIsBulkDeleteLocationModalOpen,
  } = useContext(GlobalContext);

  const { currentUser, currentLocationAndFacility, handleUserPermissionAllow } =
    useContext(AuthContext);

  const [currentView, setCurrentView] = useState(0);
  const [binCount, setBinCount] = useState(0);
  const [zoneCount, setZoneCount] = useState(0);
  const [error, setError] = useState('');
  const [customerBins, setCustomerBins] = useState([]);
  const [filteredBins, setFilteredBins] = useState([]);
  const [facilityZones, setFacilityZones] = useState([]);
  const [filteredZones, setFilteredZones] = useState([]);
  const [deleteLocation, setDeleteLocation] = useState(false);
  const [zoneAction, setZoneAction] = useState(false);
  const [editZone, setEditZone] = useState(false);
  const [billTypeCallback, setBillTypeCallback] = useState<any>(null);

  const [locationBarcode, setLocationBarcode] =
    useState<IPrintBarcodeLocation>();

  const initialDataSelected = {
    binId: '',
    customerLocationId: '',
    zoneId: '',
    name: '',
    sequenceNumber: '',
    aisle: '',
    row: '',
    shelf: '',
    level: '',
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [dataSelected, setDataSelected] = useState(initialDataSelected);
  const [searchParams, setSearchParams] = useState({
    searchZone: '',
    searchLocation: '',
    searchName: '',
    searchUniqueSKUs: '',
    searchCategory: '',
    searchAreaLocations: '',
    searchAreaUniqueSKU: '',
    searchAreaItemQty: '',
    searchWarehouseCustomer: '',
    searchSequenceNumber: '',
    searchAisle: '',
    searchRow: '',
    searchShelf: '',
    searchLevel: '',
  });

  const [notForDeletion, setNotForDeletion] = useState([]);
  const [forDeletion, setForDeletion] = useState([]);

  const provideCsvTemplate = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const obj = [
      'Location Name',
      'Area',
      'Category',
      'Sequence No',
      'Aisle',
      'Row',
      'Shelf',
      'Level',
      'Is Picking',
      'Bin Size',
    ];

    const csvContent = `data:text/csv;charset=utf-8,${obj}`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'location_template.csv');
    document.body.appendChild(link);

    link.click();
  };

  const mapAndAddZonesBins = async (value) => {
    if (!value) {
      snackActions.error('No data to import');
      return;
    }
    const importedZonesBins = value
      .filter((zb) => zb.LocationName)
      .map((zb) => ({
        customerId: currentUser.Claim_CustomerId,
        customerLocationId: currentLocationAndFacility.customerLocationId,
        customerFacilityId: currentLocationAndFacility.customerFacilityId,
        binName: zb?.LocationName,
        zoneName: zb?.Area,
        category: zb?.Category,
        sequenceNo: zb?.SequenceNo,
        aisle: zb?.Aisle,
        row: zb?.Row,
        shelf: zb?.Shelf,
        level: zb?.Level,
        isPicking: zb?.IsPicking,
        binSize: zb?.BinSize,
      }));

    const errorlist = [];

    try {
      const response = await addImportedZonesAndBins(
        currentLocationAndFacility.customerLocationId,
        importedZonesBins,
      );
      const { status, data } = response;
      if (status === 200) {
        if (data?.errorMessages && data?.errorMessages.length > 0) {
          setImportGlobalErrorMessageList(data?.errorMessages);
          setIsImportGlobalErrorModalOpen(true);
        } else {
          snackActions.success(
            `Successfully imported areas and locations for ${currentLocationAndFacility.locationName}, ${currentLocationAndFacility.facilityName}.`,
          );
          handleUpdateData();
        }
      } else {
        snackActions.error(`${data} `);
      }
    } catch (err) {
      snackActions.error(err);
    }

    updateLoading(false);
    onCloseInputFilenModal();
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentView(newValue);
  };

  const handleResetDataSelected = () => {
    setDataSelected({
      binId: '',
      customerLocationId: '',
      zoneId: '',
      name: '',
      sequenceNumber: '',
      aisle: '',
      row: '',
      shelf: '',
      level: '',
    });
    setEditZone(false);
    setZoneAction(false);
    setDeleteLocation(false);
  };

  const onLoadCustomerBinsAndZones = async () => {
    try {
      const customerBinsFromApi = await getStorageManagementDetails(
        currentLocationAndFacility.customerLocationId,
        currentLocationAndFacility.customerFacilityId,
      );

      const zonesFromApi = await getZoneStorageManagement(
        currentLocationAndFacility.customerFacilityId,
        '',
      );

      setCustomerBins(customerBinsFromApi.binDetails);
      setFilteredBins(customerBinsFromApi.binDetails);
      setBinCount(customerBinsFromApi.bins);
      setZoneCount(customerBinsFromApi.zones);
      setFacilityZones(zonesFromApi);
      setFilteredZones(zonesFromApi);

      return true;
    } catch (err: any) {
      return err;
    }
  };

  const handleBulkDelete = () => {
    const ids = forDeletion.map((x) => x.binId);
    bulkDelete(ids).then(() => {
      const bins = notForDeletion.map((row) => ` [${row.name}]`).join(',');
      if (bins.length <= 0) {
        // This will not show the modal or we can remove this implementation if not used already?
        withReactContent(Swal).fire({
          title: 'Bulk Delete',
          text: `Location ${bins} was not deleted because it's not an empty location`,
          icon: 'info',
          confirmButtonText: 'Ok',
        });
      }
      onLoadCustomerBinsAndZones();
    });
  };

  useEffect(() => {
    setDataSelected({
      binId: '',
      customerLocationId: '',
      zoneId: '',
      name: '',
      sequenceNumber: '',
      aisle: '',
      row: '',
      shelf: '',
      level: '',
    });
    setBinCount(0);
    setZoneCount(0);
    updateLoading(true);
    setCustomerBins([]);
    setFilteredBins([]);
    setFacilityZones([]);
    setFilteredZones([]);
    onLoadCustomerBinsAndZones();
  }, [loading, updateData, currentLocationAndFacility]);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickDeleteBin = (rowData) => {
    setDeleteLocation(true);
    setZoneAction(false);
    setDataSelected(rowData);
    onOpenConfirmDeleteDialog();
    return true;
  };

  const handleEditBin = (rowData) => {
    setDataSelected(rowData);
    onOpenCreateBinModal();
  };

  const handleClickViewBinDetails = (rowData) => {
    setDataSelected(rowData);
    const querySearchParams = {
      binId: rowData.binId,
    };
    navigate({
      pathname: `/locations/location/${rowData.name}`,
      search: `?${createSearchParams(querySearchParams)}`,
    });
  };

  const binColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Details"
          onClick={() => handleClickViewBinDetails(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleEditBin(params.row)}
          showInMenu
          disabled={
            !handleUserPermissionAllow(UserPermissionEnum.Locations_Edit)
          }
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={params.row.uniqueSKU !== 0}
          onClick={() => handleClickDeleteBin(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'name',
      width: 200,
      headerName: 'Location',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={`locations/location/${params.row.name}?binId=${params.row.binId}`}
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.name}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'zoneName',
      width: 200,
      headerName: 'Area',
      renderCell: (params) => <Typography>{params.row.zone}</Typography>,
    },
    {
      field: 'uniqueSKUs',
      width: 120,
      headerName: 'Unique SKUs',
      renderCell: (params) => (
        <Typography>{params.row.uniqueSKU?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'category',
      width: 120,
      headerName: 'Category',
      renderCell: (params) => (
        <Typography>{BinCategory[params.row.category]}</Typography>
      ),
    },
    {
      field: 'binSize',
      width: 100,
      headerName: 'Bin Size',
      renderCell: (params) => <Typography>{params.row.binSizeStr}</Typography>,
    },
    {
      field: 'warehouseCustomer',
      width: 250,
      headerName: 'Client',
      renderCell: (params) => (
        <Typography>{params.row.warehouseCustomer}</Typography>
      ),
    },
    {
      field: 'sequenceNumber',
      width: 120,
      headerName: 'Sequence No.',
      renderCell: (params) => (
        <Typography>{params.row.sequenceNumber}</Typography>
      ),
    },
    {
      field: 'aisle',
      width: 200,
      headerName: 'Aisle',
      renderCell: (params) => <Typography>{params.row.aisle}</Typography>,
    },
    {
      field: 'row',
      width: 200,
      headerName: 'Row',
      renderCell: (params) => <Typography>{params.row.row}</Typography>,
    },
    {
      field: 'shelf',
      width: 200,
      headerName: 'Shelf',
      renderCell: (params) => <Typography>{params.row.shelf}</Typography>,
    },
    {
      field: 'level',
      width: 200,
      headerName: 'Level',
      renderCell: (params) => <Typography>{params.row.level}</Typography>,
    },
  ];

  type ZoneRows = typeof facilityZones[number];

  const handleClickEditZone = (rowData) => {
    setEditZone(true);
    setDataSelected(rowData);
    onOpenFacilityZoneModal();
  };

  const handleClickDeleteZone = (rowData) => {
    setZoneAction(true);
    setDataSelected(rowData);
    onOpenConfirmDeleteDialog();
    return true;
  };

  const handleClickBillTypeCallback = (data) => {
    setBillTypeCallback(data);
  };

  const zoneColumns: GridColumns<ZoneRows> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => handleClickEditZone(params.row)}
          showInMenu
        />,
        <GridActionsCellItem
          label="Delete"
          disabled={params.row.uniqueSKU !== 0}
          onClick={() => handleClickDeleteZone(params.row)}
          showInMenu
        />,
      ],
    },
    {
      field: 'billingType',
      width: 341,
      hide: true,
      headerName: 'Billing Type',
      renderCell: (params) => <Typography>{params.row.billingType}</Typography>,
    },
    {
      field: 'name',
      width: 200,
      headerName: 'Name',
      renderCell: (params) => <Typography>{params.row.name}</Typography>,
    },
    {
      field: 'locations',
      width: 200,
      headerName: 'Locations',
      renderCell: (params) => (
        <Typography>{params.row.locations?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'uniqueSKU',
      width: 200,
      headerName: 'Unique SKUs',
      renderCell: (params) => (
        <Typography>{params.row.uniqueSKU?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'itemQty',
      width: 200,
      headerName: 'Item Qty',
      renderCell: (params) => (
        <Typography>{params.row.itemQty?.toLocaleString()}</Typography>
      ),
    },
  ];

  const handleDeleteBulkBin = async () => {
    if (selectedRows.length <= 0) {
      withReactContent(Swal).fire({
        title: 'Please select a bin location to delete',
        icon: 'info',
        confirmButtonText: 'Ok',
      });
      return;
    }

    const binNotForDeletion = selectedRows.filter((row) => row.uniqueSKU !== 0);
    const binForDeletion = selectedRows.filter((row) => row.uniqueSKU === 0);

    setNotForDeletion([...binNotForDeletion]);
    setForDeletion([...binForDeletion]);

    onOpenConfirmDeleteDialog();
    setIsBulkDeleteLocationModalOpen(true);
  };

  const handlePrintBarcode = async () => {
    if (selectedRows.length <= 0) {
      setLocationBarcode((e) => ({
        ...e,
        locations: filteredBins.map((item) => ({
          name: item.name,
        })),
      }));
    }
    onOpenBinPrintBarcodeModal();
  };

  useEffect(() => {
    setLocationBarcode((e) => ({
      ...e,
      locations: selectedRows.map((item: any) => ({
        name: item.name,
      })),
    }));
  }, [selectedRows]);

  return (
    <MainContainer>
      <Header
        onClickBulkDeleteBin={handleDeleteBulkBin}
        onClickPrintBarcode={handlePrintBarcode}
      />
      <ImportGlobalMessageModal title="Import Location Error" />

      {deleteLocation && (
        <ConfirmationDialog
          deleteBin
          binData={dataSelected}
          callBack={handleResetDataSelected}
        />
      )}

      {isBulkDeleteLocationModalOpen && (
        <ConfirmationDialog
          deleteBinList
          binDataList={forDeletion}
          callBack={handleBulkDelete}
        />
      )}

      <FileInputModal
        callBack={mapAndAddZonesBins}
        csvTemplate={provideCsvTemplate}
      />
      <CreateBinModal
        binData={dataSelected}
        resetData={handleResetDataSelected}
      />
      {zoneAction && (
        <ConfirmationDialog
          deleteZone
          zoneData={dataSelected}
          callBack={handleResetDataSelected}
        />
      )}
      {editZone ? (
        <CustomerFacilityZoneModal
          edit
          zoneData={dataSelected}
          callBack={handleResetDataSelected}
          billTypeCallBack={handleClickBillTypeCallback}
        />
      ) : (
        <CustomerFacilityZoneModal />
      )}

      <LocationPrintBarcode data={locationBarcode} />
      <BillTypeWarningModal cb={billTypeCallback} />
      <ContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
            marginBottom: '16px',
          }}
        >
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {binCount.toLocaleString()}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Locations
            </Typography>
          </Card>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minWidth: '192px',
            }}
          >
            <Typography
              sx={{ color: [theme.palette.primary.main] }}
              variant="h3"
            >
              {zoneCount.toLocaleString()}
            </Typography>
            <Typography variant="caption" fontWeight="bold">
              Areas
            </Typography>
          </Card>
        </Box>
        <Card sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Tabs value={currentView} onChange={handleChange}>
            <Tab label="Locations" {...a11yProps(0)} />
            <Tab label="Areas" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={currentView} index={0}>
            <DatagridPremium
              autoHeight
              rows={filteredBins}
              columns={binColumns}
              initialState={{ pagination: { pageSize: 100 } }}
              rowsPerPageOptions={[100, 250, 500]}
              pagination
              rowGroupingColumnMode="single"
              density="compact"
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              disableSelectionOnClick
              checkboxSelection
              getRowId={(row) => row.binId}
              onSelectionModelChange={(binId) => {
                const selectedId = binId[0];
                const selectedRowData = customerBins.filter(
                  (c) => c.binId === selectedId,
                );

                const selectedRowsData = binId.map((id) =>
                  customerBins.find((row) => row.binId === id),
                );

                setSelectedRows(selectedRowsData);
                if (selectedId === undefined) {
                  setDataSelected({
                    binId: '',
                    customerLocationId: '',
                    zoneId: '',
                    name: '',
                    sequenceNumber: '',
                    aisle: '',
                    row: '',
                    shelf: '',
                    level: '',
                  });
                } else {
                  setDataSelected(selectedRowData[0]);
                }
              }}
              components={{ Toolbar: CustomToolbar }}
            />
          </TabPanel>
          <TabPanel value={currentView} index={1}>
            <DatagridPremium
              autoHeight
              rows={filteredZones}
              columns={zoneColumns}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              pageSize={15}
              density="compact"
              rowsPerPageOptions={[15]}
              getRowId={(row) => row.zoneId}
              onSelectionModelChange={(customerId) => {
                const selectedId = customerId[0];
                const selectedRowData = facilityZones.filter(
                  (f) => f.zoneId === selectedId,
                );
                setDataSelected(selectedRowData[0]);
              }}
              components={{ Toolbar: CustomToolbar }}
            />
          </TabPanel>
        </Card>
      </ContentContainer>
    </MainContainer>
  );
}

export default React.memo(BinManagement);
