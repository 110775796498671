/* eslint-disable react/no-array-index-key */
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { useReactToPrint } from 'react-to-print';

import {
  OrderShippingRateEnum,
  OutboundSectionSettingLockOrderEditsAfterEnum,
} from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { ItemAsPackageDropdownList } from 'common/models/items';
import {
  OrderChecklistEditModel,
  OrderChecklistModel,
} from 'common/models/orders/orderchecklists';
import { IAutoComplete } from 'common/props/IAutoComplete';
import { GetNum, USDollar } from 'common/utils';
import Card from 'components/card';
import CardTab from 'components/card/card-tab';
import DatePickerInput from 'components/datepicker';
import Header from 'components/header';
import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import openPage from 'infra/httpClient/openPage.js';
import moment from 'moment';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import ModalToPrint from 'pages/packandship/modal/label-to-print-when-completed';
import { numberWithCommas } from 'pages/receiving/receipts/subpage';
import { CreateReturnOverrideModal } from 'pages/sales/create-return-override-modal';
import { ModalReturnLineItem } from 'pages/sales/return-line-item-modal';
import ChecklistModal from 'pages/sales/salesorder/checklist-modal';
import {
  CompleteOrderManuallyModal,
  pageEnum,
} from 'pages/sales/salesorder/complete-order-manually-modal';
import ConfirmRemovePackingInProcessModal from 'pages/sales/salesorder/confirm-remove-packing-in-process';
import { OrderCompleteModal } from 'pages/sales/salesorder/order-complete-modal';
import OrderPickingUomModal from 'pages/sales/salesorder/order-picking-uom-modal';
import VoidThePackModal from 'pages/sales/salesorder/void-the-pack-modal';
import ConfirmationModal from 'pages/shared/confirmationmodal';
import ConfirmationDialog from 'pages/shared/confirmdeletemodal';
import BackOrderWarningModal from 'pages/shared/confirmsalesorderbackorderwarningmodal';
import ConfirmLineItemModal from 'pages/shared/confirmsalesorderlineitemmodal';
import ConfirmPickTicketModal from 'pages/shared/confirmsalesorderpickticketmodal';
import { LabelUrlPrint, LabelUrlPrintModel } from 'pages/shared/print/labelURL';
import PrintFormTemplate, {
  FormTemplateTypeEnum,
} from 'pages/shared/printformtemplate';
import LineItemModal from 'pages/shared/salesorderlineitemmodal';
import PickTicketModal from 'pages/shared/salesorderpickticketmodal';
import {
  getCartonCodeDDL,
  GetPackageForOrderDDL,
} from 'services/api/cartoncodes/cartoncodes.api';
import { GetItemAsPackageDDL } from 'services/api/item/item.api';
import {
  updatePackageToShipAndComplete,
  printShippingLabelByOrderId,
} from 'services/api/packandship/packandship.api';
import {
  getGenerateOrderNo,
  getPrevAddressesLookup,
  getOrderContactDetail,
  getPickTicketDetail,
  getShipmentLabels,
  uploadOrderDocument,
  getOrderDocuments,
  deleteOrderDocument,
  getOrderDocumentFile,
  deleteOrderNote,
  deleteOrderChecklist,
} from 'services/api/salesorders/salesorders-new.api';
import { getUsStatesList } from 'services/api/states';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { reportUrlVariable } from 'store/reporturl';
import { DatagridPremium, DatagridStandard } from 'styles';

import { ConstructionOutlined, Pageview, Search } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PrintIcon from '@mui/icons-material/Print';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import {
  IconButton,
  Typography,
  TextField,
  Box,
  Stack,
  Grid as MUIGrid,
  Theme,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
  Link,
  Tooltip,
  InputAdornment,
} from '@mui/material';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { MainContainer, ContentContainer } from '../../styles';
import OrderNotesModal from '../ordernotesmodal';
import { ModalPackageLineItem } from '../view-package-line-items-modal';
import {
  SalesOrderContextProvider,
  useSalesOrderContext,
  CustomerType,
  FacilityType,
  ModalTitleStatus,
  OrderBillingType,
  CartonCodeDDLType,
  CountryType,
  ShipToSearchPrevAddressType,
  BillToSearchPrevAddressType,
  IContactDetailType,
  CarrierType,
  UOMType,
  OrderStatus,
  OrderSourceType,
} from './context';
import { ChecklistModal as ChecklistModalModel } from './models';

enum OrderStatusProgressBar {
  New = 1,
  Open = 2,
  Picked = 3,
  Packed = 4,
  ReadyForPickup = 5,
  Complete = 6,
  Partial = 7,
  Canceled = 8,
}
export enum OrderLineItemTabValue {
  LineItems = '1',
  PickTickets = '2',
  Packages = '3',
  BillingCharge = '4',
}

const OrderProgressBar = () => {
  const { salesOrderFlagStatus, salesOrderForm } = useSalesOrderContext();

  return (
    <Box sx={{ width: '100%', padding: '35px 70px' }}>
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'space-evenly' }}
        >
          {salesOrderForm?.isReturn === true && (
            <Typography variant="body1" fontWeight="bold">
              Returned
            </Typography>
          )}

          {salesOrderForm?.isReturn !== true &&
            salesOrderFlagStatus?.statusProgressTexts?.length > 0 && (
              <>
                {salesOrderFlagStatus.statusProgressTexts.map((row: string) => (
                  <Typography key={row} variant="body1" fontWeight="bold">
                    {row}
                  </Typography>
                ))}
              </>
            )}
        </MUIGrid>
        <MUIGrid item xs={12}>
          {salesOrderForm?.isReturn === true ? (
            <LinearProgress
              variant="determinate"
              value={100}
              sx={{
                width: '100%',
                height: '10px',
                borderRadius: 2,
                [`& .${linearProgressClasses.bar1Determinate}`]: {
                  borderRadius: 2,
                  backgroundColor: 'rgb(158 135 255)',
                },
              }}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              value={salesOrderFlagStatus.statusProgressPercent}
              sx={{
                width: '100%',
                height: '10px',
                borderRadius: 2,
                backgroundColor: 'rgb(211 211 211)',
                [`& .${linearProgressClasses.bar1Determinate}`]: {
                  borderRadius: 2,
                  backgroundColor:
                    (salesOrderFlagStatus.statusProgress ===
                      OrderStatusProgressBar.Complete &&
                      '#00cf00') ||
                    ((salesOrderFlagStatus.statusProgress ===
                      OrderStatusProgressBar.Partial ||
                      salesOrderFlagStatus.statusProgress ===
                        OrderStatusProgressBar.Canceled) &&
                      'rgb(228,110,115)'),
                },
              }}
            />
          )}
        </MUIGrid>
      </MUIGrid>
    </Box>
  );
};

const OrderDetails = () => {
  const {
    currentLocationAndFacility,
    isWarehouseCustomerAccount,
    handleUserPermissionAllow,
    isAdmin,
  } = useContext(AuthContext);

  const {
    pageViewer,
    currentUser,
    carrierValue,
    setOptionSkus,
    isFormDisable,
    isOnHoldValue,
    optionCarriers,
    salesOrderForm,
    optionCustomers,
    optionFacilities,
    orderSourceValue,
    optionOrderSource,
    optionOrderBillings,
    carrierServiceValue,
    salesOrderFlagStatus,
    salesOrderFormErrors,
    isShipByDateHasValue,
    optionCarrierServices,
    queryStringItemOrders,
    packageCartonCodeValue,
    loadBillingChargesDetailByModule,
    onLoadSkuLookupWithwarehouseCustomer,
    setCarrierValue,
    setIsOnHoldValue,
    setSalesOrderForm,
    autoCompleteOnChange,
    salesOrderFormOnChange,
    setIsShipByDateHasValue,
    setPackageCartonCodeValue,
    onLoadOrderCarrierServices,
    setCarrierServiceValue,
    setOrderSourceValue,
  } = useSalesOrderContext();

  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [facilityValue, setFacilityValue] = useState<FacilityType>(null);

  const [optionPackageCartonCodes, setOptionPackageCartonCodes] = useState<
    CartonCodeDDLType[] | null | []
  >([]);
  const [filteredCartonCodes, setFilteredCartonCodes] = useState<
    CartonCodeDDLType[] | null | []
  >([]);

  const [orderBillValue, setOrderBillValue] = useState<OrderBillingType>(null);

  const onLoadGenerateNo = async () => {
    try {
      const generateOrderNoFromApi = await getGenerateOrderNo();

      return generateOrderNoFromApi;
    } catch (err) {
      return err;
    }
  };

  const filterCarrierPackages = (selected?: CarrierType) => {
    const cartonCodes: CartonCodeDDLType[] = [];
    optionPackageCartonCodes.forEach((x: CarrierType) => {
      if (!cartonCodes.some((y) => y.label === x.label)) cartonCodes.push(x);
    });

    if (!selected) {
      setFilteredCartonCodes(cartonCodes);
    } else {
      setFilteredCartonCodes(
        optionPackageCartonCodes.filter(
          (x: CarrierType) =>
            x.serviceCode === selected.serviceCode || !x.serviceCode,
        ),
      );
    }
  };

  const onLoadPackagesForOrder = async (warehouseCustomerId, carrierId) => {
    try {
      const result = await GetPackageForOrderDDL(
        warehouseCustomerId,
        carrierId,
      );
      return result;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (optionCustomers !== null) {
      const customer = (el: CustomerType) => {
        if (pageViewer.isNew() && queryStringItemOrders.isReturn !== true)
          return optionCustomers.length === 1
            ? el.label === currentUser.Claim_CustomerName
            : null;
        if (
          pageViewer.isViewOrEdit() ||
          queryStringItemOrders.isReturn === true
        )
          return el.value === salesOrderForm.warehouseCustomerId;
        return null;
      };

      const vmCustomer = optionCustomers.find(customer);

      if (vmCustomer !== undefined) {
        setCustomerValue(vmCustomer);
        setSalesOrderForm((prev) => ({
          ...prev,
          warehouseCustomerId: vmCustomer.value,
        }));
      }

      if (optionCustomers.length === 1) {
        optionCustomers.forEach((o) => {
          setCustomerValue((e) => ({
            ...e,
            value: o.value,
            label: o.label,
          }));
          setSalesOrderForm((prev) => ({
            ...prev,
            warehouseCustomerId: o.value,
          }));
        });
      }
    }

    if (optionFacilities !== null) {
      const facility = (el: FacilityType) => {
        if (pageViewer.isNew()) {
          if (!isWarehouseCustomerAccount) {
            return el.value === currentLocationAndFacility.customerFacilityId;
          }

          if (isWarehouseCustomerAccount) {
            if (optionFacilities.length === 1) {
              return el.value === optionFacilities[0];
            }
          }
        }

        if (pageViewer.isViewOrEdit()) {
          return el.value === salesOrderForm.customerFacilityId;
        }

        return null;
      };

      const vmFacility = optionFacilities.find(facility);
      if (vmFacility !== undefined) {
        setFacilityValue(vmFacility);
        setSalesOrderForm((prev) => ({
          ...prev,
          customerFacilityId: vmFacility.value,
        }));
      }
    }

    if (pageViewer.isNew() || queryStringItemOrders.isReturn === true) {
      const response = onLoadGenerateNo();

      if (queryStringItemOrders.isReturn === true) {
        response
          .then((value) =>
            setSalesOrderForm((prev) => ({
              ...prev,
              returnNo: value,
            })),
          )
          .catch();
      } else {
        response
          .then((value) =>
            setSalesOrderForm((prev) => ({
              ...prev,
              orderNo: value,
            })),
          )
          .catch();
      }
    }

    if (salesOrderForm.warehouseCustomerId !== undefined) {
      const optSkus = onLoadSkuLookupWithwarehouseCustomer(
        salesOrderForm.warehouseCustomerId,
      );
      optSkus
        .then((opt) => {
          setOptionSkus(
            opt.map((c: any) => ({
              label: c.sku,
              value: c.itemId,
              description: c.description,
            })),
          );
        })
        .catch();
    }
  }, [
    optionCustomers,
    optionFacilities,
    isOnHoldValue,
    salesOrderForm.warehouseCustomerId,
  ]);

  useEffect(() => {
    if (isFormDisable) {
      setIsShipByDateHasValue(true);
    } else {
      setIsShipByDateHasValue(false);

      if (
        salesOrderForm.shipByDateTime === null ||
        salesOrderForm.shipByDateTime === undefined
      ) {
        setIsShipByDateHasValue(true);
      } else if (salesOrderForm.shipByDateTime instanceof Date) {
        setIsShipByDateHasValue(false);
      } else {
        setIsShipByDateHasValue(true);
      }
    }
  }, [isShipByDateHasValue, isFormDisable]);

  useEffect(() => {
    if (optionOrderBillings !== null) {
      const billing = (el: CountryType) => {
        if (pageViewer.isNew()) return null;
        if (pageViewer.isViewOrEdit())
          return el.value === salesOrderForm?.billingType;
        return null;
      };

      const vmBilling = optionOrderBillings.find(billing);

      if (vmBilling !== undefined) {
        setOrderBillValue(vmBilling);
      }
    }
  }, [optionOrderBillings]);

  useEffect(() => {
    if (customerValue && carrierValue) {
      onLoadPackagesForOrder(
        customerValue?.value,
        carrierValue?.isShippingRate === false ? carrierValue.value : 0,
      ).then((resCarrier) => {
        const optCartonCodesCarrier = resCarrier.map((c: any) => ({
          label: c.text,
          value: c.value,
          dimensions: c.dimensions,
          isExternalPackage: c.isExternalPackage,
          serviceCode: c.serviceCode,
          acceptsVoidFill: c.acceptsVoidFill,
          packageType: c.packageType,
        }));

        setOptionPackageCartonCodes(optCartonCodesCarrier);
      });
    }
  }, [carrierValue, customerValue]);

  useEffect(() => {
    if (optionPackageCartonCodes.length > 0) {
      filterCarrierPackages();
    }
  }, [optionPackageCartonCodes]);
  return (
    <Card
      sx={{
        marginBottom: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        borderRadius: 2,
        border: '1px solid #cdcdcd',
      }}
    >
      <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <MUIGrid
          item
          md={6}
          sx={{
            borderRight: '2px solid #d8d8d8',
            padding: '30px',
            paddingBottom: '0px',
          }}
        >
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ alignItems: 'center' }}
          >
            <MUIGrid item xs={4}>
              <Typography variant="subtitle2" fontWeight="bold">
                Client*:
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={8} sx={{ marginBottom: '5px' }}>
              <Autocomplete
                disableClearable
                disabled={pageViewer.isViewOrEdit()}
                onChange={(event: any, newValue: CustomerType) => {
                  if (newValue !== null) {
                    setCustomerValue(newValue);
                    autoCompleteOnChange(event, newValue);
                    loadBillingChargesDetailByModule(newValue.value);
                  } else {
                    setCustomerValue(null);
                    autoCompleteOnChange(event, null, {
                      name: 'warehouseCustomerId',
                      value: undefined,
                    });
                  }

                  setCarrierValue(null);
                  setSalesOrderForm((prev) => ({
                    ...prev,
                    carrierId: null,
                    isShippingRate: null,
                    isLabelProvided: false,
                  }));
                }}
                id="controllable-states"
                options={optionCustomers}
                getOptionLabel={(option: CustomerType) => option.label}
                value={customerValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li
                    data-name="warehouseCustomerId"
                    {...props}
                    key={option.value}
                  >
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    {...(salesOrderFormErrors.customer && {
                      error: true,
                      helperText: salesOrderFormErrors.customer,
                    })}
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </MUIGrid>
            <MUIGrid
              item
              xs={4}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Facility:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <Autocomplete
                disabled={pageViewer.isViewOrEdit()}
                onChange={(event: any, newValue: FacilityType) => {
                  if (newValue !== null) {
                    setFacilityValue((prevState) => ({
                      ...prevState,
                      value: newValue.value,
                      label: newValue.label,
                    }));
                    setSalesOrderForm((prevState) => ({
                      ...prevState,
                      customerFacilityId: newValue.value,
                    }));
                  } else {
                    setFacilityValue(null);
                    setSalesOrderForm((prevState) => ({
                      ...prevState,
                      customerFacilityId: undefined,
                    }));
                  }
                }}
                id="controllable-states"
                options={optionFacilities}
                getOptionLabel={(option: FacilityType) => option.label}
                value={facilityValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li
                    data-name="customerFacilityId"
                    {...props}
                    key={option.value}
                  >
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    {...(salesOrderFormErrors.facility && {
                      error: true,
                      helperText: salesOrderFormErrors.facility,
                    })}
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </MUIGrid>

            <MUIGrid
              item
              xs={4}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Reference No.:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <TextField
                inputProps={{
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                disabled={isFormDisable}
                id="outlined"
                name="referenceNo"
                value={salesOrderForm.referenceNo || undefined || ''}
                onChange={salesOrderFormOnChange}
                size="small"
                sx={{ width: '100%' }}
              />
            </MUIGrid>
            <MUIGrid
              item
              xs={4}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Order Rule:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <Autocomplete
                disabled={isFormDisable}
                onChange={(event: any, newValue: OrderSourceType) => {
                  if (newValue !== null) {
                    setOrderSourceValue(newValue);
                    setSalesOrderForm((prevState) => ({
                      ...prevState,
                      orderSource: newValue.label,
                    }));
                  } else {
                    setOrderSourceValue(null);
                    setSalesOrderForm((prevState) => ({
                      ...prevState,
                      orderSource: '',
                    }));
                  }
                }}
                id="controllable-states"
                options={optionOrderSource}
                getOptionLabel={(option: OrderSourceType) => option.label}
                value={orderSourceValue}
                isOptionEqualToValue={(option, selected) =>
                  option?.value === selected?.value
                }
                renderOption={(props, option) => (
                  <li data-name="orderSourceId" {...props} key={option.value}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    {...(salesOrderFormErrors.orderSource && {
                      error: true,
                      helperText: salesOrderFormErrors.orderSource,
                    })}
                    id="outlined-required"
                    size="small"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </MUIGrid>
            <MUIGrid
              item
              xs={4}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Ship via Carrier*:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                }}
              >
                <Autocomplete
                  disableClearable
                  sx={{ width: '100%' }}
                  disabled={isFormDisable}
                  onChange={(event: any, newValue: CarrierType) => {
                    if (newValue !== null) {
                      setCarrierValue(newValue);
                      setSalesOrderForm((prev) => ({
                        ...prev,
                        carrierId: newValue.value,
                        carrier: newValue.label,
                        isShippingRate: newValue.isShippingRate,
                        isLabelProvided: false,
                      }));
                      onLoadOrderCarrierServices(newValue.value);
                    } else {
                      setCarrierValue(null);
                      setSalesOrderForm((prev) => ({
                        ...prev,
                        carrierId: null,
                        carrier: null,
                        isShippingRate: null,
                        isLabelProvided: false,
                      }));
                    }
                    setCarrierServiceValue(null);
                    setPackageCartonCodeValue(null);
                  }}
                  id="controllable-states"
                  options={optionCarriers}
                  getOptionLabel={(option: CarrierType) => option.label}
                  value={
                    carrierValue?.label === undefined ? null : carrierValue
                  }
                  isOptionEqualToValue={(option, selected) =>
                    option.value === selected.value
                  }
                  renderOption={(props, option) => (
                    <li data-name="carrierType" {...props}>
                      {option.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      {...(salesOrderFormErrors.carrier && {
                        error: true,
                        helperText: salesOrderFormErrors.carrier,
                      })}
                      autoComplete="off"
                      id="outlined"
                      size="small"
                      sx={{ width: '100%', background: '#ffffff' }}
                    />
                  )}
                />

                {customerValue !== null &&
                  ((salesOrderForm?.isShippingRate === false &&
                    salesOrderForm?.carrierId !== null) ||
                    (salesOrderForm?.isShippingRate &&
                      salesOrderForm?.carrierId ===
                        OrderShippingRateEnum.BestWay)) && (
                    <>
                      <Checkbox
                        sx={{ paddingRight: '0px' }}
                        color="primary"
                        disabled={isFormDisable}
                        size="medium"
                        checked={salesOrderForm.isLabelProvided || false}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          setSalesOrderForm((prev) => ({
                            ...prev,
                            isLabelProvided: event.target.checked,
                          }));
                        }}
                      />
                      <Tooltip
                        sx={{ paddingRight: '0px' }}
                        title="Check when you already have the shipping label printed for this order."
                        placement="bottom"
                        arrow
                      >
                        <IconButton aria-label="print" color="primary">
                          <HelpOutlineOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
              </Box>
            </MUIGrid>

            <MUIGrid
              item
              xs={4}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Carrier Service:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={8}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <Autocomplete
                disabled={isFormDisable}
                onChange={(event: any, newValue: CarrierType) => {
                  if (newValue !== null) {
                    setCarrierServiceValue(newValue);
                    setSalesOrderForm((prev) => ({
                      ...prev,
                      carrierService:
                        newValue.isShippingRate === true
                          ? newValue.label
                          : newValue.serviceCode,
                    }));
                  } else {
                    setCarrierServiceValue(null);
                    setSalesOrderForm((prev) => ({
                      ...prev,
                      carrierService: null,
                    }));
                  }
                  filterCarrierPackages(newValue);
                }}
                id="controllable-states"
                options={optionCarrierServices}
                getOptionLabel={(option: CarrierType) => option.label}
                value={carrierServiceValue}
                isOptionEqualToValue={(option, selected) =>
                  option.value === selected.value
                }
                renderOption={(props, option) => (
                  <li data-name="carrierType" {...props}>
                    {option.label}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    size="small"
                    sx={{ width: '100%', background: '#ffffff' }}
                  />
                )}
              />
            </MUIGrid>
            {!isWarehouseCustomerAccount && (
              <>
                <MUIGrid
                  item
                  xs={4}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Package:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={8}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                    marginTop: '5px',
                  }}
                >
                  <Autocomplete
                    disabled={isFormDisable}
                    id="grouped-demo"
                    onChange={(event: any, newValue: CartonCodeDDLType) => {
                      if (newValue !== null) {
                        setPackageCartonCodeValue(newValue);
                        autoCompleteOnChange(event, newValue);
                        setSalesOrderForm((prev) => ({
                          ...prev,
                          carrierPackage: newValue,
                        }));
                      } else {
                        setPackageCartonCodeValue(null);
                      }
                    }}
                    getOptionLabel={(option: CartonCodeDDLType) => option.label}
                    value={packageCartonCodeValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    options={filteredCartonCodes}
                    groupBy={(option) => option.packageType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                          endadornment: null,
                        }}
                        autoComplete="off"
                        id="outlined"
                        size="small"
                        sx={{ width: '100%', background: '#ffffff' }}
                      />
                    )}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Box display="flex" width="100%">
                          <Box display="flex" flexDirection="column">
                            <Typography color="text.primary">
                              {option.label}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                              {option.dimensions}
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    )}
                    renderGroup={(params) => (
                      <Box
                        key={params.key}
                        display="flex"
                        flexDirection="column"
                      >
                        <Typography fontWeight="bold" p={1}>
                          {params.group}
                        </Typography>
                        {params.children}
                      </Box>
                    )}
                  />
                </MUIGrid>

                {(salesOrderFlagStatus.statusStr === 'Complete' ||
                  salesOrderFlagStatus.statusStr === 'Partial') &&
                  handleUserPermissionAllow(UserPermissionEnum.Orders_Edit) && (
                    <>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Our Cost:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingTop: '0px !important',
                          marginTop: '5px',
                        }}
                      >
                        <Input
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          placeholder=""
                          disabled={isFormDisable}
                          value={salesOrderForm?.shipmentCost || undefined || 0}
                          type="number"
                          onChange={(e) => {
                            if (e.target.value <= 0) {
                              e.target.value = 0;
                            }
                            const validateDecimal = decimalValidationHelper(
                              e.target.value,
                            );
                            setSalesOrderForm((prev) => ({
                              ...prev,
                              shipmentCost: Number(validateDecimal),
                            }));
                          }}
                          size="small"
                          sx={{ width: '100%' }}
                          {...(salesOrderFormErrors.shipmentCost && {
                            error: true,
                            helperText: salesOrderFormErrors.shipmentCost,
                          })}
                        />
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Total Shipment Cost:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingTop: '0px !important',
                          marginTop: '5px',
                        }}
                      >
                        <Input
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          placeholder=""
                          disabled={isFormDisable}
                          value={
                            salesOrderForm?.totalShipmentCost || undefined || 0
                          }
                          type="number"
                          onChange={(e) => {
                            if (e.target.value <= 0) {
                              e.target.value = 0;
                            }
                            const validateDecimal = decimalValidationHelper(
                              e.target.value,
                            );
                            setSalesOrderForm((prev) => ({
                              ...prev,
                              totalShipmentCost: Number(validateDecimal),
                            }));
                          }}
                          {...(salesOrderFormErrors.totalShipmentCost && {
                            error: true,
                            helperText: salesOrderFormErrors.totalShipmentCost,
                          })}
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      </MUIGrid>
                    </>
                  )}
              </>
            )}
            {salesOrderForm.integrationChannel
              ?.substring(0, 6)
              .toLowerCase() === 'amazon' && (
              <>
                <MUIGrid
                  item
                  xs={4}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Latest Delivery Date:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={8}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <DatePickerInput
                    disabled
                    inputFormat="MM/dd/yyyy"
                    value={salesOrderForm.latestDeliveryDate || null || ''}
                    renderInput={(params) => (
                      <TextField {...params} error={false} size="small" />
                    )}
                  />
                </MUIGrid>
              </>
            )}
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          item
          md={6}
          sx={{
            padding: '30px',
            marginBottom:
              salesOrderForm?.isReturn === true ? '-26px !important' : '0',
          }}
        >
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ alignItems: 'center' }}
          >
            <MUIGrid item xs={6}>
              <Typography variant="subtitle2" fontWeight="bold">
                Purchase Order:
              </Typography>
            </MUIGrid>
            <MUIGrid item xs={6} sx={{ marginBottom: '5px' }}>
              <TextField
                inputProps={{
                  autoComplete: autoCompleteTimeStamp().toString(),
                }}
                autoComplete="off"
                disabled={isFormDisable}
                id="outlined"
                name="purchaseOrder"
                value={salesOrderForm.purchaseOrder || undefined || ''}
                onChange={salesOrderFormOnChange}
                size="small"
                sx={{ width: '100%' }}
              />
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                {salesOrderForm?.isReturn === true ? 'Return' : 'Order '} Date:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <DatePickerInput
                disabled={isFormDisable}
                inputFormat="MM/dd/yyyy"
                value={salesOrderForm.creationDate || null || ''}
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid Date') {
                    setSalesOrderForm((prevState) => ({
                      ...prevState,
                      creationDate: e.toLocaleString().split(',')[0] as Date,
                    }));
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} error={false} size="small" />
                )}
              />
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                {salesOrderForm?.isReturn === true ? 'Return' : 'Order '} Time:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <TextField
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    disabled={isFormDisable}
                    id="time"
                    size="small"
                    type="time"
                    value={salesOrderForm.creationTime || null || ''}
                    onChange={(e) => {
                      const date = new Date(salesOrderForm.creationDate)
                        .toISOString()
                        .split('T')[0];
                      const dateTime = new Date(
                        Date.parse(`${date}T${e.currentTarget.value}:00.000Z`),
                      );

                      setSalesOrderForm((prevState) => ({
                        ...prevState,
                        creationTime: e.currentTarget.value,
                        creationDateTime: dateTime.toISOString(),
                      }));
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
              </LocalizationProvider>
            </MUIGrid>
            {salesOrderForm?.isReturn === true && (
              <>
                <MUIGrid
                  item
                  xs={6}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Original Order No.:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                    margin: '5px 0px',
                  }}
                >
                  <Typography variant="subtitle2">
                    {pageViewer.isEdit()
                      ? salesOrderForm.originalOrderNo
                      : salesOrderForm?.orderNo}
                  </Typography>
                </MUIGrid>
              </>
            )}
            <MUIGrid
              item
              xs={6}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Ship by Date:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <DatePickerInput
                disabled={isFormDisable}
                inputFormat="MM/dd/yyyy"
                value={salesOrderForm.shipByDateTime || undefined || ''}
                onChange={(e) => {
                  if (e !== null && e !== 'Invalid Date') {
                    if (e instanceof Date) {
                      setSalesOrderForm((prevState) => ({
                        ...prevState,
                        shipByDateTime: e,
                      }));
                      setIsShipByDateHasValue(false);
                    } else {
                      setIsShipByDateHasValue(true);
                    }
                  }
                }}
                renderInput={(params) => (
                  <TextField disabled {...params} error={false} size="small" />
                )}
              />
              {/* <Typography variant="subtitle2">
                  {salesOrderForm.shipByDateTime
                    ? moment(salesOrderForm.shipByDateTime).format(
                        'MMM DD YYYY',
                      )
                    : '--'}
                </Typography> */}
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                Ship Cancel Date:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
                margin: '5px 0px',
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePickerInput
                  disabled={isShipByDateHasValue}
                  inputFormat="MM/dd/yyyy"
                  value={salesOrderForm.cancelByDateTime || undefined || ''}
                  onChange={(e) => {
                    if (e !== null && e !== 'Invalid Date') {
                      if (
                        salesOrderForm.shipByDateTime instanceof Date &&
                        e instanceof Date
                      ) {
                        if (salesOrderForm.shipByDateTime > e) {
                          snackActions.error(
                            'Ship Cancel Date cannot be before Ship By Date',
                          );
                        } else {
                          setSalesOrderForm((prevState) => ({
                            ...prevState,
                            cancelByDateTime: e,
                          }));
                        }
                      }
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} error={false} size="small" />
                  )}
                />
              </LocalizationProvider>
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
            >
              <Typography variant="subtitle2" fontWeight="bold">
                On Hold:
              </Typography>
            </MUIGrid>
            <MUIGrid
              item
              xs={6}
              sx={{
                paddingLeft: '10px',
                paddingTop: '0px !important',
              }}
            >
              <Checkbox
                color="primary"
                disabled={isFormDisable}
                size="medium"
                checked={isOnHoldValue || false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setIsOnHoldValue(event.target.checked);
                  setSalesOrderForm((prev) => ({
                    ...prev,
                    onHold: event.target.checked,
                  }));
                }}
              />
            </MUIGrid>

            {(salesOrderFlagStatus.statusStr === 'Complete' ||
              salesOrderFlagStatus.statusStr === 'Partial') && (
              <>
                <MUIGrid
                  item
                  xs={6}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Ship Date:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <DatePickerInput
                    disabled={isFormDisable}
                    inputFormat="MM/dd/yyyy"
                    value={salesOrderForm.completeDateTime}
                    onChange={(e) => {
                      if (e !== null && e !== 'Invalid Date') {
                        setSalesOrderForm((prevState) => ({
                          ...prevState,
                          completeDateTime: e,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        disabled
                        {...params}
                        error={false}
                        size="small"
                      />
                    )}
                  />
                </MUIGrid>
              </>
            )}
            {salesOrderForm?.deliveryType && (
              <>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                    margin: '5px 0px',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Delivery Method:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                    margin: '5px 0px',
                  }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm.deliveryType}
                  </Typography>
                </MUIGrid>
              </>
            )}
            {salesOrderForm?.shippingCost && (
              <>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                    margin: '5px 0px',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Paid Shipping Cost:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                    margin: '5px 0px',
                  }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm.shippingCost}
                  </Typography>
                </MUIGrid>
              </>
            )}
            {salesOrderForm.integrationChannel
              ?.substring(0, 6)
              .toLowerCase() === 'amazon' && (
              <>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Latest Delivery Date:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm.latestDeliveryDate
                      ? moment(salesOrderForm.latestDeliveryDate).format(
                          'MMM DD YYYY',
                        )
                      : '--'}
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Is Fba:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm.isFba}
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Is Prime:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={6}
                  sx={{
                    paddingLeft: '10px',
                    paddingTop: '0px !important',
                  }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm.isPrime}
                  </Typography>
                </MUIGrid>
              </>
            )}
          </MUIGrid>
        </MUIGrid>
      </MUIGrid>
    </Card>
  );
};

const ContactDetails = ({ theme }: { theme?: Theme }) => {
  const {
    isFormDisable,
    pageViewer,
    optionCountries,
    salesOrderForm,
    salesOrderFormErrors,
    setSalesOrderForm,
    salesOrderFormOnChange,
    autoCompleteOnChange,
  } = useSalesOrderContext();
  const [tabValue, setTabValue] = React.useState<string>('1');
  const [useShipToForBillToChecked, setUseShipToForBillToChecked] =
    React.useState<boolean>(false);
  const [shipToCountryValue, setShipToCountryValue] =
    useState<CountryType>(null);
  const [billToCountryValue, setBillToCountryValue] =
    useState<CountryType>(null);
  const [optionShipToSearchPrevAddresses, setOptionShipToSearchPrevAddresses] =
    useState<ShipToSearchPrevAddressType[] | null | []>([]);
  const [optionBillToSearchPrevAddresses, setOptionBillToSearchPrevAddresses] =
    useState<BillToSearchPrevAddressType[] | null | []>([]);
  const [billToSearchPrevAddresseValue, setBillToSearchPrevAddresseValue] =
    useState<CountryType>(null);
  const [shipToSearchPrevAddresseValue, setShipToSearchPrevAddresseValue] =
    useState<CountryType>(null);
  const [optShipStates, setOptShipStates] = useState<IAutoComplete[]>([]);
  const [optBillStates, setOptBillStates] = useState<IAutoComplete[]>([]);
  const [shipToStatesValue, setShipToStatesValue] =
    useState<IAutoComplete>(null);
  const [billToStatesValue, setBillToStatesValue] =
    useState<IAutoComplete>(null);

  const onLoadPrevAddressesLookup = async (contact) => {
    try {
      return await getPrevAddressesLookup(
        salesOrderForm.customerFacilityId,
        salesOrderForm.warehouseCustomerId,
        contact,
      );
    } catch (err) {
      return err;
    }
  };

  const onLoadContactDetail = async (contactDetailId) => {
    try {
      return await getOrderContactDetail(contactDetailId);
    } catch (err) {
      return err;
    }
  };

  const fetchDropdownStates = async (country: number) => {
    const res: IAutoComplete[] = [];
    if (country !== 236) {
      return res;
    }
    try {
      const data = await getUsStatesList();
      const opt = data.map((r) => ({ label: r.name, value: r.code }));
      return opt;
    } catch {
      return res;
    }
  };

  useEffect(() => {
    if (
      salesOrderForm.warehouseCustomerId !== undefined &&
      salesOrderForm.customerFacilityId
    ) {
      const contactType = (callback) => callback();

      const optionPrevAddress = onLoadPrevAddressesLookup(
        contactType(() => {
          let type = '';

          if (tabValue === '1') {
            type = 'shipTo';
          }
          if (tabValue === '2') {
            if (useShipToForBillToChecked) {
              type = 'shipTo';
            } else {
              type = 'billTo';
            }
          }
          return type;
        }),
      );

      optionPrevAddress
        .then((opt) => {
          if (tabValue === '1') {
            setOptionShipToSearchPrevAddresses(
              opt.map((c: any) => ({
                label: c.name,
                value: c.orderContactDetailId,
              })),
            );
          }

          if (tabValue === '2') {
            setBillToSearchPrevAddresseValue(null);
            setOptionBillToSearchPrevAddresses(
              opt.map((c: any) => ({
                label: c.name,
                value: c.orderContactDetailId,
              })),
            );
          } else if (useShipToForBillToChecked) {
            setBillToSearchPrevAddresseValue(null);
            setOptionShipToSearchPrevAddresses(
              opt.map((c: any) => ({
                label: c.name,
                value: c.orderContactDetailId,
              })),
            );
          }
        })
        .catch();
    } else {
      setOptionShipToSearchPrevAddresses([]);
      setOptionBillToSearchPrevAddresses([]);
    }
  }, [
    tabValue,
    useShipToForBillToChecked,
    salesOrderForm.warehouseCustomerId,
    salesOrderForm.customerFacilityId,
  ]);

  useEffect(() => {
    if (tabValue === '1') {
      if (shipToSearchPrevAddresseValue !== null) {
        onLoadContactDetail(shipToSearchPrevAddresseValue?.value)
          .then((x: IContactDetailType) => {
            setSalesOrderForm((prev) => ({
              ...prev,
              shipTo: {
                ...prev.shipTo,
                orderContactDetailId: x.orderContactDetailId,
                contactDetailType: 1,
                codeId: x.codeId,
                recipientName: x.recipientName,
                companyName: x.companyName,
                phone: x.phone,
                email: x.email,
                address1: x.address1,
                address2: x.address2,
                country: x.country,
                stateProvinceRegion: x.stateProvinceRegion,
                cityTownship: x.cityTownship,
                zipPostalCode: x.zipPostalCode,
                retailerId: x.retailerId,
                departmentNumber: x.departmentNumber,
              },
            }));

            setShipToCountryValue(
              optionCountries.find((o) => o?.value === x.country),
            );

            if (x.country === 236) {
              setShipToStatesValue(
                optShipStates.find((o) => o.label === x.stateProvinceRegion),
              );
            }
          })
          .catch();
      }
    }
    if (tabValue === '2') {
      if (billToSearchPrevAddresseValue !== null) {
        onLoadContactDetail(billToSearchPrevAddresseValue?.value)
          .then((x: IContactDetailType) => {
            setSalesOrderForm((prev) => ({
              ...prev,
              billTo: {
                ...prev.billTo,
                orderContactDetailId: x.orderContactDetailId,
                contactDetailType: 2,
                codeId: x.codeId,
                recipientName: x.recipientName,
                companyName: x.companyName,
                phone: x.phone,
                email: x.email,
                address1: x.address1,
                address2: x.address2,
                country: x.country,
                stateProvinceRegion: x.stateProvinceRegion,
                cityTownship: x.cityTownship,
                zipPostalCode: x.zipPostalCode,
                retailerId: x.retailerId,
                departmentNumber: x.departmentNumber,
              },
            }));

            setBillToCountryValue(
              optionCountries.find(
                (o) => o?.value === billToSearchPrevAddresseValue?.value,
              ),
            );

            if (x.country === 236) {
              setBillToStatesValue(
                optBillStates.find((o) => o.value === x.stateProvinceRegion),
              );
            }
          })
          .catch();
      }
    }
  }, [shipToSearchPrevAddresseValue, billToSearchPrevAddresseValue]);

  useEffect(() => {
    if (shipToCountryValue) {
      fetchDropdownStates(shipToCountryValue.value).then((result) =>
        setOptShipStates(result),
      );
    }
    if (billToCountryValue) {
      fetchDropdownStates(billToCountryValue.value).then((result) =>
        setOptBillStates(result),
      );
    }
  }, [shipToCountryValue, billToCountryValue]);

  const handleOnChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUseShipToForBillToChecked(event.target.checked);
    setSalesOrderForm((prevState) => ({
      ...prevState,
      useShipToDetailsForBillTo: event.target.checked,
    }));
    if (event.target.checked === true) {
      setBillToCountryValue(shipToCountryValue);

      setSalesOrderForm((prevState) => ({
        ...prevState,
        billTo: prevState.shipTo,
      }));
      setBillToStatesValue(
        optBillStates.find(
          (x) => x.label === salesOrderForm.shipTo.stateProvinceRegion,
        ),
      );
    }
  };

  useEffect(() => {
    if (optionCountries !== null || optionCountries.length !== 0) {
      const shipToCountry = (el: CountryType) => {
        if (pageViewer.isNew()) {
          if (
            salesOrderForm?.shipTo?.country === null ||
            salesOrderForm?.shipTo?.country === undefined
          ) {
            setSalesOrderForm((prev) => ({
              ...prev,
              shipTo: {
                ...prev.shipTo,
                country: 236,
                stateProvinceRegion: '',
              },
            }));
            return el.value === 236;
          }
        }
        if (pageViewer.isViewOrEdit())
          return el.value === salesOrderForm.shipTo.country;
        return null;
      };

      const vmShipToCountry = optionCountries.find(shipToCountry);
      if (vmShipToCountry !== undefined) {
        setShipToCountryValue(vmShipToCountry);

        if (vmShipToCountry.value === 236) {
          const info = optShipStates.find(
            (r) => r.label === salesOrderForm.shipTo.stateProvinceRegion,
          );

          if (info) {
            setShipToStatesValue(info);
          }
        }
      }

      const billToCountry = (el: CountryType) => {
        if (pageViewer.isNew()) {
          if (
            salesOrderForm?.billTo?.country === null ||
            salesOrderForm?.billTo?.country === undefined
          ) {
            setSalesOrderForm((prev) => ({
              ...prev,
              billTo: {
                ...prev.billTo,
                country: 236,
              },
            }));
            return el.value === 236;
          }
        }
        if (pageViewer.isViewOrEdit())
          return el.value === salesOrderForm.billTo.country;
        return null;
      };

      const vmBillToCountry = optionCountries.find(billToCountry);
      if (vmBillToCountry !== undefined) {
        setBillToCountryValue(vmBillToCountry);
        if (vmBillToCountry.value === 236) {
          const info = optBillStates.find(
            (r) => r.label === salesOrderForm.billTo.stateProvinceRegion,
          );
          if (info) {
            setBillToStatesValue(info);
          }
        }
      }
    }

    if (pageViewer.isViewOrEdit())
      setUseShipToForBillToChecked(salesOrderForm.useShipToDetailsForBillTo);
  }, [
    salesOrderForm.warehouseCustomerId,
    salesOrderForm.customerFacilityId,
    optShipStates,
    useShipToForBillToChecked,
  ]);

  const [valueSecond, setValueSecond] = React.useState('1');
  const handleChangeSecond = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setValueSecond(newValue);
  };

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            marginBottom: 1,
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={valueSecond}
          handleChange={handleChangeSecond}
          tabView={[
            {
              title: 'Ship To',
              value: '1',
              isDisplay: true,
              children: (
                <Box
                  sx={{
                    padding: '21px',
                    paddingBottom: '37px',
                  }}
                >
                  <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <MUIGrid item md={6}>
                      <Autocomplete
                        disabled={isFormDisable}
                        onChange={(
                          event: any,
                          newValue: ShipToSearchPrevAddressType,
                        ) => {
                          if (newValue !== null) {
                            setShipToSearchPrevAddresseValue(newValue);
                            autoCompleteOnChange(event, newValue);
                          } else {
                            setShipToSearchPrevAddresseValue(null);
                            autoCompleteOnChange(event, null, {
                              name: 'shipTo',
                              data: {
                                state: 'orderContactDetailId',
                                value: 0,
                              },
                            });
                          }
                        }}
                        id="controllable-states"
                        options={optionShipToSearchPrevAddresses}
                        getOptionLabel={(option: ShipToSearchPrevAddressType) =>
                          option.label
                        }
                        value={shipToSearchPrevAddresseValue}
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        renderOption={(props, option) => (
                          <li
                            data-name="shipTo"
                            data-state="orderContactDetailId"
                            {...props}
                            key={option.value}
                          >
                            {option.label}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Input
                            {...params}
                            sx={{
                              '& .MuiFormLabel-root': {
                                fontSize: '0.8rem',
                              },
                            }}
                            placeholder="Search Previous (Company Name, Address)"
                            rightIcon={<Search />}
                          />
                        )}
                      />
                    </MUIGrid>
                    {useShipToForBillToChecked !== undefined && (
                      <MUIGrid item md={6} textAlign="right">
                        <FormControlLabel
                          disabled={isFormDisable}
                          control={
                            <Checkbox
                              onChange={handleOnChangeCheckbox}
                              {...(useShipToForBillToChecked !== undefined && {
                                checked: useShipToForBillToChecked,
                              })}
                            />
                          }
                          label="Use Ship To details for Bill To"
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>
                  <Box
                    sx={{
                      marginTop: 2,
                      padding: 2,
                      width: '100%',
                      backgroundColor:
                        theme.palette.mode === 'light' ? '#f5f5f5' : '#17262f',
                    }}
                  >
                    <MUIGrid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ margin: '17px' }}
                    >
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'companyName',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Company Name"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.companyName || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'recipientName',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.shipToRecipient && {
                            error: true,
                            helperText: salesOrderFormErrors.shipToRecipient,
                          })}
                          id="outlined-required"
                          label="Recipient Name"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.recipientName || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'email',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Email"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.email || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'phone',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Phone"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.phone || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                          {...(salesOrderFormErrors?.phone && {
                            error: true,
                            helperText: salesOrderFormErrors?.phone,
                          })}
                        />
                      </MUIGrid>
                    </MUIGrid>

                    <MUIGrid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ margin: '17px' }}
                    >
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'address1',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.shipToAddress1 && {
                            error: true,
                            helperText: salesOrderFormErrors.shipToAddress1,
                          })}
                          id="outlined-required"
                          label="Address 1"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.address1 || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'address2',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Address 2"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.address2 || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'cityTownship',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.shipToCityTownship && {
                            error: true,
                            helperText: salesOrderFormErrors.shipToCityTownship,
                          })}
                          id="outlined-required"
                          label="City/Township"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.cityTownship || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                    </MUIGrid>

                    <MUIGrid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ margin: '17px' }}
                    >
                      <MUIGrid item xs={4}>
                        {shipToCountryValue?.value === 236 ? (
                          <Autocomplete
                            id="controllable-states"
                            disableClearable
                            disabled={isFormDisable}
                            options={optShipStates}
                            value={shipToStatesValue}
                            isOptionEqualToValue={(option, selected) =>
                              option?.value === selected?.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined"
                                label="State/Province/Region"
                                size="small"
                                sx={{ width: '100%', background: '#ffffff' }}
                                autoComplete="off"
                              />
                            )}
                            onChange={(event: any, newValue: any) => {
                              if (newValue !== null) {
                                setShipToStatesValue(newValue);
                                setSalesOrderForm((e) => ({
                                  ...e,
                                  shipTo: {
                                    ...e.shipTo,
                                    stateProvinceRegion: newValue.label,
                                  },
                                }));
                              } else {
                                setShipToStatesValue(newValue);
                                setSalesOrderForm((e) => ({
                                  ...e,
                                  shipTo: {
                                    ...e.shipTo,
                                    stateProvinceRegion: null,
                                  },
                                }));
                              }
                            }}
                          />
                        ) : (
                          <TextField
                            inputProps={{
                              'data-state': 'Region',
                              autoComplete: autoCompleteTimeStamp().toString(),
                            }}
                            autoComplete="off"
                            disabled={isFormDisable}
                            id="outlined"
                            label="State/Province/Region"
                            size="small"
                            name="shipTo"
                            value={
                              salesOrderForm?.shipTo?.stateProvinceRegion || ''
                            }
                            onChange={(v) =>
                              setSalesOrderForm((e) => ({
                                ...e,
                                shipTo: {
                                  ...e.shipTo,
                                  stateProvinceRegion: v.target.value,
                                },
                              }))
                            }
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                          />
                        )}
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'zipPostalCode',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.shipToZipPostalCode && {
                            error: true,
                            helperText:
                              salesOrderFormErrors.shipToZipPostalCode,
                          })}
                          id="outlined-required"
                          label="ZIP/Postal Code"
                          size="small"
                          name="shipTo"
                          value={salesOrderForm?.shipTo?.zipPostalCode || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <Autocomplete
                          disableClearable
                          disabled={isFormDisable}
                          id="controllable-states"
                          options={optionCountries}
                          getOptionLabel={(option: CountryType) => option.label}
                          value={shipToCountryValue}
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderOption={(props, option) => (
                            <li
                              data-name="shipTo"
                              data-state="country"
                              {...props}
                            >
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              autoComplete="off"
                              {...(salesOrderFormErrors.shipToCountry && {
                                error: true,
                                helperText: salesOrderFormErrors.shipToCountry,
                              })}
                              id="outlined-required"
                              label="Country"
                              size="small"
                              sx={{ width: '100%', background: '#ffffff' }}
                            />
                          )}
                          onChange={(event: any, newValue: CountryType) => {
                            if (newValue !== null) {
                              setShipToCountryValue(newValue);
                              setSalesOrderForm((e) => ({
                                ...e,
                                shipTo: {
                                  ...e.shipTo,
                                  country: newValue.value,
                                  stateProvinceRegion: null,
                                },
                              }));
                            } else {
                              setShipToCountryValue(null);
                              setSalesOrderForm((e) => ({
                                ...e,
                                shipTo: {
                                  ...e.shipTo,
                                  country: null,
                                  stateProvinceRegion: null,
                                },
                              }));
                            }
                            setShipToStatesValue(null);
                          }}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  </Box>
                </Box>
              ),
            },
            {
              title: 'Bill To',
              value: '2',
              isDisplay: true,
              children: (
                <Box sx={{ padding: '21px', paddingBottom: '37px' }}>
                  <MUIGrid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <MUIGrid item md={6}>
                      <Autocomplete
                        disabled={isFormDisable}
                        onChange={(
                          event: any,
                          newValue: ShipToSearchPrevAddressType,
                        ) => {
                          if (newValue !== null) {
                            setBillToSearchPrevAddresseValue(newValue);
                            autoCompleteOnChange(event, newValue);
                            setUseShipToForBillToChecked(false);
                          } else {
                            setBillToSearchPrevAddresseValue(null);
                            autoCompleteOnChange(event, null, {
                              name: 'billTo',
                              data: {
                                state: 'orderContactDetailId',
                                value: 0,
                              },
                            });
                          }
                        }}
                        id="controllable-states"
                        options={optionBillToSearchPrevAddresses}
                        getOptionLabel={(option: ShipToSearchPrevAddressType) =>
                          option.label
                        }
                        value={billToSearchPrevAddresseValue}
                        isOptionEqualToValue={(option, selected) =>
                          option.value === selected.value
                        }
                        renderOption={(props, option) => (
                          <li
                            data-name="billTo"
                            data-state="orderContactDetailId"
                            {...props}
                            key={option.value}
                          >
                            {option.label}
                          </li>
                        )}
                        renderInput={(params) => (
                          <Input
                            {...params}
                            sx={{
                              '& .MuiFormLabel-root': {
                                fontSize: '0.8rem',
                              },
                            }}
                            placeholder="Search Previous (Company Name, Address)"
                            rightIcon={<Search />}
                          />
                        )}
                      />
                    </MUIGrid>
                    {useShipToForBillToChecked !== undefined && (
                      <MUIGrid item md={6} textAlign="right">
                        <FormControlLabel
                          disabled={isFormDisable}
                          control={
                            <Checkbox
                              onChange={handleOnChangeCheckbox}
                              {...(useShipToForBillToChecked !== undefined && {
                                checked: useShipToForBillToChecked,
                              })}
                            />
                          }
                          label="Use Ship To details for Bill To"
                        />
                      </MUIGrid>
                    )}
                  </MUIGrid>
                  <Box
                    sx={{
                      marginTop: 2,
                      padding: 2,
                      width: '100%',
                      backgroundColor:
                        theme.palette.mode === 'light' ? '#f5f5f5' : '#17262f',
                    }}
                  >
                    <MUIGrid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ margin: '17px' }}
                    >
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'companyName',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Company Name"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.companyName || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'recipientName',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.billToRecipient && {
                            error: true,
                            helperText: salesOrderFormErrors.billToRecipient,
                          })}
                          id="outlined-required"
                          label="Recipient Name"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.recipientName || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'email',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Email"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.email || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={3}>
                        <TextField
                          inputProps={{
                            'data-state': 'phone',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Phone"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.phone || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                          {...(salesOrderFormErrors?.billPhone && {
                            error: true,
                            helperText: salesOrderFormErrors?.billPhone,
                          })}
                        />
                      </MUIGrid>
                    </MUIGrid>

                    <MUIGrid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ margin: '17px' }}
                    >
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'address1',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.billToAddress1 && {
                            error: true,
                            helperText: salesOrderFormErrors.billToAddress1,
                          })}
                          id="outlined-required"
                          label="Address 1"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.address1 || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'address2',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          id="outlined"
                          label="Address 2"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.address2 || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'cityTownship',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.billToCityTownship && {
                            error: true,
                            helperText: salesOrderFormErrors.billToCityTownship,
                          })}
                          id="outlined-required"
                          label="City/Township"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.cityTownship || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                    </MUIGrid>

                    <MUIGrid
                      container
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      sx={{ margin: '17px' }}
                    >
                      <MUIGrid item xs={4}>
                        {billToCountryValue?.value === 236 ? (
                          <Autocomplete
                            id="controllable-states"
                            disableClearable
                            disabled={isFormDisable}
                            options={optBillStates}
                            value={billToStatesValue}
                            isOptionEqualToValue={(option, selected) =>
                              option.value === selected.value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id="outlined"
                                label="State/Province/Region"
                                size="small"
                                sx={{ width: '100%', background: '#ffffff' }}
                                autoComplete="off"
                              />
                            )}
                            onChange={(event: any, newValue: any) => {
                              if (newValue !== null) {
                                setBillToStatesValue(newValue);
                                setSalesOrderForm((e) => ({
                                  ...e,
                                  billTo: {
                                    ...e.billTo,
                                    stateProvinceRegion: newValue.label,
                                  },
                                }));
                              } else {
                                setBillToStatesValue(newValue);
                                setSalesOrderForm((e) => ({
                                  ...e,
                                  billTo: {
                                    ...e.billTo,
                                    stateProvinceRegion: null,
                                  },
                                }));
                              }
                            }}
                          />
                        ) : (
                          <TextField
                            inputProps={{
                              'data-state': 'Region',
                              autoComplete: autoCompleteTimeStamp().toString(),
                            }}
                            autoComplete="off"
                            disabled={isFormDisable}
                            id="outlined"
                            label="State/Province/Region"
                            size="small"
                            name="billTo"
                            value={
                              salesOrderForm?.billTo?.stateProvinceRegion || ''
                            }
                            onChange={(v) =>
                              setSalesOrderForm((e) => ({
                                ...e,
                                billTo: {
                                  ...e.billTo,
                                  stateProvinceRegion: v.target.value,
                                },
                              }))
                            }
                            sx={{
                              width: '100%',
                              backgroundColor: '#ffffff !important',
                            }}
                          />
                        )}
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <TextField
                          inputProps={{
                            'data-state': 'zipPostalCode',
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          disabled={isFormDisable}
                          required
                          {...(salesOrderFormErrors.billToZipPostalCode && {
                            error: true,
                            helperText:
                              salesOrderFormErrors.billToZipPostalCode,
                          })}
                          id="outlined-required"
                          label="ZIP/Postal Code"
                          size="small"
                          name="billTo"
                          value={salesOrderForm?.billTo?.zipPostalCode || ''}
                          onChange={salesOrderFormOnChange}
                          sx={{
                            width: '100%',
                            backgroundColor: '#ffffff !important',
                          }}
                        />
                      </MUIGrid>
                      <MUIGrid item xs={4}>
                        <Autocomplete
                          disableClearable
                          disabled={isFormDisable}
                          id="controllable-states"
                          options={optionCountries}
                          getOptionLabel={(option: CountryType) => option.label}
                          value={billToCountryValue}
                          isOptionEqualToValue={(option, selected) =>
                            option.value === selected.value
                          }
                          renderOption={(props, option) => (
                            <li
                              data-name="billTo"
                              data-state="country"
                              {...props}
                            >
                              {option.label}
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete:
                                  autoCompleteTimeStamp().toString(),
                              }}
                              autoComplete="off"
                              {...(salesOrderFormErrors.billToCountry && {
                                error: true,
                                helperText: salesOrderFormErrors.billToCountry,
                              })}
                              id="outlined-required"
                              label="Country"
                              size="small"
                              sx={{ width: '100%', background: '#ffffff' }}
                            />
                          )}
                          onChange={(event: any, newValue: CountryType) => {
                            if (newValue !== null) {
                              setBillToCountryValue(newValue);
                              setSalesOrderForm((e) => ({
                                ...e,
                                billTo: {
                                  ...e.billTo,
                                  country: newValue.value,
                                  stateProvinceRegion: null,
                                },
                              }));
                            } else {
                              setBillToCountryValue(null);
                              setSalesOrderForm((e) => ({
                                ...e,
                                billTo: {
                                  ...e.billTo,
                                  country: null,
                                  stateProvinceRegion: null,
                                },
                              }));
                            }
                            setBillToStatesValue(null);
                          }}
                        />
                      </MUIGrid>
                    </MUIGrid>
                  </Box>
                </Box>
              ),
            },
          ]}
        />
      </MUIGrid>
    </MUIGrid>
  );
};

const LineItems = () => {
  const theme = useTheme();

  const {
    onOpenOrderItemModal,
    onOpenConfirmSalesOrderLineItemModal,
    setIsReturnLineItemModalOpen,
    setIsOrderPickingUomModalOpen,
  } = useContext(GlobalContext);

  const { isWarehouseCustomerAccount, outboundSectionSettings } =
    useContext(AuthContext);

  const {
    pageViewer,
    isFormDisable,
    orderItemRows,
    salesOrderForm,
    returnOrderItemRows,
    setModalTitle,
    setOrderItemRows,
    setSalesOrderForm,
    setSingleOrderLineItemRow,
  } = useSalesOrderContext();

  const [lineItemsSummary, setLineItemsSummary] = useState({
    totalItem: 0,
    totalQty: 0,
    subTotal: 0,
    total: 0,
  });

  const deleteOrderLineItemNewMode = async (data) => {
    const datas = [...orderItemRows];
    const newValues = datas.filter((r) => r.lineId !== data.lineId);
    setOrderItemRows([...newValues]);
    setSalesOrderForm((prev) => ({
      ...prev,
      lineItems: [...newValues],
    }));
  };

  const lineItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: true,
      hide: isFormDisable,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          disabled={
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                outboundSectionSettings?.lockOrderEditsAfter) ||
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickCompleted &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickStarted) ||
            (isWarehouseCustomerAccount &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.Always)
          }
          onClick={() => {
            if (salesOrderForm?.isReturn === true) {
              setIsReturnLineItemModalOpen(true);
            } else {
              onOpenOrderItemModal();
              setModalTitle(ModalTitleStatus.Edit);
              setSingleOrderLineItemRow(params.row);
            }
          }}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Duplicate"
          disabled={
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                outboundSectionSettings?.lockOrderEditsAfter) ||
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickCompleted &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickStarted) ||
            (isWarehouseCustomerAccount &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.Always)
          }
          onClick={() => {
            onOpenConfirmSalesOrderLineItemModal();
            setSingleOrderLineItemRow({
              action: 'Duplicate',
              data: params.row,
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Cancel"
          disabled={
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                outboundSectionSettings?.lockOrderEditsAfter) ||
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickCompleted &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickStarted) ||
            (isWarehouseCustomerAccount &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.Always)
          }
          onClick={() => {
            onOpenConfirmSalesOrderLineItemModal();
            setSingleOrderLineItemRow({
              action: 'Cancel',
              data: params.row,
            });
          }}
          showInMenu
        />,
        <GridActionsCellItem
          label="Remove"
          disabled={
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                outboundSectionSettings?.lockOrderEditsAfter) ||
            (isWarehouseCustomerAccount &&
              salesOrderForm?.lockOrderEditsAfter !== null &&
              salesOrderForm?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickCompleted &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.PickStarted) ||
            (isWarehouseCustomerAccount &&
              outboundSectionSettings?.lockOrderEditsAfter ===
                OutboundSectionSettingLockOrderEditsAfterEnum.Always)
          }
          onClick={() => {
            if (pageViewer.isNew()) {
              deleteOrderLineItemNewMode(params.row);
            } else {
              onOpenConfirmSalesOrderLineItemModal();
              setSingleOrderLineItemRow({
                action: 'Delete',
                data: params.row,
              });
            }
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'lineNumber',
      width: 80,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Line No.</Typography>
        </Box>
      ),
    },
    {
      field: 'sku',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">SKU</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Link
            sx={{
              fontSize: 14,
              fontWeight: 600,
              color: '#303538',
              textDecoration: 'none',
            }}
            href={`/inventory/item-details?form=view&id=${params.row.itemId}`}
            target="_blank"
          >
            <Typography
              sx={{
                cursor: 'pointer',
                color: [theme.palette.primary.main],
              }}
              className="cellDatagridWrapper"
            >
              {params.row.sku}
            </Typography>
          </Link>
        </Box>
      ),
    },
    {
      field: 'description',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Description</Typography>
        </Box>
      ),
    },
    {
      field: 'qty',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty Ordered</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          sx={{
            width: '100%',
          }}
        >
          {params?.row?.uomType === UOMType.Primary && (
            <>
              {params.row.qty.toLocaleString()}
              <IconButton
                color="primary"
                onClick={() => {
                  setSingleOrderLineItemRow({ ...params?.row });
                  setIsOrderPickingUomModalOpen(true);
                }}
              >
                <SwapCallsIcon />
              </IconButton>
            </>
          )}

          {params?.row?.uomType === UOMType.Other &&
            `${params.row.totalCases.toLocaleString()} ${
              params?.row?.otherUOMAbbr
            } (${params.row.otherUOMCount} ${params.row.primaryUOMAbbr})`}

          {params?.row?.uomType === UOMType.Pallet &&
            params?.row?.isOrderByLPN === false &&
            `${params.row.totalCases.toLocaleString()} pallet (${
              params.row.otherUOMCount
            } ${params.row.primaryUOMAbbr})`}

          {params?.row?.uomType === UOMType.Pallet &&
            params?.row?.isOrderByLPN &&
            `1 Pallet (${params.row.lpn})`}
        </Typography>
      ),
    },
    {
      field: 'qtyShipped',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Qty Shipped</Typography>
        </Box>
      ),
    },
    {
      field: 'statusStr',
      width: 130,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'onHand',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">On Hand</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.onHand?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'available',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Available</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.available?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'lotNo',
      width: 130,
      hide: orderItemRows.every((row) => row.lotNo === ''),
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot No.</Typography>
        </Box>
      ),
    },
    {
      field: 'sellPrice',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Sell Price</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params?.row?.sellPrice !== undefined || params?.row?.sellPrice
            ? `$${Number(params.row.sellPrice).toFixed(2)}`
            : ''}
        </Typography>
      ),
    },
    {
      field: 'backOrder',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Backorder</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{params.row.backOrder?.toLocaleString()}</Typography>
      ),
    },
    {
      field: 'systemNote',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">System Note</Typography>
        </Box>
      ),
    },
    {
      field: 'lotId',
      width: 250,
      hide: true,
      headerName: 'Lot Id',
    },
    {
      field: 'lotExpiration',
      width: 250,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Lot Exp. Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.lotExpiration !== undefined ||
          params.row.lotExpiration !== null
            ? moment(params.row.lotExpiration).format('MMM DD YYYY')
            : ''}
        </Typography>
      ),
    },
  ];

  useEffect(() => {
    if (orderItemRows && orderItemRows.length) {
      setLineItemsSummary((prev) => ({
        ...prev,
        totalItem: orderItemRows.length,
        totalQty: orderItemRows.reduce(
          (total, item) => total + (item.qty ? Number(item.qty) : 0),
          0,
        ),
      }));
    }
  }, [
    orderItemRows,
    salesOrderForm.subtotalLineItems,
    salesOrderForm.totalLineItems,
  ]);

  return (
    <>
      {!pageViewer.isView() && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          <Typography
            sx={{ mr: 2, flexGrow: 1 }}
            variant="h6"
            fontWeight="bold"
            mb={1}
          />
          {!isFormDisable && (
            <Button
              disabled={
                (isWarehouseCustomerAccount &&
                  !pageViewer.isNew() &&
                  salesOrderForm?.lockOrderEditsAfter !== null &&
                  salesOrderForm?.lockOrderEditsAfter ===
                    outboundSectionSettings?.lockOrderEditsAfter) ||
                (isWarehouseCustomerAccount &&
                  !pageViewer.isNew() &&
                  salesOrderForm?.lockOrderEditsAfter !== null &&
                  salesOrderForm?.lockOrderEditsAfter ===
                    OutboundSectionSettingLockOrderEditsAfterEnum.PickCompleted &&
                  outboundSectionSettings?.lockOrderEditsAfter ===
                    OutboundSectionSettingLockOrderEditsAfterEnum.PickStarted) ||
                (isWarehouseCustomerAccount &&
                  !pageViewer.isNew() &&
                  outboundSectionSettings?.lockOrderEditsAfter ===
                    OutboundSectionSettingLockOrderEditsAfterEnum.Always) ||
                (isWarehouseCustomerAccount &&
                  salesOrderForm?.customerFacilityId === undefined) ||
                (isWarehouseCustomerAccount &&
                  salesOrderForm?.customerFacilityId === null) ||
                salesOrderForm.warehouseCustomerId === null ||
                salesOrderForm.warehouseCustomerId === undefined
              }
              variant="outlined"
              size="small"
              onClick={() => {
                if (salesOrderForm?.isReturn === true) {
                  setIsReturnLineItemModalOpen(true);
                } else {
                  onOpenOrderItemModal();
                  setModalTitle(ModalTitleStatus.Add);
                }
              }}
              sx={{
                m: 1,
                borderColor: 'rgb(5, 47, 255)',
                color: 'rgb(5, 47, 255)',
              }}
            >
              Add Line Item
            </Button>
          )}
        </Box>
      )}
      <Box
        sx={{
          width: '100%',
          '& .super-app-theme--Cancel': {
            backgroundColor: '#FFD9DB',
            '&:hover': {
              backgroundColor: '#FECACD !important',
            },
          },
          '& .super-app-theme--Cancel.MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#FECACD !important',
          },
        }}
      >
        {/* ORDER LINE ITEM DATAGRID */}
        <DatagridPremium
          autoHeight
          rows={
            salesOrderForm?.isReturn === true && pageViewer.isNew()
              ? returnOrderItemRows
              : orderItemRows
          }
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          hideFooterSelectedRowCount
          columns={lineItemsColumns}
          rowsPerPageOptions={[40]}
          pageSize={40}
          getRowId={(row) => row.lineId}
          density="compact"
        />
      </Box>
      {orderItemRows.length > 0 && pageViewer.isView() && (
        <Box>
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ sm: 1, md: 2 }}
            sx={{
              justifyContent: 'right',
              padding: '20px',
              paddingBottom: '0px',
            }}
          >
            <MUIGrid
              item
              sx={{
                width: '250px',
                maxWidth: '40%',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <MUIGrid
                container
                spacing={2}
                sx={{
                  paddingBottom: '0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Items:
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1">
                    {lineItemsSummary.totalItem}
                  </Typography>
                </div>
              </MUIGrid>
              <MUIGrid
                container
                spacing={2}
                sx={{
                  paddingBottom: '0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total Quantities :
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1">
                    {lineItemsSummary.totalQty}
                  </Typography>
                </div>
              </MUIGrid>
              <MUIGrid
                container
                spacing={2}
                sx={{
                  paddingBottom: '0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '-10px',
                }}
              >
                <div>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Subtotal:
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1">
                    {lineItemsSummary.subTotal}
                  </Typography>
                </div>
              </MUIGrid>
              <Divider sx={{ mt: '7px', mb: '7px' }} />
              <MUIGrid
                container
                spacing={2}
                sx={{
                  paddingBottom: '0px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 1,
                }}
              >
                <div>
                  <Typography variant="subtitle1" fontWeight="bold">
                    Total:
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {lineItemsSummary.total}
                  </Typography>
                </div>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </Box>
      )}
    </>
  );
};

const BillingChargeSection = () => {
  const {
    billingAdhocChargesData,
    errorBillingAdhocItemValues,
    handleChangeBillingChargeSection,
    handleRemoveBillingChargeSection,
    dataBillingChargeSectionSelected,
    isFormDisable,
  } = useSalesOrderContext();

  const { handleUserPermissionAllow } = useContext(AuthContext);

  return (
    <>
      <ConfirmationDialog
        billingChargeSectionDataOrder={dataBillingChargeSectionSelected}
        deleteBillingChargeSectionOrder
      />
      <Box
        sx={{
          width: '100%',
          paddingTop: '20px',
        }}
      >
        {billingAdhocChargesData.map((model, index) => (
          <>
            <MUIGrid
              key={index}
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              sx={{ flex: 1 }}
            >
              <MUIGrid item xs={0.4} xl={0.2} alignSelf="center">
                <Typography variant="subtitle1" fontWeight="bold" align="right">
                  {' '}
                </Typography>
              </MUIGrid>
              <MUIGrid item xs={3} xl={2} alignSelf="center">
                <Typography variant="subtitle1" fontWeight="bold">
                  {model.chargeName}
                </Typography>
              </MUIGrid>
              {handleUserPermissionAllow(
                UserPermissionEnum.Client_Billing_View_Charge_Rate,
              ) && (
                <MUIGrid item xs={2} xl={1.5} alignSelf="center">
                  <TextField
                    id="outlined"
                    name="rate"
                    value={model.rate}
                    onChange={(e) => {
                      handleChangeBillingChargeSection(e, index);
                    }}
                    label="Rate"
                    size="small"
                    sx={{ width: '100%' }}
                    error={errorBillingAdhocItemValues[index]?.rate}
                    helperText={errorBillingAdhocItemValues[index]?.rate}
                    disabled={isFormDisable}
                    required
                  />
                </MUIGrid>
              )}
              <MUIGrid item xs={1.5} xl={1} alignSelf="center">
                <TextField
                  id="outlined"
                  name="qty"
                  value={model.qty}
                  onChange={(e) => {
                    handleChangeBillingChargeSection(e, index);
                  }}
                  label="Qty"
                  size="small"
                  sx={{ width: '100%', letterSpacing: '0' }}
                  error={errorBillingAdhocItemValues[index]?.qty}
                  helperText={errorBillingAdhocItemValues[index]?.qty}
                  disabled={isFormDisable}
                  required
                />
              </MUIGrid>

              {handleUserPermissionAllow(
                UserPermissionEnum.Client_Billing_View_Charge_Rate,
              ) && (
                <>
                  <MUIGrid item xs={1} xl={0.5} alignSelf="center">
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      align="right"
                    >
                      Total:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={1} alignSelf="center">
                    <Typography variant="subtitle1">{model.total}</Typography>
                  </MUIGrid>
                </>
              )}
              <MUIGrid item xs={2} xl={1.5}>
                <TextField
                  rows={1}
                  multiline
                  id="outlined-multiline-flexible"
                  name="notes"
                  value={model.notes}
                  onChange={(e) => {
                    handleChangeBillingChargeSection(e, index);
                  }}
                  label="Notes"
                  size="small"
                  disabled={isFormDisable}
                />
              </MUIGrid>
              <MUIGrid item xs={1} xl={0.5} alignSelf="center">
                <IconButton
                  sx={{
                    background: '#F8F9FA',
                    borderRadius: 1,
                    width: 30,
                    height: 30,
                  }}
                  onClick={() => handleRemoveBillingChargeSection(model)}
                  color="primary"
                  aria-label="Minus Quantity"
                  component="label"
                  disabled={isFormDisable}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </MUIGrid>
            </MUIGrid>
            <Divider sx={{ mt: 1, mb: 1 }} />
          </>
        ))}
      </Box>
    </>
  );
};

const PickTickets = () => {
  const { handleUserPermissionAllow } = useContext(AuthContext);

  const {
    pageViewer,
    formTemplateType,
    setOperatorValue,
    setOptionOperator,
    setIsPickTicketEdit,
    orderPickTicketItemRows,
    setOrderPickTicketItemModalRows,
    setSingleOrderPickTicketItemRow,
    setButtonDisable,
    setFormTemplateType,
  } = useSalesOrderContext();

  const {
    onOpenPickTicketSalesOrderModal,
    onOpenPrintFormTemplateModal,
    onOpenConfirmSalesOrderPickTicketModal,
  } = useContext(GlobalContext);

  const [pickTicketIdPrint, setPickTicketIdPrint] = useState(0);

  const editPickTicketItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: number): Promise<void> => {
      e.preventDefault();

      const response = getPickTicketDetail(id);
      response.then((value) => {
        setOptionOperator(
          value.assignedByUsers.map((c: any) => ({
            label: c.text,
            value: c.value,
          })),
        );

        if (
          value.assignedByUser === null ||
          value.assignedByUser === undefined
        ) {
          setOperatorValue(null);
        } else {
          setOperatorValue((prevState) => ({
            ...prevState,
            label: value.assignedByUser.text,
            value: value.assignedByUser.value,
          }));
        }

        setOrderPickTicketItemModalRows(
          value.lineItems.map((c: any) => ({
            orderLineItemId: c.pickTicketLineItemId,
            pickTicketId: id,
            sku: c.sku,
            description: c.description,
            primaryUOM: c.primaryUOM,
            primaryUOMAbbr: c.primaryUOMAbbr,
            itemUnitOfMeasureId: c.itemUnitOfMeasureId,
            otherUOMStr: c.otherUOMStr,
            otherUOMAbbr: c.otherUOMAbbr,
            otherUOMCount: c.otherUOMCount,
            uomType: c.uomType,
            lotId: c.lotId,
            lotNo: c.lotNo,
            quantity: c.quantity,
            totalCases: c.totalCases,
          })),
        );

        setButtonDisable(false);
        setIsPickTicketEdit(true);
        onOpenPickTicketSalesOrderModal();
      });
    },
    [],
  );

  const printPickTicketItemOnClick = useCallback(
    async (e: React.SyntheticEvent, id: any): Promise<void> => {
      e.preventDefault();
      setPickTicketIdPrint(id);
      onOpenPrintFormTemplateModal();
      setFormTemplateType(null);
    },
    [pickTicketIdPrint],
  );

  const pickTicketItemsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: true,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Orders_Edit),
      // hide: isFormDisable,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={(e) => editPickTicketItemOnClick(e, params.row.pickTicketId)}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Print"
          onClick={(e) => printPickTicketItemOnClick(e, params.id)}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Delete"
          onClick={() => {
            onOpenConfirmSalesOrderPickTicketModal();
            setSingleOrderPickTicketItemRow(params.row);
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'pickTicketId',
      width: 100,
      hide: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Pick Ticket Id</Typography>
        </Box>
      ),
    },
    {
      field: 'pickId',
      width: 150,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Pick ID</Typography>
        </Box>
      ),
    },
    {
      field: 'statusStr',
      width: 100,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Status</Typography>
        </Box>
      ),
    },
    {
      field: 'print',
      width: 70,
      headerName: 'Print',
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={(e) => {
            printPickTicketItemOnClick(e, params.id);
          }}
        >
          <PrintIcon />
        </IconButton>
      ),
    },
    {
      field: 'issues',
      width: 150,
      headerName: 'Issue',
    },
  ];

  if (orderPickTicketItemRows.length === 0) return null;

  return (
    <>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DatagridPremium
          autoHeight
          rows={orderPickTicketItemRows}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          disableSelectionOnClick
          hideFooter
          columns={pickTicketItemsColumns}
          rowsPerPageOptions={[25]}
          pageSize={25}
          getRowId={(row) => row.pickTicketId}
          density="compact"
        />
      </Box>
      {pickTicketIdPrint !== 0 && formTemplateType === null && (
        <PrintFormTemplate
          preview={false}
          dynamicId={pickTicketIdPrint}
          formtemplateTypeId={FormTemplateTypeEnum.PickTicket}
        />
      )}
    </>
  );
};

const Packages = () => {
  const theme = useTheme();

  const {
    setIsOrderPackageLineItemModalOpen,
    setIsLabelToPrintModalOpen,
    setLabelToPrintDataUrl,
  } = useContext(GlobalContext);

  const { salesOrderForm, setOrderPackageLineItems, setOrderPackageName } =
    useSalesOrderContext();

  const printLabelRef = useRef(null);
  const buttonPrintLabelRef = useRef(null);
  const [printLabelData, setPrintLabelData] =
    useState<LabelUrlPrintModel>(null);
  const [loading, setLoading] = useState(false);

  const printLabelClickTriggerHandler = useReactToPrint({
    content: () => printLabelRef.current,
  });

  const handlePrintLabel = async (labelUrl: string) => {
    setLoading(true);
    if (labelUrl.substring(0, 4) === 'http') {
      setLabelToPrintDataUrl(labelUrl);
      setIsLabelToPrintModalOpen(true);
      setLoading(false);
    } else {
      const byteCharacters = atob(labelUrl);
      const byteNumbers = new Array(byteCharacters.length);
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      const iframe = document.createElement('iframe'); // load content in an iframe to print later
      document.body.appendChild(iframe);
      iframe.style.display = 'none';
      iframe.src = fileURL;
      iframe.onload = function () {
        setTimeout(() => {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
    }
  };

  const orderPackagesColumns = [
    {
      field: 'labelUrl',
      minWidth: 60,
      flex: 1,
      headerName: 'Label',
      renderCell: (params: any) =>
        params.row.labelUrl && (
          <IconButton
            aria-label="Print Label"
            title="Print Label"
            onClick={() => handlePrintLabel(params.row.labelUrl)}
            disabled={loading}
            style={{ color: '#3375e0' }}
          >
            <PrintIcon />
          </IconButton>
        ),
    },
    {
      field: 'packageCount',
      minWidth: 150,
      flex: 1,
      headerName: 'Package',
    },

    {
      field: 'length',
      minWidth: 150,
      flex: 1,
      headerName: 'L(in)',
    },
    {
      field: 'width',
      minWidth: 150,
      flex: 1,
      headerName: 'W(in)',
    },
    {
      field: 'height',
      minWidth: 150,
      flex: 1,
      headerName: 'H(in)',
    },
    {
      field: 'weight',
      minWidth: 150,
      flex: 1,
      headerName: 'W(lbs)',
    },
    {
      field: 'totalShipmentCost',
      minWidth: 150,
      flex: 1,
      headerName: 'Shipment Cost',
      renderCell: (params) => (
        <Typography>
          $
          {params?.row?.totalShipmentCost
            ? numberWithCommas(params.row.totalShipmentCost.toFixed(2))
            : `0.00`}
        </Typography>
      ),
    },
    {
      field: 'packageTypeName',
      minWidth: 150,
      flex: 1,
      headerName: 'Package Type',
    },
    {
      field: 'tracking',
      minWidth: 150,
      flex: 1,
      headerName: 'Tracking',
      renderCell: (params) => (
        <Link
          sx={{
            fontSize: 14,
            fontWeight: 600,
            color: '#303538',
            textDecoration: 'none',
          }}
          href={params.row.trackingUrl}
          target="_blank"
          underline="none"
        >
          <Typography
            sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
          >
            {params.row.tracking}
          </Typography>
        </Link>
      ),
    },
    {
      field: 'contents',
      minWidth: 150,
      flex: 1,
      headerName: 'Contents',
      renderCell: (params) => (
        <Button
          variant="contained"
          size="small"
          sx={{ padding: '0px' }}
          onClick={() => {
            setOrderPackageLineItems(params.row.orderPackagesLineItems);
            setOrderPackageName(params.row.packageCount);
            setIsOrderPackageLineItemModalOpen(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <DatagridStandard
        autoHeight
        rows={salesOrderForm.orderPackages}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        columns={orderPackagesColumns}
        rowsPerPageOptions={[25]}
        pageSize={25}
        getRowId={(row) => row.packageId}
      />
      <div style={{ display: 'none' }}>
        <LabelUrlPrint ref={printLabelRef} data={printLabelData} />
        <button
          type="button"
          ref={buttonPrintLabelRef}
          onClick={printLabelClickTriggerHandler}
        >
          This one is trigger on print
        </button>
      </div>
      <ModalToPrint notRedirect />
    </Box>
  );
};

const Pallets = () => {
  const { salesOrderForm } = useSalesOrderContext();

  const orderPalletsColumns = [
    {
      field: 'palletNo',
      minWidth: 150,
      flex: 1,
      headerName: 'Pallets',
    },
    {
      field: 'palletLength',
      minWidth: 150,
      flex: 1,
      headerName: 'L(in)',
    },
    {
      field: 'palletWidth',
      minWidth: 150,
      flex: 1,
      headerName: 'W(in)',
    },
    {
      field: 'palletHeight',
      minWidth: 150,
      flex: 1,
      headerName: 'H(in)',
    },
    {
      field: 'palletWeight',
      minWidth: 150,
      flex: 1,
      headerName: 'W(lbs)',
    },
    {
      field: 'palletCubicDimension',
      minWidth: 150,
      flex: 1,
      headerName: 'Cubic(FT³)',
      renderCell: (params) => (
        <Typography>{params.row.palletCubicDimension.toFixed(2)}</Typography>
      ),
    },
    {
      field: 'boxes',
      minWidth: 150,
      flex: 1,
      headerName: 'Boxes',
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <DatagridStandard
        autoHeight
        rows={salesOrderForm.orderPallets}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableColumnMenu
        disableSelectionOnClick
        hideFooter
        columns={orderPalletsColumns}
        rowsPerPageOptions={[25]}
        pageSize={25}
        getRowId={(row) => row.palletId}
      />
    </Box>
  );
};

const ShippingLabels = () => {
  const { salesOrderForm } = useSalesOrderContext();

  const [labelRows, setLabelRows] = useState([]);
  useEffect(() => {
    if (salesOrderForm.orderId) {
      const response = getShipmentLabels(salesOrderForm.orderId);

      response.then((rows) => {
        setLabelRows(rows);
      });
    }
  }, [salesOrderForm.orderId]);

  const fixShipmentIssues = async (pickTicketId) => {
    const payload = {
      pickTicketId,
      customerLocationId: salesOrderForm.customerLocationId,
      customerFacilityId: salesOrderForm.customerFacilityId,
    };
    try {
      const labelResponse = await updatePackageToShipAndComplete(payload);
    } catch (err) {
      snackActions.error(err);
    }
    const response = getShipmentLabels(salesOrderForm.orderId);

    response.then((rows) => {
      setLabelRows(rows);
    });
  };

  const formatTrackingUrl = (integrationTypeStr, url) => {
    if (url === undefined) {
      return '';
    }

    if (
      integrationTypeStr === undefined ||
      integrationTypeStr === null ||
      integrationTypeStr === ''
    ) {
      return '';
    }

    if (integrationTypeStr === 'Shippo') {
      const lowerCaseUrl = url.toLowerCase();
      let trackingNo = '';
      if (lowerCaseUrl.indexOf('tracknum=') > 0) {
        // USPS
        trackingNo = lowerCaseUrl
          .substr(lowerCaseUrl.indexOf('tracknum='))
          .replace('tracknum=', '');
      } else {
        trackingNo = lowerCaseUrl
          .substr(lowerCaseUrl.indexOf('tracknums='))
          .replace('tracknums=', '');
      }

      return trackingNo.toUpperCase();
    }
    if (integrationTypeStr === 'EasyPost') {
      const str = url;
      const index = str.lastIndexOf('/');
      return url.substring(index + 1, url.lenght);
    }

    return url;
  };

  const formatShippingLabelUrl = (integrationTypeStr, url) => {
    if (
      integrationTypeStr === undefined ||
      integrationTypeStr === null ||
      integrationTypeStr === ''
    ) {
      return '';
    }

    if (integrationTypeStr === 'Shippo') {
      return url
        ? url.substr(url.indexOf('Key-Pair-Id=')).replace('Key-Pair-Id=', '')
        : '';
    }
    if (integrationTypeStr === 'EasyPost' && url !== undefined) {
      const str = url;
      const index = str.lastIndexOf('postage_label/');
      const index2 = str.lastIndexOf('/');
      return url.substring(index + 14, index2);
    }

    return url;
  };

  const printAllShippingLabels = async () => {
    printShippingLabelByOrderId(salesOrderForm.orderId);
  };

  const shippingLabelsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      hideable: true,
      hide: false,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => {
        const actions = [
          <GridActionsCellItem
            label="Print"
            onClick={() => {
              window.open(params.row.labelUrl, '_blank');
            }}
            showInMenu
          />,
        ];

        if (params.row.messages !== '') {
          actions.push(
            <GridActionsCellItem
              label="Create Shipping Label"
              onClick={() => fixShipmentIssues(params.row.pickTicketId)}
              showInMenu
            />,
          );
        }
        return actions;
      },
    },
    {
      field: 'creationDate',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Creation Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {moment(params.row.creationDate).format('MM/DD/YY')}
        </Typography>
      ),
    },
    {
      field: 'labelUrl',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Shipping Label ID</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          <a href={params.row.labelUrl} target="_blank" rel="noreferrer">
            {formatShippingLabelUrl(
              params.row.integrationTypeStr,
              params.row.labelUrl,
            )}
          </a>
        </Typography>
      ),
    },
    {
      field: 'trackingUrl',
      minWidth: 150,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Tracking No</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          <a href={params.row.trackingUrl} target="_blank" rel="noreferrer">
            {formatTrackingUrl(
              params.row.integrationTypeStr,
              params.row.trackingUrl,
            )}
          </a>
        </Typography>
      ),
    },
    {
      field: 'shipmentCost',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Cost</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{USDollar.format(params.row.shipmentCost)}</Typography>
      ),
    },
    {
      field: 'messages',
      minWidth: 450,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Messages</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <ul className="flex">
          {params.row.messages.split('..').map((row) => (
            <li key={Math.random()}>{row}</li>
          ))}
        </ul>
      ),
      type: 'string',
    },
    {
      field: 'print',
      width: 70,
      headerName: 'Print',
      renderCell: (params) => (
        <IconButton
          aria-label="print"
          color="primary"
          onClick={() => {
            window.open(params.row.labelUrl, '_blank');
          }}
        >
          <PrintIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ marginBottom: 1, display: 'flex', justifyContent: 'right' }}>
        <IconButton
          aria-label="print"
          color="primary"
          onClick={() => {
            printAllShippingLabels();
          }}
        >
          <PrintIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <DatagridPremium
          autoHeight
          rows={labelRows}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          hideFooter
          columns={shippingLabelsColumns}
          getRowId={(row) => row.packageId}
          sx={{
            borderColor: 'transparent',
            '& .MuiDataGrid-columnSeparator--sideRight': {
              display: 'none !important',
            },
            [`& .${gridClasses.cell}`]: {
              maxHeight: 'none !important;',
              height: 'auto !important;',
              wrapText: true,
            },
            [`& .${gridClasses.row}`]: {
              maxHeight: 'none !important;',
              height: 'auto !important;',
              wrapText: true,
            },
            [`& .${gridClasses}`]: {
              maxHeight: 'none !important;',
              height: '100% !important;',
              wrapText: true,
            },
          }}
          density="compact"
        />
      </Box>
    </>
  );
};

// --------------------------- new set up ----------------------------------------
const FirstRow = () => {
  const theme = useTheme();

  const [valueSecond, setValueSecond] = React.useState('1');
  const handleChangeSecond = (
    event: React.SyntheticEvent,
    newValue: string,
  ) => {
    setValueSecond(newValue);
  };

  const [valueThird, setvalueThird] = React.useState('1');
  const handleChangeThird = (event: React.SyntheticEvent, newValue: string) => {
    setvalueThird(newValue);
  };

  const { isAdmin, handleUserPermissionAllow } = useContext(AuthContext);
  const { updateLoading } = useContext(GlobalContext);

  const {
    isFormDisable,
    pageViewer,
    currentUser,
    optionCustomers,
    optionFacilities,
    salesOrderForm,
    salesOrderFormErrors,
    setSalesOrderForm,
    salesOrderFormOnChange,
    autoCompleteOnChange,
    setIsOnHoldValue,
    setOptionSkus,
    isOnHoldValue,
    loadBillingChargesDetailByModule,
    onLoadSkuLookupWithwarehouseCustomer,
    setCarrierValue,
    optionCountries,
    optionOrderBillings,
    optionWarehouseCustomerShippingAccounts,
    optionCarriers,
    carrierValue,
    packageCartonCodeValue,
    setPackageCartonCodeValue,
    carrierServiceValue,
    setOrderNotesModalOpen,
    orderNotes,
    loadOrderNotes,
    salesOrderFlagStatus,
    setChecklistModal,
    viewLoadData,
  } = useSalesOrderContext();

  const [customerValue, setCustomerValue] = useState<CustomerType>(null);
  const [facilityValue, setFacilityValue] = useState<FacilityType>(null);
  // ship to and bill to ------------------------------------
  const [tabValue, setTabValue] = React.useState<string>('1');
  const [useShipToForBillToChecked, setUseShipToForBillToChecked] =
    React.useState<boolean>(false);
  const [shipToCountryValue, setShipToCountryValue] =
    useState<CountryType>(null);
  const [billToCountryValue, setBillToCountryValue] =
    useState<CountryType>(null);
  const [optionShipToSearchPrevAddresses, setOptionShipToSearchPrevAddresses] =
    useState<ShipToSearchPrevAddressType[] | null | []>([]);
  const [optionBillToSearchPrevAddresses, setOptionBillToSearchPrevAddresses] =
    useState<BillToSearchPrevAddressType[] | null | []>([]);
  const [billToSearchPrevAddresseValue, setBillToSearchPrevAddresseValue] =
    useState<CountryType>(null);
  const [shipToSearchPrevAddresseValue, setShipToSearchPrevAddresseValue] =
    useState<CountryType>(null);
  const [optShipStates, setOptShipStates] = useState<IAutoComplete[]>([]);
  const [optBillStates, setOptBillStates] = useState<IAutoComplete[]>([]);
  const [shipToStatesValue, setShipToStatesValue] =
    useState<IAutoComplete>(null);
  const [billToStatesValue, setBillToStatesValue] =
    useState<IAutoComplete>(null);
  // carrier ----------------------------------------------
  const [orderBillValue, setOrderBillValue] = useState<OrderBillingType>(null);
  const [shippingAccountValue, setShippingAccountValue] =
    useState<OrderBillingType>(null);

  const onLoadGenerateNo = async () => {
    try {
      const generateOrderNoFromApi = await getGenerateOrderNo();

      return generateOrderNoFromApi;
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    if (optionCustomers !== null) {
      const customer = (el: CustomerType) => {
        if (pageViewer.isNew())
          return optionCustomers.length === 1
            ? el.label === currentUser.Claim_CustomerName
            : null;
        if (pageViewer.isViewOrEdit())
          return el.value === salesOrderForm.warehouseCustomerId;
        return null;
      };

      const vmCustomer = optionCustomers.find(customer);

      if (vmCustomer !== undefined) {
        setCustomerValue(vmCustomer);
        setSalesOrderForm((prev) => ({
          ...prev,
          warehouseCustomerId: vmCustomer.value,
        }));
      }

      if (optionCustomers.length === 1) {
        optionCustomers.forEach((o) => {
          setCustomerValue((e) => ({
            ...e,
            value: o.value,
            label: o.label,
          }));
          setSalesOrderForm((prev) => ({
            ...prev,
            warehouseCustomerId: o.value,
          }));
        });
      }
    }

    if (optionFacilities !== null) {
      const facility = (el: FacilityType) => {
        if (pageViewer.isNew()) return null;
        if (pageViewer.isViewOrEdit())
          return el.value === salesOrderForm.customerFacilityId;
        return null;
      };
      const vmFacility = optionFacilities.find(facility);
      if (vmFacility !== undefined) {
        setFacilityValue(vmFacility);
        setSalesOrderForm((prev) => ({
          ...prev,
          customerFacilityId: vmFacility.value,
        }));
      }
    }

    if (pageViewer.isNew()) {
      const response = onLoadGenerateNo();

      response
        .then((value) =>
          setSalesOrderForm((prev) => ({
            ...prev,
            orderNo: value,
          })),
        )
        .catch();
    }

    if (salesOrderForm.warehouseCustomerId !== undefined) {
      const optSkus = onLoadSkuLookupWithwarehouseCustomer(
        salesOrderForm.warehouseCustomerId,
      );
      optSkus
        .then((opt) => {
          setOptionSkus(
            opt.map((c: any) => ({
              label: c.sku,
              value: c.itemId,
              description: c.description,
            })),
          );
        })
        .catch();
    }
  }, [
    optionCustomers,
    optionFacilities,
    isOnHoldValue,
    salesOrderForm.warehouseCustomerId,
    salesOrderForm.customerFacilityId,
  ]);

  const onLoadPrevAddressesLookup = async (contact) => {
    try {
      return await getPrevAddressesLookup(
        salesOrderForm.customerFacilityId,
        salesOrderForm.warehouseCustomerId,
        contact,
      );
    } catch (err) {
      return err;
    }
  };

  const onLoadContactDetail = async (contactDetailId) => {
    try {
      return await getOrderContactDetail(contactDetailId);
    } catch (err) {
      return err;
    }
  };

  const fetchDropdownStates = async (country: number) => {
    const res: IAutoComplete[] = [];
    if (country !== 236) {
      return res;
    }
    try {
      const data = await getUsStatesList();
      const opt = data.map((r) => ({ label: r.name, value: r.code }));
      return opt;
    } catch {
      return res;
    }
  };

  useEffect(() => {
    if (
      salesOrderForm.warehouseCustomerId !== undefined &&
      salesOrderForm.customerFacilityId
    ) {
      const contactType = (callback) => callback();

      const optionPrevAddress = onLoadPrevAddressesLookup(
        contactType(() => {
          let type = '';

          if (tabValue === '1') {
            type = 'shipTo';
          }
          if (tabValue === '2') {
            if (useShipToForBillToChecked) {
              type = 'shipTo';
            } else {
              type = 'billTo';
            }
          }
          return type;
        }),
      );

      optionPrevAddress
        .then((opt) => {
          if (tabValue === '1') {
            setOptionShipToSearchPrevAddresses(
              opt.map((c: any) => ({
                label: c.name,
                value: c.orderContactDetailId,
              })),
            );
          }

          if (tabValue === '2') {
            setBillToSearchPrevAddresseValue(null);
            setOptionBillToSearchPrevAddresses(
              opt.map((c: any) => ({
                label: c.name,
                value: c.orderContactDetailId,
              })),
            );
          } else if (useShipToForBillToChecked) {
            setBillToSearchPrevAddresseValue(null);
            setOptionShipToSearchPrevAddresses(
              opt.map((c: any) => ({
                label: c.name,
                value: c.orderContactDetailId,
              })),
            );
          }
        })
        .catch();
    } else {
      setOptionShipToSearchPrevAddresses([]);
      setOptionBillToSearchPrevAddresses([]);
    }
  }, [
    tabValue,
    useShipToForBillToChecked,
    salesOrderForm.warehouseCustomerId,
    salesOrderForm.customerFacilityId,
  ]);

  useEffect(() => {
    if (tabValue === '1') {
      if (shipToSearchPrevAddresseValue !== null) {
        onLoadContactDetail(shipToSearchPrevAddresseValue?.value)
          .then((x: IContactDetailType) => {
            setSalesOrderForm((prev) => ({
              ...prev,
              shipTo: {
                ...prev.shipTo,
                orderContactDetailId: x.orderContactDetailId,
                contactDetailType: 1,
                codeId: x.codeId,
                recipientName: x.recipientName,
                companyName: x.companyName,
                phone: x.phone,
                email: x.email,
                address1: x.address1,
                address2: x.address2,
                country: x.country,
                stateProvinceRegion: x.stateProvinceRegion,
                cityTownship: x.cityTownship,
                zipPostalCode: x.zipPostalCode,
                retailerId: x.retailerId,
                departmentNumber: x.departmentNumber,
              },
            }));

            setShipToCountryValue(
              optionCountries.find((o) => o?.value === x.country),
            );

            if (x.country === 236) {
              setShipToStatesValue(
                optShipStates.find((o) => o.value === x.stateProvinceRegion),
              );
            }
          })
          .catch();
      }
    }
    if (tabValue === '2') {
      if (billToSearchPrevAddresseValue !== null) {
        onLoadContactDetail(billToSearchPrevAddresseValue?.value)
          .then((x: IContactDetailType) => {
            setSalesOrderForm((prev) => ({
              ...prev,
              billTo: {
                ...prev.billTo,
                orderContactDetailId: x.orderContactDetailId,
                contactDetailType: 2,
                codeId: x.codeId,
                recipientName: x.recipientName,
                companyName: x.companyName,
                phone: x.phone,
                email: x.email,
                address1: x.address1,
                address2: x.address2,
                country: x.country,
                stateProvinceRegion: x.stateProvinceRegion,
                cityTownship: x.cityTownship,
                zipPostalCode: x.zipPostalCode,
                retailerId: x.retailerId,
                departmentNumber: x.departmentNumber,
              },
            }));

            setBillToCountryValue(
              optionCountries.find(
                (o) => o?.value === billToSearchPrevAddresseValue?.value,
              ),
            );

            if (x.country === 236) {
              setBillToStatesValue(
                optBillStates.find((o) => o.value === x.stateProvinceRegion),
              );
            }
          })
          .catch();
      }
    }
  }, [shipToSearchPrevAddresseValue, billToSearchPrevAddresseValue]);

  useEffect(() => {
    if (shipToCountryValue?.value === 236) {
      fetchDropdownStates(shipToCountryValue.value).then((result) =>
        setOptShipStates(result),
      );
    }
    if (billToCountryValue?.value === 236) {
      fetchDropdownStates(billToCountryValue.value).then((result) =>
        setOptBillStates(result),
      );
    }
  }, [shipToCountryValue, billToCountryValue]);

  useEffect(() => {
    if (optionOrderBillings !== null) {
      const billing = (el: CountryType) => {
        if (pageViewer.isNew()) return null;
        if (pageViewer.isViewOrEdit())
          return el.value === salesOrderForm?.billingType;
        return null;
      };

      const vmBilling = optionOrderBillings.find(billing);

      if (vmBilling !== undefined) {
        setOrderBillValue(vmBilling);
      }
    }
  }, [optionOrderBillings]);

  useEffect(() => {
    if (
      optionCarriers !== null &&
      salesOrderForm !== null &&
      carrierValue === null
    ) {
      if (
        salesOrderForm?.carrierId !== undefined &&
        salesOrderForm?.carrierId !== null
      ) {
        optionCarriers.forEach((row) => {
          if (
            (row.value === salesOrderForm?.carrierId &&
              row.isShippingRate === salesOrderForm?.isShippingRate) ??
            false
          ) {
            setCarrierValue(row);
          }
        });
      }

      if (!salesOrderForm?.carrierId && salesOrderForm?.carrier) {
        const getCarrier = optionCarriers.find(
          (x) => x.label === salesOrderForm?.carrier,
        );

        setCarrierValue(getCarrier);
      }
    }
  }, [optionCarriers, salesOrderForm, carrierValue]);

  const handleGetOrderDocumentFile = async (orderDocumentId) => {
    openPage(`/order/tokenized/documents/file/${orderDocumentId}`);
  };

  const { onOpenConfirmationModal } = useContext(GlobalContext);

  const [fileToBeDeleted, setFileToBeDeleted] = useState(null);
  const [noteToBeDeleted, setNoteToBeDeleted] = useState(null);
  const [documentsRows, setDocumentsRows] = useState([]);
  const [checklistToBeDeleted, setChecklistToBeDeleted] =
    useState<ChecklistModalModel>(null);

  const loadDocuments = (id?: number) => {
    getOrderDocuments(id ?? salesOrderForm.orderId).then((res) => {
      setDocumentsRows(res);
    });
  };

  const [confirmationModalText, setConfirmationModalText] = useState('');

  const handleDeleteConfirmation = async () => {
    if (fileToBeDeleted !== null) {
      await deleteOrderDocument(fileToBeDeleted);
      loadDocuments();
    } else if (noteToBeDeleted !== null) {
      await deleteOrderNote(noteToBeDeleted);
      loadOrderNotes();
    } else if (checklistToBeDeleted !== null) {
      updateLoading(true);

      const PAYLOAD: OrderChecklistEditModel = {
        orderChecklistId: checklistToBeDeleted.orderChecklistId,
      };

      deleteOrderChecklist(PAYLOAD)
        .then(() => {
          viewLoadData();
          setChecklistToBeDeleted(null);
        })
        .catch((err) => {
          snackActions.error(err);
        })
        .finally(() => {
          updateLoading(false);
        });
    }
  };

  const documentsColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Remove"
          onClick={() => {
            setConfirmationModalText(
              'Are you sure that you want to remove this file?',
            );
            setFileToBeDeleted(params.row.orderDocumentId);
            setNoteToBeDeleted(null);
            onOpenConfirmationModal();
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'fileName',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">File Name</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography
          onClick={() => handleGetOrderDocumentFile(params.row.orderDocumentId)}
          sx={{ textDecoration: 'underline', color: '#052fff' }}
        >
          {params.row.fileName}
        </Typography>
      ),
    },
    {
      field: 'fileSize',
      minWidth: 130,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Size</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {(params.row.fileSize / 1024)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
          KB
        </Typography>
      ),
    },
    {
      field: 'createdByUser',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Uploaded by</Typography>
        </Box>
      ),
    },
  ];

  type AutocompleteBase = {
    label?: string;
    value?: string;
  };

  const categoryOptions: AutocompleteBase[] = [
    {
      label: 'Order Notes',
      value: 'Order',
    },
    {
      label: 'Picking Instructions',
      value: 'Picking',
    },
    {
      label: 'Packing Instructions',
      value: 'Packing',
    },
    {
      label: 'Carrier Notes',
      value: 'Carrier',
    },
  ];

  const notesColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 14,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Orders_EditNotes),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Remove"
          onClick={() => {
            setFileToBeDeleted(null);
            setConfirmationModalText(
              'Are you sure that you want to remove this note?',
            );
            setNoteToBeDeleted(params.row.orderNoteId);
            onOpenConfirmationModal();
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'note',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Note</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Tooltip title={params.row.note}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {params.row.note}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'category',
      minWidth: 100,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Category</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {
            categoryOptions.filter((x) => x.value === params.row.category)[0]
              .label
          }
        </Typography>
      ),
    },
    {
      field: 'createdByUser',
      minWidth: 80,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Created by</Typography>
        </Box>
      ),
    },
    {
      field: 'createdDate',
      minWidth: 80,
      flex: 1,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold">Created Date</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>
          {moment(params.row.createdDate).format('MM/DD/YYYY')}
        </Typography>
      ),
    },
  ];

  const checklistColumns: GridColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 14,
      hide: !handleUserPermissionAllow(UserPermissionEnum.Orders_EditChecklist),
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams<OrderChecklistModel>) => [
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Edit"
          onClick={() => {
            setChecklistModal((prevState) => ({
              ...prevState,
              orderChecklistId: params.row.orderChecklistId,
              name: params.row.name,
              nameError: '',
            }));
          }}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ ...(pageViewer.isNew() && { display: 'none' }) }}
          label="Remove"
          onClick={() => {
            // setFileToBeDeleted(null);
            setConfirmationModalText(
              'Are you sure that you want to remove this checklist?',
            );
            setChecklistToBeDeleted(params.row);
            onOpenConfirmationModal();
          }}
          showInMenu
        />,
      ],
    },
    {
      field: 'name',
      minWidth: 190,
      flex: 2,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography fontWeight="bold" />
        </Box>
      ),
      renderCell: (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        params: GridRenderCellParams<any, OrderChecklistModel>,
      ) => (
        <Tooltip title={params.row.name}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {params.row.name}
          </Typography>
        </Tooltip>
      ),
    },
  ];

  const fileUploadRef = useRef<HTMLInputElement>(null);
  const uploadFile = (event) => {
    try {
      uploadOrderDocument(salesOrderForm.orderId, event.target.files[0])
        .then((result) => {
          snackActions.success('File uploaded.');
          loadDocuments();
        })
        .catch((err) => snackActions.error(err));
    } catch (err) {
      snackActions.error(err);
    }
  };

  const [noteCount, setNoteCount] = useState(0);
  const [docCount, setDocCount] = useState(0);

  useEffect(() => {
    if (salesOrderForm.orderId) {
      loadOrderNotes(salesOrderForm.orderId);
      loadDocuments(salesOrderForm.orderId);
    }
  }, [salesOrderForm.orderId, valueThird]);

  useEffect(() => {
    setNoteCount(orderNotes.length ?? 0);
    setDocCount(documentsRows.length ?? 0);
  }, [orderNotes, documentsRows]);

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={5}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '15px !important',
            height: '250px',
            marginBottom: 2,
            overflow: 'auto',
            borderRadius: 2,
            border: '2px solid #cdcdcd',
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Order Details
          </Typography>
          <br />
          <MUIGrid
            container
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6} sx={{ borderRight: '2px solid #d8d8d8' }}>
              <MUIGrid
                container
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={5}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Client:
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={7}>
                  <Typography variant="subtitle2">
                    {customerValue?.label}
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={5}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Facility:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={7}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2">
                    {facilityValue?.label}
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={5}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    {salesOrderForm?.isReturn === true
                      ? 'RMA#:'
                      : ' Order No.:'}
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={7}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm?.orderNo}
                  </Typography>
                </MUIGrid>

                <MUIGrid
                  item
                  xs={5}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Reference No.:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={7}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm?.referenceNo}
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={5}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2" fontWeight="bold">
                    Order Rule:
                  </Typography>
                </MUIGrid>
                <MUIGrid
                  item
                  xs={7}
                  sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                >
                  <Typography variant="subtitle2">
                    {salesOrderForm.orderSource
                      ? salesOrderForm.orderSource
                      : '--'}
                  </Typography>
                </MUIGrid>
                {salesOrderForm.integrationChannel
                  ?.substring(0, 6)
                  .toLowerCase() === 'amazon' && (
                  <>
                    <MUIGrid
                      item
                      xs={6}
                      sx={{
                        paddingLeft: '10px',
                        paddingTop: '0px !important',
                      }}
                    >
                      <Typography variant="subtitle2" fontWeight="bold">
                        Is Fba:
                      </Typography>
                    </MUIGrid>
                    <MUIGrid
                      item
                      xs={6}
                      sx={{
                        paddingLeft: '10px',
                        paddingTop: '0px !important',
                      }}
                    >
                      <Typography variant="subtitle2">
                        {salesOrderForm.isFba}
                      </Typography>
                    </MUIGrid>
                    <MUIGrid
                      item
                      xs={6}
                      sx={{
                        paddingLeft: '10px',
                        paddingTop: '0px !important',
                      }}
                    >
                      <Typography variant="subtitle2" fontWeight="bold">
                        Is Prime:
                      </Typography>
                    </MUIGrid>
                    <MUIGrid
                      item
                      xs={6}
                      sx={{
                        paddingLeft: '10px',
                        paddingTop: '0px !important',
                      }}
                    >
                      <Typography variant="subtitle2">
                        {salesOrderForm.isPrime}
                      </Typography>
                    </MUIGrid>
                  </>
                )}
              </MUIGrid>
            </MUIGrid>
            <MUIGrid item md={6}>
              <MUIGrid item xs={12}>
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                >
                  <MUIGrid item xs={6}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Purchase Order:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item xs={6}>
                    <Typography variant="subtitle2">
                      {salesOrderForm.purchaseOrder
                        ? salesOrderForm.purchaseOrder
                        : '--'}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Order Date:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm.creationDateTime
                        ? moment(salesOrderForm.creationDateTime).format(
                            'MM/DD/yyyy hh:mm a',
                          )
                        : '--'}{' '}
                    </Typography>
                  </MUIGrid>
                  {salesOrderForm?.isReturn === true && (
                    <>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Original Order No.:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Link
                          sx={{
                            color: '#303538',
                            textDecoration: 'none',
                          }}
                          href={`/orders/sales-orders?form=view&id=${salesOrderForm.returnOriginalOrderId}`}
                          target="_blank"
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              cursor: 'pointer',
                              color: [theme.palette.primary.main],
                            }}
                          >
                            {salesOrderForm?.originalOrderNo}
                          </Typography>
                        </Link>
                      </MUIGrid>
                    </>
                  )}
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Ship by Date:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm.shipByDateTime
                        ? moment(salesOrderForm.shipByDateTime).format(
                            'MMM DD YYYY',
                          )
                        : '--'}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Ship Cancel Date:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm.cancelByDateTime
                        ? moment(salesOrderForm.cancelByDateTime).format(
                            'MMM DD YYYY',
                          )
                        : '--'}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      On Hold:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    xs={6}
                    sx={{ paddingLeft: '10px', paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {isOnHoldValue ? 'Yes' : 'No'}
                    </Typography>
                  </MUIGrid>
                  {salesOrderForm?.deliveryType && (
                    <>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Delivery Method:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2">
                          {salesOrderForm.deliveryType}
                        </Typography>
                      </MUIGrid>
                    </>
                  )}
                  {salesOrderForm?.shippingCost && (
                    <>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Paid Shipping Cost:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2">
                          {salesOrderForm.shippingCost}
                        </Typography>
                      </MUIGrid>
                    </>
                  )}
                  {salesOrderForm.integrationChannel
                    ?.substring(0, 6)
                    .toLowerCase() === 'amazon' && (
                    <>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          Latest Delivery Date:
                        </Typography>
                      </MUIGrid>
                      <MUIGrid
                        item
                        xs={6}
                        sx={{
                          paddingLeft: '10px',
                          paddingTop: '0px !important',
                        }}
                      >
                        <Typography variant="subtitle2">
                          {salesOrderForm.latestDeliveryDate
                            ? moment(salesOrderForm.latestDeliveryDate).format(
                                'MMM DD YYYY',
                              )
                            : '--'}
                        </Typography>
                      </MUIGrid>
                    </>
                  )}
                </MUIGrid>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </Card>
      </MUIGrid>
      <MUIGrid item xs={3}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            height: '250px',
            marginBottom: 2.5,
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={valueSecond}
          handleChange={handleChangeSecond}
          tabView={[
            {
              title: 'Ship To',
              value: '1',
              isDisplay: true,
              children: (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: '20px !important' }}
                >
                  <MUIGrid item sm={12} md={12} lg={4}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Company:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item sm={12} md={12} lg={8}>
                    <Typography variant="subtitle2">
                      {salesOrderForm?.shipTo?.companyName}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Recipient:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm?.shipTo?.recipientName}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Address:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm?.shipTo?.address1}{' '}
                      {salesOrderForm?.shipTo?.address2}
                      <br />
                      {salesOrderForm?.shipTo?.cityTownship},{' '}
                      {salesOrderForm?.shipTo?.stateProvinceRegion}{' '}
                      {salesOrderForm?.shipTo?.zipPostalCode}
                      <br />
                      {salesOrderForm?.shipTo?.countryStr}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Phone, Email:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm?.shipTo.phone
                        ? `${salesOrderForm.shipTo.phone},`
                        : '--'}{' '}
                      {salesOrderForm.shipTo.email
                        ? salesOrderForm?.shipTo?.email
                        : '--'}
                    </Typography>
                  </MUIGrid>
                </MUIGrid>
              ),
            },
            {
              title: 'Bill To',
              value: '2',
              isDisplay: true,
              children: (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: '20px !important' }}
                >
                  <MUIGrid item sm={12} md={12} lg={4}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Company:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid item sm={12} md={12} lg={8}>
                    <Typography variant="subtitle2">
                      {salesOrderForm?.billTo?.companyName}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Recipient:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm?.billTo?.recipientName}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Address:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm?.billTo?.address1}{' '}
                      {salesOrderForm?.billTo?.address2}
                      <br />
                      {salesOrderForm?.billTo?.cityTownship},{' '}
                      {salesOrderForm?.billTo?.stateProvinceRegion}{' '}
                      {salesOrderForm?.billTo?.zipPostalCode}
                      <br />
                      {salesOrderForm?.billTo?.countryStr}
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={4}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2" fontWeight="bold">
                      Phone, Email:
                    </Typography>
                  </MUIGrid>
                  <MUIGrid
                    item
                    sm={12}
                    md={12}
                    lg={8}
                    sx={{ paddingTop: '0px !important' }}
                  >
                    <Typography variant="subtitle2">
                      {salesOrderForm?.billTo.phone
                        ? `${salesOrderForm.billTo.phone},`
                        : '--'}{' '}
                      {salesOrderForm?.billTo.email
                        ? salesOrderForm?.billTo?.email
                        : '--'}
                    </Typography>
                  </MUIGrid>
                </MUIGrid>
              ),
            },
          ]}
        />
      </MUIGrid>
      <MUIGrid item xs={4}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            height: '250px',
            marginBottom: 2,
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={valueThird}
          handleChange={handleChangeThird}
          tabView={[
            {
              title: 'Carrier',
              value: '1',
              isDisplay: true,
              children: (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: '20px !important' }}
                >
                  <MUIGrid
                    item
                    xs={7}
                    sx={{ borderRight: '2px solid #d8d8d8' }}
                  >
                    <MUIGrid item xs={12}>
                      <MUIGrid
                        container
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={5}
                          sx={{
                            paddingLeft: '10px',
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight="bold">
                            Ship via Carrier:
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={7}
                          sx={{
                            paddingLeft: '10px',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {salesOrderForm?.carrier
                              ? salesOrderForm.carrier
                              : '--'}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={5}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight="bold">
                            Carrier Service:
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={7}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {carrierServiceValue?.label
                              ? carrierServiceValue.label
                              : '--'}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={5}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight="bold">
                            Package:
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={7}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            className="cellDatagridWrapper"
                          >
                            {packageCartonCodeValue?.label
                              ? packageCartonCodeValue.label
                              : '--'}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={5}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight="bold">
                            Account No.:
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={7}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {shippingAccountValue?.label
                              ? shippingAccountValue.label
                              : '--'}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={5}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight="bold">
                            Zip Code:
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={7}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {salesOrderForm?.accountZipPostalCode
                              ? salesOrderForm.accountZipPostalCode
                              : '--'}
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={5}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2" fontWeight="bold">
                            SCAC Code:
                          </Typography>
                        </MUIGrid>
                        <MUIGrid
                          item
                          sm={12}
                          md={12}
                          lg={7}
                          sx={{
                            paddingLeft: '10px',
                            paddingTop: '0px !important',
                          }}
                        >
                          <Typography variant="subtitle2">
                            {salesOrderForm?.scacCode
                              ? salesOrderForm.scacCode
                              : '--'}
                          </Typography>
                        </MUIGrid>
                      </MUIGrid>
                    </MUIGrid>
                  </MUIGrid>

                  <MUIGrid item xs={5}>
                    <MUIGrid item xs={12}>
                      <MUIGrid
                        container
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {(salesOrderFlagStatus.statusStr === 'Complete' ||
                          salesOrderFlagStatus.statusStr === 'Partial') && (
                          <>
                            <MUIGrid
                              item
                              xs={5}
                              sx={{
                                paddingLeft: '10px',
                              }}
                            >
                              <Typography variant="subtitle2" fontWeight="bold">
                                Ship Date:
                              </Typography>
                            </MUIGrid>
                            <MUIGrid
                              item
                              xs={7}
                              sx={{
                                paddingLeft: '10px',
                              }}
                            >
                              <Typography variant="subtitle2">
                                {moment(salesOrderForm.completeDateTime).format(
                                  'MM/DD/YYYY',
                                )}
                              </Typography>
                            </MUIGrid>
                          </>
                        )}
                        {salesOrderFlagStatus.statusStr === 'Cancelled' && (
                          <>
                            <MUIGrid
                              item
                              xs={5}
                              sx={{
                                paddingLeft: '10px',
                                paddingTop: '0px !important',
                              }}
                            >
                              <Typography variant="subtitle2" fontWeight="bold">
                                Cancel Date:
                              </Typography>
                            </MUIGrid>
                            <MUIGrid
                              item
                              xs={7}
                              sx={{
                                paddingLeft: '10px',
                                paddingTop: '0px !important',
                              }}
                            >
                              <Typography variant="subtitle2">
                                {moment(salesOrderForm.cancelByDateTime).format(
                                  'YYYY/MM/DD',
                                )}
                              </Typography>
                            </MUIGrid>
                          </>
                        )}
                      </MUIGrid>

                      {(salesOrderFlagStatus.statusStr === 'Complete' ||
                        salesOrderFlagStatus.statusStr === 'Partial') &&
                        isAdmin && (
                          <MUIGrid
                            container
                            spacing={2}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          >
                            <MUIGrid
                              item
                              xs={7}
                              sx={{
                                paddingLeft: '10px',
                              }}
                            >
                              <Typography variant="subtitle2" fontWeight="bold">
                                Our Cost:
                              </Typography>
                            </MUIGrid>
                            <MUIGrid
                              item
                              xs={5}
                              sx={{
                                paddingLeft: '10px',
                              }}
                            >
                              {salesOrderForm.shipmentCost && (
                                <Typography variant="subtitle2">
                                  ${salesOrderForm.shipmentCost}
                                </Typography>
                              )}
                            </MUIGrid>
                          </MUIGrid>
                        )}
                      <MUIGrid
                        container
                        spacing={2}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      >
                        {(salesOrderFlagStatus.statusStr === 'Complete' ||
                          salesOrderFlagStatus.statusStr === 'Partial') && (
                          <>
                            <MUIGrid
                              item
                              xs={7}
                              sx={{
                                paddingLeft: '10px',
                              }}
                            >
                              <Typography variant="subtitle2" fontWeight="bold">
                                Total Shipment Cost:
                              </Typography>
                            </MUIGrid>
                            <MUIGrid
                              item
                              xs={5}
                              sx={{
                                paddingLeft: '10px',
                              }}
                            >
                              {salesOrderForm.totalShipmentCost && (
                                <Typography variant="subtitle2">
                                  ${salesOrderForm.totalShipmentCost}
                                </Typography>
                              )}
                            </MUIGrid>
                          </>
                        )}
                      </MUIGrid>
                    </MUIGrid>
                  </MUIGrid>
                </MUIGrid>
              ),
            },
            {
              title: (
                <>
                  <span
                    style={{
                      display: noteCount > 0 ? 'flex' : 'none',
                      position: 'absolute',
                      top: '3px',
                      right: '0',
                      borderRadius: '100%',
                      background: '#3375e0',
                      padding: '2px 7px',
                      color: '#fff',
                      fontSize: '0.7rem',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      zIndex: 999,
                    }}
                  >
                    {noteCount}
                  </span>
                  <span>Notes</span>
                </>
              ),
              value: '2',
              isDisplay: true,
              children: (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: '20px !important' }}
                  justifyContent="flex-end"
                >
                  <MUIGrid item xs={4} sx={{ height: 0 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        float: 'right',
                        marginTop: '-14px',
                        height: '25px',
                        whiteSpace: 'nowrap',
                        display: handleUserPermissionAllow(
                          UserPermissionEnum.Orders_EditNotes,
                        )
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => setOrderNotesModalOpen(true)}
                    >
                      Add Note
                    </Button>
                  </MUIGrid>
                  <MUIGrid item xs={12}>
                    <DatagridPremium
                      autoHeight
                      rows={orderNotes}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnMenu
                      hideFooter
                      columns={notesColumns}
                      rowsPerPageOptions={[25]}
                      pageSize={25}
                      hideFooterSelectedRowCount
                      hideFooterPagination
                      getRowId={(row) => row.orderNoteId}
                      density="compact"
                    />
                  </MUIGrid>
                  <OrderNotesModal />
                </MUIGrid>
              ),
            },
            {
              title: (
                <>
                  <span
                    style={{
                      display: docCount > 0 ? 'flex' : 'none',
                      position: 'absolute',
                      top: '3px',
                      right: '0',
                      borderRadius: '100%',
                      background: '#3375e0',
                      padding: '2px 7px',
                      color: '#fff',
                      fontSize: '0.7rem',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      zIndex: 999,
                    }}
                  >
                    {docCount}
                  </span>
                  <span>Documents</span>
                </>
              ),
              value: '3',
              isDisplay: true,
              children: (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: '20px !important' }}
                  justifyContent="flex-end"
                >
                  <MUIGrid item xs={4} sx={{ height: 0 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        float: 'right',
                        marginTop: '-14px',
                        height: '25px',
                        whiteSpace: 'nowrap',
                        display: handleUserPermissionAllow(
                          UserPermissionEnum.Orders_DocumentUpload,
                        )
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => fileUploadRef.current.click()}
                    >
                      Upload File
                    </Button>
                    <input
                      ref={fileUploadRef}
                      type="file"
                      name="file"
                      accept="image/*,.pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      multiple
                      onChange={uploadFile}
                      hidden
                    />
                  </MUIGrid>
                  <MUIGrid item xs={12}>
                    <DatagridPremium
                      autoHeight
                      rows={documentsRows}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnMenu
                      hideFooter
                      columns={documentsColumns}
                      rowsPerPageOptions={[25]}
                      pageSize={25}
                      hideFooterSelectedRowCount
                      hideFooterPagination
                      getRowId={(row) => row.orderDocumentId}
                      density="compact"
                    />
                  </MUIGrid>
                  {/* <MUIGrid
                    item
                    xs={6}
                    sx={{
                      paddingLeft: '10px',
                    }}
                  >
                    <Typography variant="subtitle2">--</Typography>
                  </MUIGrid> */}
                </MUIGrid>
              ),
            },
            {
              title: (
                <>
                  <span
                    style={{
                      display:
                        salesOrderForm.orderChecklists.length > 0
                          ? 'flex'
                          : 'none',
                      position: 'absolute',
                      top: '3px',
                      right: '0',
                      borderRadius: '100%',
                      background: '#3375e0',
                      padding: '2px 7px',
                      color: '#fff',
                      fontSize: '0.7rem',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontWeight: 'bold',
                      zIndex: 999,
                    }}
                  >
                    {salesOrderForm.orderChecklists.length}
                  </span>
                  <span>Checklist</span>
                </>
              ),
              value: '4',
              isDisplay: true,
              children: (
                <MUIGrid
                  container
                  spacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  sx={{ padding: '20px !important' }}
                  justifyContent="flex-end"
                >
                  <MUIGrid item xs={4} sx={{ height: 0 }}>
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{
                        float: 'right',
                        marginTop: '-14px',
                        height: '25px',
                        whiteSpace: 'nowrap',
                        display: handleUserPermissionAllow(
                          UserPermissionEnum.Orders_EditChecklist,
                        )
                          ? 'block'
                          : 'none',
                      }}
                      onClick={() => {
                        setChecklistModal((prevState) => ({
                          ...prevState,
                          orderChecklistId: null,
                          name: null,
                          nameError: '',
                        }));
                      }}
                    >
                      Add Checklist
                    </Button>
                  </MUIGrid>
                  <MUIGrid item xs={12}>
                    <DatagridPremium
                      autoHeight
                      rows={salesOrderForm.orderChecklists}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      disableColumnMenu
                      hideFooter
                      columns={checklistColumns}
                      rowsPerPageOptions={[25]}
                      pageSize={25}
                      hideFooterSelectedRowCount
                      hideFooterPagination
                      getRowId={(row) => row.orderChecklistId}
                      density="compact"
                    />
                  </MUIGrid>
                  <ChecklistModal />
                </MUIGrid>
              ),
            },
          ]}
        />
      </MUIGrid>
      <ConfirmationModal
        dialogText={confirmationModalText}
        callBack={handleDeleteConfirmation}
      />
    </MUIGrid>
  );
};

const SecondRow = () => {
  const { isWarehouseCustomerAccount } = useContext(AuthContext);

  const {
    orderPickTicketItemRows,
    billingAdhocChargesData,
    salesOrderForm,
    salesOrderFlagStatus,
    valueSecondTab,
    handleChangeSecond,
  } = useSalesOrderContext();

  const [labelRows, setLabelRows] = useState([]);
  useEffect(() => {
    if (salesOrderForm.orderId) {
      const response = getShipmentLabels(salesOrderForm.orderId);

      response.then((rows) => {
        setLabelRows(rows);
      });
    }
  }, [salesOrderForm.orderId]);

  const printAllShippingLabels = async () => {
    const fileContent = await printShippingLabelByOrderId(
      salesOrderForm.orderId,
    );

    const blob = new Blob([fileContent.data], { type: 'application/pdf' });
    const blobURL = URL.createObjectURL(blob);

    const iframe = document.createElement('iframe'); // load content in an iframe to print later
    document.body.appendChild(iframe);

    iframe.style.display = 'none';
    iframe.src = blobURL;
    iframe.onload = function () {
      setTimeout(() => {
        iframe.focus();
        iframe.contentWindow.print();
      }, 1);
    };
  };

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            marginBottom: 2.5,
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={valueSecondTab}
          handleChange={handleChangeSecond}
          tabView={[
            {
              title: 'Line Items',
              value: '1',
              isDisplay: true,
              children: LineItems(),
            },
            {
              title: 'Pick Tickets',
              value: '2',
              isDisplay:
                !isWarehouseCustomerAccount &&
                salesOrderFlagStatus.status !== OrderStatus.Shipment &&
                salesOrderFlagStatus.status !== OrderStatus.Complete &&
                orderPickTicketItemRows.length > 0,
              children: PickTickets(),
            },
            {
              title: (
                <>
                  Packages ({salesOrderForm?.orderPackages?.length})
                  <IconButton
                    aria-label="print"
                    color="primary"
                    onClick={(e) => {
                      printAllShippingLabels();
                    }}
                  >
                    <PrintIcon />
                  </IconButton>
                </>
              ),
              value: '3',
              isDisplay:
                (salesOrderFlagStatus.status === OrderStatus.Shipment ||
                  salesOrderFlagStatus.status === OrderStatus.Partial ||
                  salesOrderFlagStatus.status === OrderStatus.Complete) &&
                salesOrderForm.orderPackages.length > 0,
              children: Packages(),
            },
            {
              title: `Pallets (${salesOrderForm?.orderPallets?.length})`,
              value: '4',
              isDisplay:
                (salesOrderFlagStatus.status === OrderStatus.Shipment ||
                  salesOrderFlagStatus.status === OrderStatus.Partial ||
                  salesOrderFlagStatus.status === OrderStatus.Complete) &&
                salesOrderForm?.orderPallets?.length > 0,
              children: Pallets(),
            },
            {
              title: 'Billing Charge',
              value: '5',
              isDisplay:
                billingAdhocChargesData.length > 0 &&
                !isWarehouseCustomerAccount,
              children: BillingChargeSection(),
            },
          ]}
        />
      </MUIGrid>
    </MUIGrid>
  );
};

const OrderCreateUI = () => {
  const theme = useTheme();

  const { isWarehouseCustomerAccount } = useContext(AuthContext);
  const {
    pageViewer,
    billingAdhocChargesData,
    valueSecondTab,
    handleChangeSecond,
  } = useSalesOrderContext();

  return (
    <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <MUIGrid item xs={12} md={6}>
        <OrderDetails />
      </MUIGrid>
      <MUIGrid item xs={12} md={6}>
        <ContactDetails theme={theme} />
      </MUIGrid>
      <MUIGrid item xs={12}>
        <CardTab
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px !important',
            overflow: 'auto',
            borderRadius: 2,
            border: '1px solid #cdcdcd',
          }}
          tabValue={valueSecondTab}
          handleChange={handleChangeSecond}
          tabView={[
            {
              title: 'Line Items',
              value: '1',
              isDisplay: true,
              children: LineItems(),
            },
            {
              title: 'Billing Charge',
              value: '4',
              isDisplay:
                billingAdhocChargesData.length > 0 &&
                !isWarehouseCustomerAccount,
              children: BillingChargeSection(),
            },
          ]}
        />
      </MUIGrid>
      <MUIGrid item xs={12}>
        {!pageViewer.isView() && (
          <Button
            type="submit"
            variant="contained"
            size="medium"
            sx={{ padding: 1, backgroundColor: 'rgb(5, 47, 255)' }}
          >
            SAVE
          </Button>
        )}
      </MUIGrid>
    </MUIGrid>
  );
};

const SalesOrderContainer = () => {
  const {
    pageViewer,
    salesOrderForm,
    formTemplateType,
    orderPickTicketItemRows,
    createSalesOrderOnClick,
  } = useSalesOrderContext();

  return (
    <Box
      autoComplete="off"
      component="form"
      onSubmit={createSalesOrderOnClick}
      noValidate
    >
      {pageViewer.isView() ? (
        <>
          <OrderProgressBar />
          <FirstRow />
          <SecondRow />
        </>
      ) : (
        <OrderCreateUI />
      )}

      {formTemplateType === FormTemplateTypeEnum.BillofLading && (
        <PrintFormTemplate
          preview={false}
          dynamicId={salesOrderForm.orderId}
          formtemplateTypeId={formTemplateType}
        />
      )}

      {formTemplateType === FormTemplateTypeEnum.PackingList && (
        <PrintFormTemplate
          preview={false}
          dynamicId={salesOrderForm.orderId}
          formtemplateTypeId={formTemplateType}
          isComingFromOrder
        />
      )}
    </Box>
  );
};

const SalesOrders = () => (
  <MainContainer>
    <SalesOrderContextProvider>
      <Header />
      <BackOrderWarningModal />
      <LineItemModal />
      <ModalReturnLineItem />
      <CreateReturnOverrideModal />
      <OrderCompleteModal />
      <ConfirmRemovePackingInProcessModal />
      <CompleteOrderManuallyModal page={pageEnum.OrderPage} />
      <OrderPickingUomModal />
      <PickTicketModal />
      <ConfirmLineItemModal />
      <ConfirmPickTicketModal />
      <ModalPackageLineItem />
      <VoidThePackModal />
      <ContentContainer>
        <SalesOrderContainer />
      </ContentContainer>
    </SalesOrderContextProvider>
  </MainContainer>
);
export default React.memo(SalesOrders);
