import React, { useContext, useState, useEffect } from 'react';

import Input from 'components/input';
import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { createExtensivIntegration } from 'services/api/integrations/extensiv/extensive.api';
import { getOrderSourceList } from 'services/api/salesorders/salesorders-new.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  Grid as MUIGrid,
  TextField,
  Button,
  Switch,
} from '@mui/material';
import { styled as MuiStyled } from '@mui/material/styles';

const MUIContainer = MuiStyled(Box)`
  && {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: ${(props) =>
      props.theme.palette.mode === 'dark'
        ? props.theme.palette.background.default
        : 'white'};
    box-shadow: 0px 24px 38px #00000024;

    width: 100%;
    max-width: 850px;
    border-radius: 4px;
  }
`;
const MUIContent = MuiStyled(Box)`
  && {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    padding: 24px;
    gap: 16px;
  }
`;
export default React.memo(() => {
  const { setExtensivIntegrationModalOpen, isExtensivIntegrationModalOpen } =
    useContext(GlobalContext);

  const {
    extensivIntegrationProps,
    queryString,
    loadExtensivIntegrations,
    availableIntegrationOptions,
    addAvailableIntegration,
    setAddAvailableIntegration,
    setExtensivIntegrationProps,
  } = useWarehouseCustomerContext();
  const [integrationFields, setIntegrationFields] = useState([]);
  const { currentLocationAndFacility, currentUser } = useContext(AuthContext);

  const initialState = {
    warehouseCustomerId: '',
    name: '',
    cart_id: '',
    user_key: '',
    password: '',
    updateConnection: false,
    customerFacilityId: 0,
    customerLocationId: 0,
    customerId: 0,
    extensivCartConfigurationId: 0,
    isUpdateShipStationOrder: false,
    orderSource: null,
  };
  type AutocompleteBase = {
    label?: string;
    value?: number;
    auxValue?: number;
  };
  const [internalForm, setForm] = useState<any>(initialState);
  const [orderSourceValue, setOrderSourceValue] =
    useState<AutocompleteBase>(null);

  const availableIntegrations = {
    value: 0,
    label: '',
  };

  const [availableIntegrationValue, setAvailableIntegrationValue] =
    useState<any>(availableIntegrations);

  const handleUpdateShipStationOrder = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setForm(() => ({
      ...internalForm,
      isUpdateShipStationOrder: event.target.checked,
    }));
  };

  const [optionOrderSource, setOptionOrderSource] = useState<
    AutocompleteBase[] | null | []
  >([]);

  useEffect(() => {
    if (isExtensivIntegrationModalOpen) {
      if (extensivIntegrationProps) {
        let entries = [];
        if (extensivIntegrationProps.fields) {
          entries = Object.entries(extensivIntegrationProps.fields);
        }

        setForm(() => ({
          ...internalForm,
          cart_id: extensivIntegrationProps.id,
          name: extensivIntegrationProps.name,
          warehouseCustomerId: queryString.id,
          customerLocationId: currentLocationAndFacility.locationId,
          customerFacilityId: currentLocationAndFacility.customerFacilityId,
          customerId: currentUser.Claim_CustomerId,
          user_key: extensivIntegrationProps.key,
          password: extensivIntegrationProps.secret,
          updateConnection: !!(
            extensivIntegrationProps.key !== undefined &&
            extensivIntegrationProps.key !== ''
          ),
          extensivCartConfigurationId:
            extensivIntegrationProps.extensivCartConfigurationId,
          isUpdateShipStationOrder:
            extensivIntegrationProps.isUpdateShipStationOrder,
        }));

        const fields = [];
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < entries.length; index++) {
          const element = entries[index];
          fields.push({ label: element[1], value: element[0] });
        }

        setIntegrationFields(fields);
      }

      const optOrderSources = getOrderSourceList(
        queryString.id ?? currentUser.Claim_WarehouseCustomerId,
      );

      optOrderSources
        .then((opt) => {
          setOptionOrderSource(
            opt.map((c: any) => ({
              label: c.text,
              value: c.value,
            })),
          );
        })
        .catch();
    }
  }, [extensivIntegrationProps, isExtensivIntegrationModalOpen]);

  const cancelForm = async () => {
    setForm(initialState);
    setAddAvailableIntegration(false);
    setAvailableIntegrationValue(null);
    setExtensivIntegrationModalOpen(false);
  };

  const submitForm = async () => {
    try {
      const response = await createExtensivIntegration(internalForm);
      if (response !== null && response.data !== '') {
        window.open(response.data, '_blank');
      }

      snackActions.success(`${internalForm.name} connected.`);
      cancelForm();
      loadExtensivIntegrations();
    } catch (error) {
      snackActions.error(error);
    }
  };

  return (
    <Modal
      open={isExtensivIntegrationModalOpen}
      onClose={() => {
        setExtensivIntegrationModalOpen(false);
      }}
    >
      <MUIContainer>
        <MUIContent>
          <MUIGrid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {addAvailableIntegration && (
              <>
                <MUIGrid item xs={6} sx={{ textAlign: 'center' }}>
                  <Autocomplete
                    options={availableIntegrationOptions}
                    getOptionLabel={(option: any) => option.label}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    value={availableIntegrationValue || null}
                    onChange={(event: any, newValue: any | null) => {
                      if (newValue === null) {
                        setAvailableIntegrationValue(null);
                        setExtensivIntegrationProps(null);
                      } else {
                        setAvailableIntegrationValue((prev) => ({
                          ...prev,
                          value: newValue.value,
                          label: newValue.label,
                        }));
                        setExtensivIntegrationProps((prev) => ({
                          ...prev,
                          id: newValue.id,
                          name: newValue.name,
                          key: newValue.key,
                          secret: newValue.secret,
                          icon: newValue.icon,
                          fields: newValue.fields,
                        }));
                      }
                    }}
                    renderInput={(params) => (
                      <div>
                        <TextField
                          {...params}
                          label="Available Integrations"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                          }}
                          sx={{ boxShadow: 'none !important' }}
                          fullWidth
                          size="small"
                        />
                      </div>
                    )}
                  />
                </MUIGrid>
                <MUIGrid item xs={6}>
                  <Autocomplete
                    onChange={(event: any, newValue: AutocompleteBase) => {
                      if (newValue !== null) {
                        setOrderSourceValue(newValue);
                        setForm(() => ({
                          ...internalForm,
                          orderSourceId: newValue.label,
                        }));
                      } else {
                        setOrderSourceValue(null);
                        setForm(() => ({
                          ...internalForm,
                          orderSourceId: null,
                        }));
                      }
                    }}
                    id="controllable-states"
                    options={optionOrderSource}
                    getOptionLabel={(option: AutocompleteBase) => option.label}
                    value={orderSourceValue}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li
                        data-name="orderSourceId"
                        {...props}
                        key={option.value}
                      >
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                        autoComplete="off"
                        id="outlined-required"
                        size="small"
                        sx={{ width: '100%' }}
                        label="Source"
                      />
                    )}
                  />
                </MUIGrid>
              </>
            )}

            {extensivIntegrationProps && (
              <>
                <MUIGrid item xs={12} sx={{ textAlign: 'center' }}>
                  <Box
                    component="img"
                    alt={extensivIntegrationProps?.name}
                    src={extensivIntegrationProps?.icon}
                  />
                </MUIGrid>
                <MUIGrid item xs={12} sx={{ textAlign: 'center' }}>
                  <Typography variant="subtitle1" fontWeight="bold" mb={2}>
                    {extensivIntegrationProps?.name}
                  </Typography>
                </MUIGrid>
              </>
            )}
          </MUIGrid>
          <MUIGrid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <>
              {extensivIntegrationProps &&
                integrationFields &&
                integrationFields.map((item) => (
                  <MUIGrid item xs={6} key={item.label}>
                    <Input
                      placeholder={item.label}
                      size="small"
                      sx={{
                        width: '100%',
                        backgroundColor: '#ffffff !important',
                      }}
                      value={internalForm[item.value]}
                      onChange={(value) =>
                        setForm(() => ({
                          ...internalForm,
                          [item.value]: value.target.value,
                        }))
                      }
                    />
                  </MUIGrid>
                ))}

              {extensivIntegrationProps &&
                integrationFields &&
                extensivIntegrationProps.id === 'ShipStation' && (
                  <MUIGrid item xs={12}>
                    Update Ship Station Order:
                    <Switch
                      checked={internalForm.isUpdateShipStationOrder}
                      onChange={handleUpdateShipStationOrder}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </MUIGrid>
                )}
              <span />
            </>
          </MUIGrid>
          <MUIGrid
            container
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={2}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Button
                onClick={() => {
                  cancelForm();
                }}
                variant="outlined"
                size="large"
                sx={{ padding: 1, width: '100%' }}
              >
                CANCEL
              </Button>
            </MUIGrid>
            <MUIGrid item xs={6}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{ padding: 1, width: '100%', backgroundColor: '#DE4A50' }}
                onClick={() => {
                  submitForm();
                }}
                disabled={!extensivIntegrationProps}
              >
                SUBMIT
              </Button>
            </MUIGrid>
          </MUIGrid>
        </MUIContent>
      </MUIContainer>
    </Modal>
  );
});
