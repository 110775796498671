import React, { ChangeEvent, useContext, useEffect, useState } from 'react';

import Card from 'components/card';
import CardWithHeader from 'components/card/CardWithHeader';
import Input from 'components/input/Input';
import { snackActions } from 'config/snackbar.js';
import { getCountries } from 'services/api/country/country.api';
import {
  editCustomer,
  getCustomerById,
} from 'services/api/customer/customer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import * as yup from 'yup';

import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Typography,
  Button,
  Grid as MUIGrid,
  Autocomplete,
  TextField,
} from '@mui/material';

function CompanyProfile() {
  const { currentUser, currentLocationAndFacility, updateData } =
    useContext(AuthContext);

  interface AutoCompleteOptionType {
    id: number;
    label: string;
  }

  const initialFormErrorsState: any = {
    name: '',
    address: '',
    address2: '',
    city: '',
    stateProvince: '',
    zipPostalCode: '',
    phoneNumber: '',
    warehouseLogo: '',
    favicon: '',
    domainUrl: '',
    contactEmail: '',
  };

  const initialState = {
    customerId: '',
    name: '',
    address: '',
    address2: '',
    city: '',
    contactEmail: '',
    stateProvince: '',
    zipPostalCode: '',
    phoneNumber: '',
    pointOfContact: '',
    country: null,
    warehouseLogo: '',
    favicon: '',
    domainUrl: '',
  };
  const [formErrors, setFormErrors] = useState(initialFormErrorsState);
  const [form, setForm] = useState(initialState);
  const [showErrors, setShowErrors] = useState(false);
  const [error, setError] = useState('');
  const [countries, setCountries] = useState([]);

  const loadCountries = async () => {
    try {
      const countriesFromApi = await getCountries('');
      const countryList = countriesFromApi.map((v, index) => ({
        id: v.countryId,
        label: v.name,
      }));
      setCountries(countryList);
      return true;
    } catch (err) {
      return err;
    }
  };

  const onForm = (key, text) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: text,
    }));
  };

  const onLoadCustomerProfile = async () => {
    try {
      const result = await getCustomerById(currentUser.Claim_CustomerId);
      loadCountries();
      setForm((e) => ({
        ...e,
        customerId: result.customerId,
        name: result.name,
        address: result.address,
        address2: result.address2,
        city: result.city,
        contactEmail: result.contactEmail,
        stateProvince: result.stateProvince,
        zipPostalCode: result.zipPostalCode,
        phoneNumber: result.phoneNumber,
        pointOfContact: result.pointOfContact,
        country: { id: result.country, label: result.countryStr },
        warehouseLogo: result.warehouseLogo,
        favicon: result.favicon,
        domainUrl: result.domainUrl,
      }));

      return true;
    } catch (err: any) {
      return err;
    }
  };

  const inputHandler = (key: string, event: ChangeEvent<HTMLInputElement>) =>
    onForm(key, event.target.value);

  const onImageChange = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    const MIN_FILE_SIZE = 1024; // 1MB

    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size / 1024 > MIN_FILE_SIZE) {
        // eslint-disable-next-line no-alert
        window.alert('Please upload a file smaller than 1 MB');
      } else {
        const reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          const regex = /data:.*base64,/;
          const base64Data = reader.result.toString().replace(regex, '');
          setForm(() => ({
            ...form,
            [key]: base64Data,
          }));
        };
      }
    }
  };

  const schema = yup.object().shape({
    name: yup.string().required('Required.'),
    address: yup.string().required('Required.'),
    address2: yup.string(),
    city: yup.string().required('Required.'),
    stateProvince: yup.string().required('Required.'),
    zipPostalCode: yup.string().required('Required.'),
    warehouseLogo: yup.string(),
    contactEmail: yup.string().required('Required.'),
    favicon: yup.string(),
  });

  useEffect(() => {
    onLoadCustomerProfile();
  }, [currentLocationAndFacility, updateData]);

  const onSaveNewCustomer = async () => {
    schema
      .validate(form, {
        abortEarly: false,
      })
      .then(async () => {
        try {
          const payload = {
            customerId: form.customerId,
            name: form.name,
            address: form.address,
            address2: form.address2,
            city: form.city,
            contactEmail: form.contactEmail,
            stateProvince: form.stateProvince,
            zipPostalCode: form.zipPostalCode,
            phoneNumber: form.phoneNumber,
            pointOfContact: form.pointOfContact,
            country: form.country?.id,
            warehouseLogo: form.warehouseLogo,
            favicon: form.favicon,
            domainUrl: form.domainUrl,
          };
          await editCustomer(currentUser.Claim_CustomerId, payload);
          snackActions.success(`Successfully edited customer.`);
          setShowErrors(false);
          setError('');
          setFormErrors(initialFormErrorsState);
        } catch (err: any) {
          setError(err);
          setFormErrors(initialFormErrorsState);
          setShowErrors(true);
          snackActions.error(err);
        }
      })
      .catch((err) => {
        const errorsFound = err.inner.reduce((acc, curr) => {
          if (!acc[curr.path]) acc[curr.path] = curr.message;
          return acc;
        }, {});
        setFormErrors(errorsFound);
        setError('');
        setShowErrors(true);
      });
  };
  return (
    <>
      {/* Company Profile */}
      <Card sx={{ display: 'flex', flexDirection: 'column' }}>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ marginBottom: 2 }}
        >
          <MUIGrid item xs={12}>
            <MUIGrid container>
              <MUIGrid
                item
                xs={12}
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">
                  <Box fontWeight="bold">Company Details</Box>
                </Typography>
                <Button
                  sx={{ display: 'flex', width: '10%' }}
                  variant="contained"
                  size="small"
                  onClick={() => onSaveNewCustomer()}
                >
                  Save Changes
                </Button>
              </MUIGrid>
              <MUIGrid item xs={12}>
                <Typography variant="subtitle1">
                  <Box>
                    Use corporate or headquarters information. This address will
                    be used in on printouts and other relevant fields.
                  </Box>
                </Typography>
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ marginBottom: 1 }}
        >
          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Company Name"
              value={form.name}
              error={formErrors.name}
              size="large"
              onChange={(value) => inputHandler('name', value)}
            />
          </MUIGrid>

          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Company Address"
              value={form.address}
              error={formErrors.address}
              size="large"
              onChange={(value) => inputHandler('address', value)}
            />
          </MUIGrid>

          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Company Address 2"
              value={form.address2}
              error={formErrors.address2}
              size="large"
              onChange={(value) => inputHandler('address2', value)}
            />
          </MUIGrid>

          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Company City"
              value={form.city}
              error={formErrors.city}
              size="large"
              onChange={(value) => inputHandler('city', value)}
            />
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ marginBottom: 1 }}
        >
          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Company State or Province"
              value={form.stateProvince}
              error={formErrors.stateProvince}
              size="large"
              onChange={(value) => inputHandler('stateProvince', value)}
            />
          </MUIGrid>
          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Company Postal Code"
              value={form.zipPostalCode}
              error={formErrors.zipPostalCode}
              size="large"
              onChange={(value) => inputHandler('zipPostalCode', value)}
            />
          </MUIGrid>
          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Phone Number"
              value={form.phoneNumber}
              error={formErrors.phoneNumber}
              size="large"
              onChange={(value) => inputHandler('phoneNumber', value)}
            />
          </MUIGrid>
          <MUIGrid item xs={3}>
            <Autocomplete
              sx={{ width: '100%' }}
              options={countries}
              getOptionLabel={(option) => (option.label ? option.label : '')}
              isOptionEqualToValue={(option, selected) =>
                option.value === selected.value
              }
              size="medium"
              value={form.country || null}
              onChange={(event: any, value: AutoCompleteOptionType | null) => {
                if (value != null) {
                  setForm((e) => ({
                    ...e,
                    country: value,
                  }));
                } else {
                  setForm((e) => ({
                    ...e,
                    country: null,
                  }));
                }
              }}
              renderInput={(params) =>
                formErrors.country ? (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    autoComplete="off"
                    label="Country"
                    error
                    helperText={formErrors.country}
                  />
                ) : (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    autoComplete="off"
                    label="Country"
                  />
                )
              }
            />
          </MUIGrid>
        </MUIGrid>
        <MUIGrid
          container
          spacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ marginBottom: 1 }}
        >
          <MUIGrid item xs={3}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Button
                  sx={{
                    height: '90px',
                    maxWidth: '100%',
                    width: '100px',
                    border: '2px dashed #4171ff !important',
                    backgroundColor: 'rgb(216 232 255) !important',
                    borderRadius: '5px !important',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                  }}
                  variant="contained"
                  component="label"
                >
                  <UploadFileIcon
                    sx={{ fontSize: '40px', color: 'black !important', mb: 1 }}
                  />
                  <Typography
                    sx={{ fontSize: '7.5px', color: 'black !important' }}
                    fontWeight="bold"
                  >
                    Warehouse Logo
                  </Typography>
                  <input
                    hidden
                    type="file"
                    onChange={(value) => onImageChange('warehouseLogo', value)}
                    className="filetype"
                    accept="image/png, image/jpeg"
                  />
                </Button>
              </MUIGrid>
              <MUIGrid
                item
                xs={8}
                rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ paddingLeft: ' 5px important' }}
              >
                {form.warehouseLogo ? (
                  <input
                    disabled
                    type="image"
                    src={`data:image/jpeg;base64,${form.warehouseLogo}`}
                    alt="preview image"
                    width="86"
                    accept="image/*"
                  />
                ) : (
                  <>
                    <Typography fontWeight="bold" variant="subtitle2">
                      Upload Warehouse Logo
                    </Typography>
                    <Typography sx={{ fontSize: 'x-small', color: 'red' }}>
                      (Only JPG & PNG)
                    </Typography>
                  </>
                )}
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>

          <MUIGrid item xs={3}>
            <MUIGrid
              container
              spacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <MUIGrid item xs={4}>
                <Button
                  sx={{
                    height: '90px',
                    maxWidth: '100%',
                    width: '100px',
                    border: '2px dashed #4171ff !important',
                    backgroundColor: 'rgb(216 232 255) !important',
                    borderRadius: '5px !important',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                  }}
                  variant="contained"
                  component="label"
                >
                  <UploadFileIcon
                    sx={{ fontSize: '40px', color: 'black !important', mb: 1 }}
                  />
                  <Typography
                    sx={{ fontSize: '7.5px', color: 'black !important' }}
                    fontWeight="bold"
                  >
                    Favicon
                  </Typography>
                  <input
                    hidden
                    type="file"
                    onChange={(value) => onImageChange('favicon', value)}
                    className="filetype"
                    accept="image/png, image/jpeg"
                  />
                </Button>
              </MUIGrid>
              <MUIGrid
                item
                xs={8}
                rowSpacing={{ xs: 1, sm: 2, md: 3 }}
                sx={{ paddingLeft: ' 5px important' }}
              >
                {form.favicon ? (
                  <input
                    disabled
                    type="image"
                    src={`data:image/jpeg;base64,${form.favicon}`}
                    alt="preview image"
                    width="86"
                    accept="image/*"
                  />
                ) : (
                  <>
                    <Typography fontWeight="bold" variant="subtitle2">
                      Upload Favicon
                    </Typography>
                    <Typography sx={{ fontSize: 'x-small', color: 'red' }}>
                      (Only JPG & PNG)
                    </Typography>
                  </>
                )}
              </MUIGrid>
            </MUIGrid>
          </MUIGrid>

          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Domain URL"
              value={form.domainUrl}
              error={formErrors.domainUrl}
              size="large"
              onChange={(value) => inputHandler('domainUrl', value)}
            />
          </MUIGrid>
          <MUIGrid item xs={3}>
            <Input
              sx={{ width: '100%' }}
              placeholder="Contact Email"
              value={form.contactEmail}
              error={formErrors.contactEmail}
              size="large"
              onChange={(value) => inputHandler('contactEmail', value)}
            />
          </MUIGrid>
        </MUIGrid>
      </Card>
    </>
  );
}

export default React.memo(CompanyProfile);
