import React, { useContext, useState, ChangeEvent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ReceiptLineItemStatusOnArrivalEnum } from 'common/enums';
import { DropdownListItem, DropDownListItemById } from 'common/models';
import { CustomerFacilityLocationDropDownListItem } from 'common/models/customerfacilities';
import { snackActions } from 'config/snackbar.js';
import { decimalValidationHelper } from 'pages/inventory/item/details';
import ConfirmationModal from 'pages/shared/confirmationmodal';
import {
  getTransferDetails,
  getGetBinLookupTransfer,
  getGetLPNLookupTransfer,
  createItemTransfer,
} from 'services/api/item/item.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import {
  Modal,
  Box,
  Typography,
  Autocomplete,
  TextField,
  Grid as MUIGrid,
  Button,
} from '@mui/material';

import { autoCompleteTimeStamp } from '../../../helpers/autocompletetimestamp';
import { MUIContainer, MUIContent } from '../editreceiptmodal';

interface TransferBinModalProps {
  itemDetails?: any;
}

interface AutoCompleteOptionType {
  value: number;
  label: string;
}

enum TransferType {
  Pallet = 2,
  Eaches = 1,
}

export default React.memo(({ itemDetails }: TransferBinModalProps) => {
  const {
    isTransferV2ModalOpen,
    onCloseTransferV2Modal,
    onOpenConfirmationModal,
  } = useContext(GlobalContext);

  const navigate = useNavigate();

  const [transferDetails, setTransferDetails] = useState(undefined); // //FORMS
  const [transferFormErrors, setTransferFormErrors] = useState<any>({});
  const [optionTransfers, setOptionTransfers] = useState([]);
  const [optionTransferValue, setOptionTransferValue] = useState(null);
  const [optionFacilities, setOptionFacilities] = useState<
    CustomerFacilityLocationDropDownListItem[]
  >([]);
  const [optionFacilityValue, setOptionFacilityValue] =
    useState<CustomerFacilityLocationDropDownListItem>(null);
  const [areaDDL, setAreaDDL] = useState<DropDownListItemById[]>([]);
  const [optionAreas, setOptionAreas] = useState<DropdownListItem[]>([]);
  const [optionAreaValue, setOptionAreaValue] =
    useState<DropdownListItem>(null);
  const [optionLocations, setOptionLocations] = useState([]);
  const [optionLocationValue, setOptionLocationValue] = useState(null);
  const [optionReasons, setOptionReasons] = useState([]);
  const [optionReasonValue, setOptionReasonValue] = useState(null);
  const [optionLPNS, setOptionLPNS] = useState([]);
  const [optionLPNValue, setOptionLPNValue] = useState(null);
  const [isEach, setIsEach] = useState(false);
  const [hasArea, setHasArea] = useState(false);
  const [hasLPN, setHasLPN] = useState(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const [transferNotAvailableMessage, setTransferNotAvailableMessage] =
    useState<string>('');
  const [transferNotAvailableIsComplete, setTransferNotAvailableIsComplete] =
    useState<boolean>(false);

  const onLoadTransferDetails = async (
    customerLocationId,
    customerFacilityId,
    inventoryBinDetailId,
  ) => {
    try {
      const transferDetailsFromApi = await getTransferDetails(
        customerLocationId,
        customerFacilityId,
        inventoryBinDetailId,
      );

      return transferDetailsFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetBinLookupTransfer = async (zoneId, itemId) => {
    try {
      const getBinLookupFromApi = await getGetBinLookupTransfer(zoneId, itemId);

      return getBinLookupFromApi;
    } catch (err) {
      return err;
    }
  };

  const onLoadGetLPNLookupTransfer = async (zoneId, itemId) => {
    try {
      const transferDetailsFromApi = await getGetLPNLookupTransfer(
        zoneId,
        itemId,
      );

      return transferDetailsFromApi;
    } catch (err) {
      return err;
    }
  };

  const transferFormValidation = () => {
    const temp: any = {};
    if (optionTransferValue.value === TransferType.Eaches) {
      if (
        transferDetails.quantityToTransfer === undefined ||
        transferDetails.quantityToTransfer === ''
      ) {
        temp.quantityToTransfer = 'This field is required';
      } else {
        temp.quantityToTransfer = '';
      }
    }

    if (optionFacilityValue === null) {
      temp.facility = 'This field is required';
    } else {
      temp.facility = '';
    }

    if (optionAreaValue === null) {
      temp.area = 'This field is required';
    } else {
      temp.area = '';
    }

    if (hasArea) {
      if (optionLocationValue === null) {
        temp.location = 'This field is required';
      } else {
        temp.location = '';
      }
    }

    setTransferFormErrors({ ...temp });
    return Object.values(temp).every((x) => x === '');
  };

  const createNewTransfer = async (isComplete: boolean) => {
    setIsBusy(true);
    const PAYLOAD = {
      inventoryBinDetailId: itemDetails.inventoryBinDetailId,
      transferType: optionTransferValue?.value,
      toCustomerLocationId: optionFacilityValue?.customerLocationId,
      toCustomerFacilityId: optionFacilityValue?.value,
      toZoneId: optionAreaValue?.value,
      toBinId: optionLocationValue?.value,
      toPalletInventoryId: optionLPNValue?.value,
      qtyToTransfer: transferDetails?.quantityToTransfer,
      reason: optionReasonValue !== null ? optionReasonValue.value : '',
      isComplete,
    };

    try {
      await createItemTransfer(PAYLOAD);

      if (isComplete) {
        snackActions.success(`Transfer complete.`);
      } else {
        snackActions.success(
          `Successfully added ${transferDetails.sku} to transfer queue.`,
        );
      }
      setIsBusy(false);
      navigate(0);
    } catch (err: any) {
      snackActions.error(err);
      setIsBusy(false);
    }
  };

  const createNewTransferOnClick = async (e: any, isComplete: boolean) => {
    e.preventDefault();
    transferDetails.quantityToTransfer =
      transferDetails.quantityToTransfer <= 0
        ? ''
        : transferDetails.quantityToTransfer;

    if (!transferFormValidation()) {
      snackActions.error('Please complete required fields');
    } else if (
      itemDetails.status === ReceiptLineItemStatusOnArrivalEnum.Available
    ) {
      createNewTransfer(isComplete);
    } else {
      setTransferNotAvailableMessage(
        `You are transferring an item that is on ${itemDetails.statusStr} status.`,
      );
      setTransferNotAvailableIsComplete(isComplete);
      onOpenConfirmationModal();
    }
  };

  const transferNotAvailableCallback = async () => {
    createNewTransfer(transferNotAvailableIsComplete);
  };

  useEffect(() => {
    if (itemDetails !== null) {
      onLoadTransferDetails(
        itemDetails.customerLocationId,
        itemDetails.customerFacilityId,
        itemDetails.inventoryBinDetailId,
      )
        .then((x) => {
          const { transferTypeDDL, facilityDDL, reasonDDL } = x;
          setTransferDetails(x);
          setOptionTransfers(
            transferTypeDDL?.map((dropdown) => ({
              value: dropdown.value,
              label: dropdown.text,
            })),
          );

          const facilities = facilityDDL?.map(
            (dropdown: CustomerFacilityLocationDropDownListItem) => ({
              customerLocationId: dropdown.customerLocationId,
              customerLocation: dropdown.customerLocation,
              value: dropdown.value,
              text: dropdown.text,
            }),
          );
          setOptionFacilities(facilities);

          const areas = x.areaDDL?.map((dropdown: DropDownListItemById) => ({
            id: dropdown.id,
            value: dropdown.value,
            text: dropdown.text,
          }));
          setAreaDDL(areas);

          const facility = facilities.find(
            (f) => f.value === itemDetails.customerFacilityId,
          );
          if (facility !== undefined) {
            setOptionFacilityValue(facility);

            const areasById = areas?.filter((a) => a.id === facility.value);
            setOptionAreas(
              areasById?.map((dropdown: DropDownListItemById) => ({
                value: dropdown.value,
                text: dropdown.text,
              })),
            );
          }

          setOptionReasons(
            reasonDDL?.map((dropdown) => ({
              value: dropdown.value,
              label: dropdown.text,
            })),
          );

          const transferValue = transferTypeDDL
            ?.map((dropdown) => ({
              value: dropdown.value,
              label: dropdown.text,
            }))
            .filter((item) => item.value === x.transferType)[0];
          setOptionTransferValue(transferValue);
          if (transferValue.value === TransferType.Eaches) {
            setIsEach(true);
          } else {
            setIsEach(false);
          }
        })
        .catch((err) => snackActions.error(err));
    }
  }, [isTransferV2ModalOpen]);

  useEffect(() => {
    if (optionTransferValue !== null) {
      if (optionTransferValue.value === TransferType.Eaches) {
        setIsEach(true);
      } else {
        setIsEach(false);
      }
    } else {
      setIsEach(false);
    }
    // CLEAR TO:
    setOptionAreaValue(null);
    setOptionLocationValue(null);
    setOptionLPNValue(null);
    setHasLPN(false);
    setHasArea(false);
  }, [optionTransferValue]);

  useEffect(() => {
    if (optionAreaValue !== null) {
      onLoadGetBinLookupTransfer(
        optionAreaValue?.value,
        transferDetails?.itemId,
      )
        .then((x) => {
          setOptionLocations(
            x?.map((dropdown, idx) => ({
              id: idx,
              value: dropdown.binId,
              label: dropdown.bin,
              binItemTypeTransfer: dropdown.binItemTypeTransfer,
              binItemTypeTransferStr: dropdown.binItemTypeTransferStr,
            })),
          );
          if (x?.length !== 0) {
            setHasArea(true);
          } else {
            setHasArea(false);
          }
        })
        .catch((err) => snackActions.error(err));
    }
    setOptionLocationValue(null);
    setHasLPN(false);
  }, [optionAreaValue]);

  useEffect(() => {
    if (optionLocationValue !== null) {
      onLoadGetLPNLookupTransfer(
        optionLocationValue?.value,
        transferDetails?.itemId,
      )
        .then((x) => {
          if (
            optionLocationValue.binItemTypeTransfer === TransferType.Pallet &&
            optionTransferValue.value === TransferType.Eaches
          ) {
            setHasLPN(true);
            setOptionLPNS(
              x?.map((dropdown, idx) => ({
                id: idx,
                value: dropdown.palletInventoryId,
                label: dropdown.licensePlateNo,
              })),
            );
          } else {
            setHasLPN(false);
            setOptionLPNValue(null);
            setOptionLPNS([]);
          }
        })
        .catch((err) => snackActions.error(err));
    } else {
      setHasLPN(false);
      setOptionLPNValue(null);
      setOptionLPNS([]);
    }
  }, [optionLocationValue]);

  return (
    <>
      <Modal open={isTransferV2ModalOpen}>
        <MUIContainer sx={{ maxWidth: '800px !important' }}>
          <Typography
            className="modalTextHeader"
            variant="h6"
            fontWeight="bold"
          >
            Transfer
          </Typography>

          <MUIContent>
            <Box autoComplete="off" component="form" noValidate>
              <MUIGrid
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
                spacing={2}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <MUIGrid item xs={isEach ? 4 : 6} sm={isEach ? 4 : 6}>
                  <TextField
                    disabled
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="SKU"
                    value={transferDetails?.sku || ''}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={isEach ? 4 : 6} sm={isEach ? 4 : 6}>
                  <Autocomplete
                    onChange={(
                      event: any,
                      newValue: AutoCompleteOptionType,
                    ) => {
                      if (newValue !== null) {
                        setOptionTransferValue(newValue);
                      } else {
                        setOptionTransferValue(null);
                      }
                    }}
                    disabled={isBusy}
                    id="controllable-states"
                    options={optionTransfers}
                    getOptionLabel={(option: any) => option.label}
                    value={optionTransferValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option?.value}>
                        {option.label}
                      </li>
                    )}
                    {...(transferDetails?.binItemType === 1 && {
                      disabled: true,
                    })}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        id="outlined"
                        label="Transfer Type"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
                {isEach && (
                  <MUIGrid item xs={4} sm={4}>
                    <TextField
                      inputProps={{
                        autoComplete: autoCompleteTimeStamp().toString(),
                      }}
                      {...(transferFormErrors.quantityToTransfer && {
                        error: true,
                        helperText: transferFormErrors.quantityToTransfer,
                      })}
                      onChange={(e) => {
                        e.preventDefault();
                        const validateDecimal = decimalValidationHelper(
                          e.target.value,
                        );

                        setTransferDetails((prev) => ({
                          ...prev,
                          quantityToTransfer: validateDecimal,
                        }));
                      }}
                      onKeyPress={(e) =>
                        !/[0-9.]/.test(e.key) && e.preventDefault()
                      }
                      type="number"
                      disabled={isBusy}
                      required
                      autoComplete="off"
                      id="outlined-required"
                      label="Quantity to Transfer"
                      value={transferDetails?.quantityToTransfer || ''}
                      size="small"
                      sx={{ width: '100%' }}
                    />
                  </MUIGrid>
                )}

                <MUIGrid item xs={12} sm={12}>
                  <Typography
                    sx={{ marginBotton: '16px' }}
                    variant="h6"
                    fontWeight="bold"
                  >
                    From:
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={6} sm={6}>
                  <TextField
                    disabled
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Current Facility"
                    value={transferDetails?.currentFacility || ''}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={6} sm={6}>
                  <TextField
                    disabled
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Current Zone"
                    value={transferDetails?.currentZone || ''}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={6} sm={6}>
                  <TextField
                    disabled
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Current Location"
                    value={transferDetails?.currentLocation || ''}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={6} sm={6}>
                  <TextField
                    disabled
                    inputProps={{
                      autoComplete: autoCompleteTimeStamp().toString(),
                    }}
                    autoComplete="off"
                    id="outlined"
                    label="Available Qty"
                    value={transferDetails?.currentQtyAvailable || ''}
                    size="small"
                    sx={{ width: '100%' }}
                  />
                </MUIGrid>
                <MUIGrid item xs={12} sm={12}>
                  <Typography
                    sx={{ marginBotton: '16px' }}
                    variant="h6"
                    fontWeight="bold"
                  >
                    To:
                  </Typography>
                </MUIGrid>
                <MUIGrid item xs={4} sm={4}>
                  <Autocomplete
                    onChange={(
                      event,
                      newValue: CustomerFacilityLocationDropDownListItem,
                    ) => {
                      setOptionFacilityValue(newValue);
                      setOptionAreaValue(null);
                      setHasArea(false);

                      if (newValue !== null) {
                        const areasById = areaDDL?.filter(
                          (a) => a.id === newValue.value,
                        );
                        setOptionAreas(
                          areasById?.map((dropdown: DropDownListItemById) => ({
                            value: dropdown.value,
                            text: dropdown.text,
                          })),
                        );
                      } else {
                        setOptionAreas([]);
                      }
                    }}
                    disabled={isBusy}
                    id="controllable-states"
                    options={optionFacilities}
                    getOptionLabel={(
                      option: CustomerFacilityLocationDropDownListItem,
                    ) => option.text}
                    value={optionFacilityValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(
                      props,
                      option: CustomerFacilityLocationDropDownListItem,
                    ) => (
                      <li {...props} key={option.value}>
                        <Box display="flex">
                          <Box display="flex" flexDirection="column">
                            <Typography color="text.primary">
                              {option.text}
                            </Typography>
                            <Typography color="text.secondary" variant="body2">
                              {option.customerLocation}
                            </Typography>
                          </Box>
                        </Box>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        {...(transferFormErrors.facility && {
                          error: true,
                          helperText: transferFormErrors.facility,
                        })}
                        required
                        id="outlined-required"
                        label="Facility"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
                <MUIGrid item xs={4} sm={4}>
                  <Autocomplete
                    onChange={(event, newValue: DropdownListItem) => {
                      if (newValue !== null) {
                        setOptionAreaValue(newValue);
                      } else {
                        setOptionAreaValue(null);
                        setHasArea(false);
                      }
                    }}
                    disabled={optionFacilityValue === null || isBusy}
                    id="controllable-states"
                    options={optionAreas}
                    getOptionLabel={(option: DropdownListItem) => option.text}
                    value={optionAreaValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option: DropdownListItem) => (
                      <li {...props} key={option?.value}>
                        {option.text}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        {...(transferFormErrors.area && {
                          error: true,
                          helperText: transferFormErrors.area,
                        })}
                        required
                        id="outlined-required"
                        label="Area"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
                <MUIGrid item xs={4} sm={4}>
                  <Autocomplete
                    onChange={(event: any, newValue: any) => {
                      if (newValue !== null) {
                        setOptionLocationValue(newValue);
                      } else {
                        setOptionLocationValue(null);
                      }
                    }}
                    id="controllable-states"
                    options={optionLocations}
                    getOptionLabel={(option: any) => option.label}
                    value={optionLocationValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option?.id}>
                        {option.label}
                      </li>
                    )}
                    disabled={!hasArea || isBusy}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: autoCompleteTimeStamp().toString(),
                        }}
                        autoComplete="off"
                        {...(transferFormErrors.location && {
                          error: true,
                          helperText: transferFormErrors.location,
                        })}
                        required
                        id="outlined-required"
                        label="Location"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
                {hasLPN && (
                  <MUIGrid item xs={4} sm={4}>
                    <Autocomplete
                      onChange={(
                        event: any,
                        newValue: AutoCompleteOptionType,
                      ) => {
                        if (newValue !== null) {
                          setOptionLPNValue(newValue);
                        } else {
                          setOptionLPNValue(null);
                        }
                      }}
                      disabled={isBusy}
                      id="controllable-states"
                      options={optionLPNS}
                      getOptionLabel={(option: AutoCompleteOptionType) =>
                        option.label
                      }
                      value={optionLPNValue || null}
                      isOptionEqualToValue={(option, selected) =>
                        option.value === selected.value
                      }
                      renderOption={(props, option) => (
                        <li {...props} key={option?.value}>
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: autoCompleteTimeStamp().toString(),
                          }}
                          autoComplete="off"
                          id="outlined"
                          label="LPN"
                          size="small"
                          sx={{ width: '100%' }}
                        />
                      )}
                    />
                  </MUIGrid>
                )}

                <MUIGrid item xs={4} sm={4}>
                  <Autocomplete
                    onChange={(event: any, newValue: any) => {
                      if (newValue !== null) {
                        setOptionReasonValue(newValue);
                      } else {
                        setOptionReasonValue(null);
                      }
                    }}
                    disabled={isBusy}
                    id="controllable-states"
                    options={optionReasons}
                    getOptionLabel={(option: any) => option.label}
                    value={optionReasonValue || null}
                    isOptionEqualToValue={(option, selected) =>
                      option.value === selected.value
                    }
                    renderOption={(props, option) => (
                      <li {...props} key={option?.id}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        aria-readonly
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        {...(transferFormErrors.reason && {
                          error: true,
                          helperText: transferFormErrors.reason,
                        })}
                        id="outlined-required"
                        label="Reason"
                        size="small"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </MUIGrid>
              </MUIGrid>
            </Box>
          </MUIContent>

          <MUIGrid
            container
            direction="row"
            justifyContent="flex-end"
            sx={{
              borderTop: '1px solid #dbdbdb',
              width: '100% !important',
              marginLeft: '1px !important',
            }}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Box
                sx={{
                  display: 'flex',
                  gap: '8px',
                  boxShadow: 'none',
                  margin: '20px',
                  padding: '0px',
                }}
              >
                <Button
                  onClick={() => {
                    onCloseTransferV2Modal();
                    // CLEAR TO:
                    setOptionAreaValue(null);
                    setOptionLocationValue(null);
                    setOptionLPNValue(null);
                    setHasLPN(false);
                  }}
                  disabled={isBusy}
                  variant="outlined"
                  size="large"
                  sx={{ padding: 1, width: '100%' }}
                >
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    createNewTransferOnClick(e, false);
                  }}
                  disabled={isBusy}
                >
                  QUEUE
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: 1,
                    width: '100%',
                    backgroundColor: ' rgb(75, 126, 254) !important',
                  }}
                  onClick={(e) => {
                    createNewTransferOnClick(e, true);
                  }}
                  disabled={isBusy}
                >
                  COMPLETE
                </Button>
              </Box>
            </MUIGrid>
          </MUIGrid>
        </MUIContainer>
      </Modal>

      <ConfirmationModal
        dialogText={transferNotAvailableMessage}
        callBack={transferNotAvailableCallback}
      />
    </>
  );
});
