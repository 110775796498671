import React, { useCallback, useContext, useEffect, useState } from 'react';

import { RoleEnum } from 'common/enums';
import { UserPermissionEnum } from 'common/enums/enum-user-permissions';
import { snackActions } from 'config/snackbar.js';
import { useWarehouseCustomerContext } from 'pages/ordercustomers/warehousecustomer';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import { saveManageUserPermission } from 'services/api/user/users.api';
import { GlobalContext } from 'store/contexts/GlobalContext';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';

import {
  CheckboxState,
  ItemState,
  defaultItemStates,
  useUsersContext,
  userPermissionsTreeView,
} from '../context';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  backgroundColor: 'white',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
    borderBottom: 2,
  },
}));

type IPermission = {
  value?: number;
  isChecked?: boolean;
};

export type Item = {
  value: number;
  label: string;
  parentId: number;
  lastNode?: boolean;
};

export enum PageUsedEnum {
  UserListPage = 1,
  ClientPortal = 2,
  ClientPortalActivateNewAccount = 3,
}
const updateItemStates = (
  oldState: ItemState[],
  items: Item[],
  clickedId: number,
) => {
  const newState = oldState.map((i) => ({ ...i }));
  // getters
  const getItemState = (value: number) =>
    newState.find((i) => i.value === value).state;
  // setters
  const updateParent = (value: number) => {
    const item = items.find((i) => i.value === value);
    const parent = items.find((i) => i.value === item.parentId);
    if (!parent) return;
    const childIds = items
      .filter((i) => i.parentId === parent.value)
      .map((i) => i.value);
    const childStates = childIds.map((childId) => getItemState(childId));
    if (
      childStates.length ===
      childStates.filter((s) => s === CheckboxState.CHECKED).length
    ) {
      newState.find((i) => i.value === parent.value).state =
        CheckboxState.CHECKED;
      newState.find((i) => i.value === parent.value).isUserPermissionChecked =
        true;
    } else if (
      childStates.length ===
      childStates.filter((s) => s === CheckboxState.UNCHECKED).length
    ) {
      newState.find((i) => i.value === parent.value).state =
        CheckboxState.UNCHECKED;
      newState.find((i) => i.value === parent.value).isUserPermissionChecked =
        false;
    } else {
      newState.find((i) => i.value === parent.value).state =
        CheckboxState.INDETERMINATE;
      newState.find((i) => i.value === parent.value).isUserPermissionChecked =
        true;
    }
    updateParent(parent.value);
  };
  const setUnchecked = (value: number) => {
    newState.find((i) => i.value === value).state = CheckboxState.UNCHECKED;
    newState.find((i) => i.value === value).isUserPermissionChecked = false;
    items
      .filter((i) => i.parentId === value)
      .map((i) => i.value)
      .forEach((childId) => setUnchecked(childId));
    updateParent(value);
  };
  const setChecked = (value: number) => {
    newState.find((i) => i.value === value).state = CheckboxState.CHECKED;
    newState.find((i) => i.value === value).isUserPermissionChecked = true;
    items
      .filter((i) => i.parentId === value)
      .map((i) => i.value)
      .forEach((childId) => setChecked(childId));
    updateParent(value);
  };
  // actual logic
  const itemState = getItemState(clickedId);
  if (itemState === CheckboxState.CHECKED) {
    setUnchecked(clickedId);
  } else {
    setChecked(clickedId);
  }
  return newState;
};

type CheckboxListProps = {
  items: Item[];
  idsToRender?: number[];
  indentLevel?: number;
  onClick?: (id: number) => void;
  getStateForId: (id: number) => CheckboxState;
  expandAll?: boolean;
};

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  getStateForId,
  idsToRender,
  indentLevel = 0,
  onClick,
  expandAll,
}) => {
  if (!idsToRender?.length && items?.length > 0) {
    // eslint-disable-next-line no-param-reassign
    idsToRender = items?.filter((i) => !i.parentId).map((i) => i.value);
  }

  const getChildNodes = (parentId: number) => {
    const nodeItems = items.filter((i) => i.parentId === parentId);
    if (!nodeItems.length) return null;
    return (
      <CheckboxList
        items={items}
        idsToRender={nodeItems.map((i) => i.value)}
        indentLevel={indentLevel + 1}
        onClick={onClick}
        getStateForId={getStateForId}
        expandAll={expandAll}
      />
    );
  };

  const [expanded, setExpanded] = useState<string | false>(false);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      event.preventDefault();
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {idsToRender?.map((value, index) => {
        const item = items.find((i) => i.value === value);
        const checkboxState = getStateForId(value);

        return (
          <React.Fragment key={item.value}>
            {item.lastNode ? (
              <AccordionDetails
                sx={{
                  backgroundColor: 'white',
                  paddingLeft: indentLevel === 1 ? 2 * 3 : indentLevel * 3,
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
              >
                <Checkbox
                  onClick={() => onClick(item.value)}
                  checked={checkboxState === CheckboxState.CHECKED}
                  indeterminate={checkboxState === CheckboxState.INDETERMINATE}
                />
                {item.label}
              </AccordionDetails>
            ) : (
              <Accordion
                expanded={expandAll || expanded === index.toString()}
                onChange={handleChange(index.toString())}
                key={value}
                sx={{
                  boxShadow: 'none',
                  paddingLeft: indentLevel * 5,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={index.toString()}
                  id={index.toString()}
                  sx={{
                    width: '100%',
                    borderTop: item.parentId === 0 ? '1px solid #dbdbdb' : '',
                    borderBottom:
                      item.parentId === 0 ? '1px solid #dbdbdb' : '',
                    backgroundColor: item.parentId === 0 ? '#f8f8f8' : '',
                  }}
                >
                  <Checkbox
                    onClick={() => onClick(item.value)}
                    checked={checkboxState === CheckboxState.CHECKED}
                    indeterminate={
                      checkboxState === CheckboxState.INDETERMINATE
                    }
                  />
                  <p style={{ alignSelf: 'center' }}> {item.label}</p>
                </AccordionSummary>
                {getChildNodes(item.value)}
              </Accordion>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Tree = (expandAll) => {
  const {
    userPermissionStates,
    setUserPermissionStates,
    filteredUserPermissionsTreeView,
  } = useContext(GlobalContext);

  const getStateForId = useCallback(
    // eslint-disable-next-line react/destructuring-assignment
    (value: number) =>
      userPermissionStates.find((i) => i.value === value).state,
    [userPermissionStates],
  );
  const clickHandler = useCallback(
    (value) =>
      setUserPermissionStates(
        updateItemStates(
          userPermissionStates,
          filteredUserPermissionsTreeView,
          value,
        ),
      ),
    [userPermissionStates],
  );

  return (
    <CheckboxList
      items={filteredUserPermissionsTreeView}
      onClick={clickHandler}
      getStateForId={getStateForId}
      // eslint-disable-next-line react/destructuring-assignment
      expandAll={expandAll.expandAll}
    />
  );
};

export default React.memo(() => {
  const {
    userPermission,
    pageUsedPermission,
    usersPermissionModal,
    userPermissionStates,
    setUsersPermissionModal,
    setUserPermissionStates,
    setFilteredUserPermissionsTreeView,
  } = useContext(GlobalContext);

  const { viewLoadData, setPermissionWarehouseCustomerValue } =
    useWarehouseCustomerContext();
  const { onLoadCustomerData } = useUsersContext();

  const [expandAll, setExpandAll] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const handleChangeAll = (value) => {
    setExpandAll(value);
  };

  const handleCloseUsersPermissionModal = () => {
    setUsersPermissionModal(false);
    setIsSubmitLoading(false);
  };

  const handleSaveUserPermissions = async () => {
    if (userPermission === null) {
      snackActions.error('User not found!');
      return;
    }

    if (pageUsedPermission === PageUsedEnum.ClientPortalActivateNewAccount) {
      if (
        userPermissionStates.filter((e) => e.isUserPermissionChecked === true)
          .length === 0
      ) {
        snackActions.error('Please select at least 1 permission');
        return;
      }
      setPermissionWarehouseCustomerValue(
        userPermissionStates
          .filter((e) => e.isUserPermissionChecked === true)
          .map((r) => r.value),
      );
      handleCloseUsersPermissionModal();
      return;
    }

    try {
      setIsSubmitLoading(true);
      const PAYLOAD = {
        userId: userPermission?.userId,
        userPermissionIds: userPermissionStates
          .filter((e) => e.isUserPermissionChecked === true)
          .map((r) => r.value),
      };
      await saveManageUserPermission(PAYLOAD);
      snackActions.success('User permission have successfully saved');
      setUserPermissionStates([...defaultItemStates]);
      handleCloseUsersPermissionModal();

      if (pageUsedPermission === PageUsedEnum.UserListPage) {
        onLoadCustomerData();
      } else if (pageUsedPermission === PageUsedEnum.ClientPortal) {
        viewLoadData();
      }
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (userPermission?.roleId === Number(RoleEnum.WarehouseCustomerAccount)) {
      const userPermissionsFiltered: Item[] = [];
      const permissionIdsWarehouseCustomer: number[] = [
        UserPermissionEnum.PurchaseOrder,
        UserPermissionEnum.PurchaseOrder_View,
        UserPermissionEnum.PurchaseOrder_Edit,
        UserPermissionEnum.PurchaseOrder_DocumentUpload,
        UserPermissionEnum.Inventory,
        UserPermissionEnum.Inventory_View,
        UserPermissionEnum.Orders,
        UserPermissionEnum.Orders_View,
        UserPermissionEnum.Orders_Edit,
        UserPermissionEnum.Orders_AddOrder,
        UserPermissionEnum.Orders_DocumentUpload,
        UserPermissionEnum.Orders_EditNotes,
        UserPermissionEnum.Orders_EditChecklist,
        UserPermissionEnum.Dashboard,
        UserPermissionEnum.Dashboard_View,
        UserPermissionEnum.Reports,
        UserPermissionEnum.Reports_View,
        UserPermissionEnum.Invoices,
        UserPermissionEnum.Invoices_View,
        UserPermissionEnum.Client,
        UserPermissionEnum.Client_Integration,
        UserPermissionEnum.Client_Integration_View,
        UserPermissionEnum.Client_Integration_Edit,
      ];
      const userPermissions = [...userPermissionsTreeView];

      permissionIdsWarehouseCustomer.forEach((x) => {
        const permission = userPermissions.find((z) => z.value === x);

        if (permission !== undefined) {
          userPermissionsFiltered.push(permission);
        }
      });

      setFilteredUserPermissionsTreeView(userPermissionsFiltered);
    } else {
      setFilteredUserPermissionsTreeView(userPermissionsTreeView);
    }
  }, [userPermission, usersPermissionModal]);

  return (
    <Modal open={usersPermissionModal}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          User Permissions
        </Typography>
        <MUIContent>
          <MUIGrid
            container
            direction="row"
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            mt={-5}
            mb={1}
            sx={{ placeContent: 'center' }}
          >
            <MUIGrid item xs={6} sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  padding: 1,
                  width: '80%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                  marginRight: '15px',
                  textTransform: 'none',
                }}
                onClick={() => handleChangeAll(false)}
              >
                <b>- </b> &nbsp; Collapse All
              </Button>
              <Button
                variant="contained"
                size="medium"
                sx={{
                  padding: 1,
                  width: '80%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                  textTransform: 'none',
                }}
                onClick={() => handleChangeAll(true)}
              >
                <b>+ </b> &nbsp; Expand All
              </Button>
            </MUIGrid>
          </MUIGrid>
          <Tree expandAll={expandAll} />
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
                alignItems: 'center',
                float: 'right',
              }}
            >
              {isSubmitLoading ? (
                <>
                  <CircularProgress />
                  <Typography variant="subtitle2">Please Wait</Typography>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => {
                      handleCloseUsersPermissionModal();
                    }}
                    variant="outlined"
                    size="medium"
                    sx={{ padding: 1, width: '100%' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    sx={{
                      padding: 1,
                      width: '100%',
                      backgroundColor: ' rgb(75, 126, 254) !important',
                    }}
                    onClick={async (e) => {
                      e.preventDefault();
                      handleSaveUserPermissions();
                    }}
                  >
                    Save
                  </Button>
                </>
              )}
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
