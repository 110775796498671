/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useContext } from 'react';

import { PageLoadStateEnum } from 'common/enums';
import { WarehouseCustomerInvoiceListGetModel } from 'common/models/invoices';
import Card from 'components/card';
import { REPORT_URL } from 'config/constants';
import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import InvoiceDialog from 'pages/ordercustomers/warehousecustomer/invoicedialog';
import InvoiceEmail from 'pages/ordercustomers/warehousecustomer/invoiceemail';
import { InvoiceDetailFormContextProvider } from 'pages/shared/invoicedetailform/context';
import { getExternalInvoiceById } from 'services/api/integrations/integrations.api';
import { updateInvoiceStatus } from 'services/api/invoice/invoice.api';
import { getInvoiceByCustomer } from 'services/api/warehousecustomer/warehouseCustomer.api';
import { AuthContext } from 'store/contexts/AuthContext';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { reportUrlVariable } from 'store/reporturl';
import { DatagridStandard } from 'styles';

import { Typography, Grid as MUIGrid, Button, Box, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridToolbar,
} from '@mui/x-data-grid';

import { useWarehouseCustomerContext } from '../..';
import {
  InvoiceInterfaceTabContextProvider,
  useInvoiceInterfaceTabContext,
} from './context';
import GenerateInvoiceModal from './modals/generateinvoicemodal';
import InvoiceDetailFormModal from './modals/invoicedetailformmodal';

function InvoiceInterface() {
  const { setInvoiceDateDialog, invoiceDateDialog, queryString } =
    useWarehouseCustomerContext();

  const { state, openInvoiceModal, editInvoice, deleteInvoice, viewLoadData } =
    useInvoiceInterfaceTabContext();

  const {
    onOpenInvoiceDialogModal,
    setIsInvoiceSendEmail,
    onOpenBillingReportModal,
    loading,
    updateLoading,
  } = useContext(GlobalContext);

  const { isWarehouseCustomerAccount } = useContext(AuthContext);

  const onHandleInvoiceView = async (
    e: React.SyntheticEvent,
    id: number,
    isEmail?: boolean,
  ) => {
    e.preventDefault();

    await getInvoiceByCustomer(id).then((result) => {
      setInvoiceDateDialog(() => ({
        ...invoiceDateDialog,
        invoiceId: id,
        invoiceNo: result.invoiceNo,
        invoiceDate: result.invoiceDate,
        date: result.date,
        companyName: result.companyName,
        address1: result.address1,
        address2: result.address2,
        city: result.city,
        state: result.state,
        zipCode: result.zipCode,
        billTo: {
          companyName: result.billToCompanyName,
          address1: result.billToAddress1,
          address2: result.billToAddress2,
          city: result.billToCity,
          state: result.billToState,
          zipCode: result.billToZipCode,
        },
        lineItems: result.descriptionDetails.map((v) => ({
          name: v.description,
          amount: v.amount,
        })),
        comment: result?.comment,
        subtotal: result?.subtotal,
        adjustmentAmount: result?.adjustmentAmount,
        total: result?.total,
      }));

      if (isEmail && isEmail === true) {
        setIsInvoiceSendEmail(true);
      } else {
        onOpenInvoiceDialogModal();
      }
    });
  };

  useEffect(() => {
    if (state.pageLoadState !== PageLoadStateEnum.LOAD_FINISH) {
      updateLoading(true);
    } else {
      updateLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, state.pageLoadState]);

  const handleUpdateInvoiceStatus = async (invoiceId, status) => {
    try {
      const response = await updateInvoiceStatus(invoiceId, status);
      viewLoadData();
    } catch (err) {
      snackActions.error(err);
    }
  };

  const handleInvoiceBillingReport = (invoiceId) => {
    const url = `${reportUrlVariable(
      REPORT_URL,
    )}/reports/invoice-billing-report?invoiceId=${invoiceId}`;

    window.open(url, '_blank', 'noreferrer');
  };

  const invoicesColumns: GridColumns<WarehouseCustomerInvoiceListGetModel> = [
    {
      field: 'actions',
      type: 'actions',
      width: 34,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (
        params: GridRowParams<WarehouseCustomerInvoiceListGetModel>,
      ) => [
        <GridActionsCellItem
          label="View"
          showInMenu
          onClick={(e) => onHandleInvoiceView(e, params.row.invoiceId)}
        />,
        <GridActionsCellItem
          label="Edit"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={() => editInvoice(params.row.invoiceId)}
        />,
        <GridActionsCellItem
          label="Delete"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={() => deleteInvoice(params.row.invoiceId)}
        />,
        <GridActionsCellItem
          label="Email"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={(e) => onHandleInvoiceView(e, params.row.invoiceId, true)}
        />,
        <GridActionsCellItem
          label="Void"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={(e) => handleUpdateInvoiceStatus(params.row.invoiceId, 4)}
        />,
        <GridActionsCellItem
          label="Submit"
          showInMenu
          disabled={isWarehouseCustomerAccount}
          onClick={(e) => handleUpdateInvoiceStatus(params.row.invoiceId, 1)}
        />,
        <GridActionsCellItem
          label="View Billing Details"
          showInMenu
          onClick={() => handleInvoiceBillingReport(params.row.invoiceId)}
        />,
      ],
    },
    {
      field: 'invoiceNo',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Invoice No.</Typography>
        </Box>
      ),
    },
    {
      field: 'date',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Invoice Date</Typography>
        </Box>
      ),
    },
    {
      field: 'billingPeriod',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Billing Period</Typography>
        </Box>
      ),
      renderCell: (params) => (
        <Typography>{`${moment(params.row.dateFrom).format(
          'MM/DD/YYYY',
        )} - ${moment(params.row.dateTo).format('MM/DD/YYYY')}`}</Typography>
      ),
    },
    {
      field: 'total',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Invoice Amount</Typography>
        </Box>
      ),
    },
    {
      field: 'status',
      minWidth: 200,
      flex: 1,
      disableColumnMenu: true,
      renderHeader: () => (
        <Box sx={{ gap: '5px' }}>
          <Typography>Status</Typography>
        </Box>
      ),
    },
    // {
    //   field: 'syncedInvoiceNo',
    //   minWidth: 200,
    //   flex: 1,
    //   disableColumnMenu: true,
    //   renderHeader: () => (
    //     <Box sx={{ gap: '5px' }}>
    //       <Typography>Synced Invoice No</Typography>
    //     </Box>
    //   ),
    //   renderCell: (
    //     // eslint-disable-next-line @typescript-eslint/no-explicit-any
    //     params: GridRenderCellParams<any, WarehouseCustomerInvoiceListGetModel>,
    //   ) => (
    //     // eslint-disable-next-line react/jsx-no-undef, jsx-a11y/anchor-is-valid
    //     <Link
    //       sx={{
    //         fontSize: 14,
    //         fontWeight: 600,
    //         color: '#303538',
    //         textDecoration: 'none',
    //       }}
    //       href="#"
    //       onClick={(e) => {
    //         e.preventDefault();
    //         handleViewInvoiceClick(params.row.syncedInvoiceId);
    //       }}
    //     >
    //       <Typography
    //         sx={{ cursor: 'pointer', color: [theme.palette.primary.main] }}
    //       >
    //         {params.row.syncedInvoiceNo}
    //       </Typography>
    //     </Link>
    //   ),
    // },
  ];

  return (
    <>
      <InvoiceDialog data={invoiceDateDialog} />
      <InvoiceEmail />
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          border: '0.5px solid #ebeaea',
          margin: '15px',
          padding: '12px !important',
        }}
      >
        <MUIGrid container spacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <MUIGrid item xs={6}>
            <Typography variant="subtitle1" fontWeight="bold">
              Invoices
            </Typography>
          </MUIGrid>
          {!isWarehouseCustomerAccount && (
            <MUIGrid item xs={6} sx={{ textAlignLast: 'right' }}>
              <Button
                sx={{
                  width: '150px',
                  whiteSpace: 'nowrap',
                }}
                variant="contained"
                size="small"
                disabled={queryString?.id === 'new'}
                onClick={() => {
                  openInvoiceModal();
                }}
              >
                Generate Invoice
              </Button>
              <Button
                sx={{
                  width: '150px',
                  marginLeft: '15px',
                }}
                variant="contained"
                size="small"
                disabled={queryString?.id === 'new'}
                onClick={() => onOpenBillingReportModal()}
              >
                Billing Report
              </Button>
            </MUIGrid>
          )}
          <MUIGrid item xs={12}>
            <DatagridStandard
              autoHeight
              rows={state.vm.invoices}
              columns={invoicesColumns}
              pageSize={15}
              density="compact"
              disableColumnSelector
              disableDensitySelector
              hideFooterSelectedRowCount
              rowsPerPageOptions={[15]}
              getRowId={() => Math.random()}
              components={{ Toolbar: GridToolbar }}
            />
          </MUIGrid>
        </MUIGrid>
      </Card>
    </>
  );
}

function InvoiceInterfaceTab() {
  return (
    <InvoiceDetailFormContextProvider>
      <InvoiceInterfaceTabContextProvider>
        <InvoiceInterface />
        <GenerateInvoiceModal />
        <InvoiceDetailFormModal />
      </InvoiceInterfaceTabContextProvider>
    </InvoiceDetailFormContextProvider>
  );
}

export default InvoiceInterfaceTab;
