import React, { useContext, memo, useEffect, useState, useRef } from 'react';

import { snackActions } from 'config/snackbar.js';
import moment from 'moment';
import { CarrierType } from 'pages/sales/salesorder/context';
import { MUIContainer, MUIContent } from 'pages/shared/editreceiptmodal';
import {
  getOrderCarriers,
  getOrderCarrierservices,
  updateCarrierAndServiceOrderFromPackAndShip,
} from 'services/api/integrations/shipment/shipment.api';
import { GlobalContext } from 'store/contexts/GlobalContext';
import { DatagridPremium } from 'styles/mui/datagrid/premium';

import {
  Modal,
  Typography,
  Grid as MUIGrid,
  Box,
  Button,
  TextField,
  Card,
  CardContent,
  Checkbox,
} from '@mui/material';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColumns,
} from '@mui/x-data-grid-premium';

import { usePackAndShipContext } from '../context';

export default memo(() => {
  const {
    isRatesToCompleteModalOpen,
    setIsRatesToCompleteModalOpen,
    ratesOptionData,
  } = useContext(GlobalContext);

  const { pickTicketFormObj, updatePackageToShipAndCompleteOnClick } =
    usePackAndShipContext();

  const [customPackageError, setCustomPackageError] = useState(null);
  const itemRef = useRef(null);

  const [selectedCarrier, setSelectedCarrier] = React.useState(null);
  const [selectedCarrierService, setSelectedCarrierService] =
    React.useState(null);

  const [bestAvailableCarrier, setBestAvailableCarrier] = React.useState(null);
  const [isBestAvailableCarrierSelected, setIsBestAvailableCarrierSelected] =
    React.useState(false);

  const [fastestCarrier, setFastestCarrier] = React.useState(null);
  const [isFastestCarrierSelected, setIsFastestCarrierSelected] =
    React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);

  const [optionCarriers, setOptionCarriers] = useState<
    CarrierType[] | null | []
  >([]);
  const [optionCarrierServices, setOptionCarrierServices] = useState<
    CarrierType[] | null | []
  >([]);

  const onLoadOrderCarriers = async (warehouseCustomerId: number) => {
    try {
      const carriersFromApi = await getOrderCarriers(warehouseCustomerId);
      return carriersFromApi;
    } catch (err) {
      return err;
    }
  };
  const onLoadOrderCarrierServices = async (carrierId: any) => {
    try {
      const carriersFromApi = await getOrderCarrierservices(carrierId);

      setOptionCarrierServices(
        carriersFromApi.map((c: any) => ({
          label: c.text,
          value: c.serviceCode,
          isShippingRate: c.isShippingRate,
          serviceCode: c.serviceCode,
        })),
      );

      return null;
    } catch (err) {
      return err;
    }
  };

  const RatesColumns: GridColumns = [
    {
      field: 'Carrier',
      flex: 1,
      minWidth: 250,
      renderHeader: () => <Typography fontWeight="bold">Carrier</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.carrier}
        </Typography>
      ),
    },
    {
      field: 'Service',
      flex: 1,
      minWidth: 200,
      renderHeader: () => (
        <Typography fontWeight="bold">Service Level</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.service}
        </Typography>
      ),
    },
    {
      field: 'DeliveryDays',
      minWidth: 120,
      renderHeader: () => (
        <Typography fontWeight="bold">Transit Days</Typography>
      ),
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          {params?.row?.deliveryDays}
        </Typography>
      ),
    },
    {
      field: 'Price',
      minWidth: 100,
      renderHeader: () => <Typography fontWeight="bold">Your Cost</Typography>,
      renderCell: (params) => (
        <Typography className="cellDatagridWrapper">
          ${params?.row?.price ? params?.row?.price.toFixed(2) : `0.00`}
        </Typography>
      ),
    },
  ];

  const handleCloseRatesToComplete = () => {
    setIsRatesToCompleteModalOpen(false);
  };

  const handleOnClickSaveRates = async (e?: any) => {
    e?.preventDefault();

    const PAYLOAD = {
      OrderId: pickTicketFormObj.orderNoId,
      CarrierId: selectedCarrier?.value,
      CarrierName: selectedCarrier?.label,
      CarrierService:
        selectedCarrierService?.isShippingRate === true
          ? selectedCarrierService.label
          : selectedCarrierService.serviceCode,
      IsShippingRate: selectedCarrier?.isShippingRate,
    };
    try {
      await updateCarrierAndServiceOrderFromPackAndShip(PAYLOAD).then(() => {
        updatePackageToShipAndCompleteOnClick(e);
      });
    } catch (err) {
      snackActions.error(err);
    }
  };

  useEffect(() => {
    if (isRatesToCompleteModalOpen && ratesOptionData) {
      const cheapest = ratesOptionData?.reduce((prev, curr) =>
        prev.price < curr.price ? prev : curr,
      );
      setBestAvailableCarrier(cheapest);

      const fastest = ratesOptionData?.reduce((prev, curr) =>
        prev.deliveryDays < curr.deliveryDays ? prev : curr,
      );
      setFastestCarrier(fastest);
      const optCarriers = onLoadOrderCarriers(
        pickTicketFormObj.warehouseCustomerId ?? 0,
      );
      optCarriers.then((opt) => {
        setOptionCarriers(
          opt.map((c: any) => ({
            label: c.text,
            value: c.value,
            isShippingRate: c.isShippingRate,
          })),
        );
      });
    }
  }, [isRatesToCompleteModalOpen, ratesOptionData]);

  // CARRIER GETTING THE ID
  useEffect(() => {
    if (
      isBestAvailableCarrierSelected ||
      isFastestCarrierSelected ||
      selectionModel.length > 0
    ) {
      let getCarrier;
      if (isBestAvailableCarrierSelected) {
        getCarrier = optionCarriers.find(
          (c: any) => c.label === bestAvailableCarrier?.carrier,
        );
        setSelectedCarrier(getCarrier);
      } else if (isFastestCarrierSelected) {
        getCarrier = optionCarriers.find(
          (c: any) => c.label === fastestCarrier?.carrier,
        );
        setSelectedCarrier(getCarrier);
      } else if (selectionModel.length > 0) {
        const selected = ratesOptionData?.find(
          (c: any) => c.id === selectionModel[0],
        );
        getCarrier = optionCarriers.find(
          (c: any) => c.label === selected?.carrier,
        );
        setSelectedCarrier(getCarrier);
      }

      if (getCarrier?.value) {
        onLoadOrderCarrierServices(getCarrier?.value);
      }
    }
  }, [
    isBestAvailableCarrierSelected,
    isFastestCarrierSelected,
    selectionModel,
  ]);

  // CARRIER SERVICE GETTING THE ID
  useEffect(() => {
    if (selectedCarrier?.value && optionCarrierServices.length > 0) {
      if (isBestAvailableCarrierSelected) {
        const getCarrierService = optionCarrierServices.find(
          (c: any) => c.serviceCode === bestAvailableCarrier?.service,
        );
        setSelectedCarrierService(getCarrierService);
      } else if (isFastestCarrierSelected) {
        const getCarrierService = optionCarrierServices.find(
          (c: any) => c.serviceCode === fastestCarrier?.service,
        );
        setSelectedCarrierService(getCarrierService);
      } else if (selectionModel.length > 0) {
        const selected = ratesOptionData?.find(
          (c: any) => c.id === selectionModel[0],
        );
        const getCarrierService = optionCarrierServices.find(
          (c: any) => c.serviceCode === selected?.service,
        );
        setSelectedCarrierService(getCarrierService);
      }
    }
  }, [optionCarrierServices, selectedCarrier]);

  if (!ratesOptionData) {
    return null;
  }

  return (
    <Modal open={isRatesToCompleteModalOpen}>
      <MUIContainer>
        <Typography className="modalTextHeader" variant="h6" fontWeight="bold">
          Select from the available options below
        </Typography>
        <MUIContent sx={{ padding: '0px 20px !important' }}>
          <Typography variant="subtitle1" fontWeight="bold">
            They are no rates found based on your selection. Select from the
            available options below
          </Typography>
          <MUIGrid
            container
            direction="row"
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <MUIGrid item xs={6}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={isBestAvailableCarrierSelected}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setIsBestAvailableCarrierSelected(event.target.checked);
                        if (event.target.checked) {
                          setIsFastestCarrierSelected(false);
                          setSelectionModel([]);
                        }
                      }}
                    />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Best Available
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '0px 15px',
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {bestAvailableCarrier?.carrier} -{' '}
                      {bestAvailableCarrier?.service}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      $
                      {bestAvailableCarrier?.price
                        ? bestAvailableCarrier?.price.toFixed(2)
                        : `0.00`}
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ marginLeft: '15px' }}>
                    Days in Transit: {bestAvailableCarrier?.deliveryDays} days
                  </Typography>
                  <Typography variant="subtitle2" sx={{ marginLeft: '15px' }}>
                    Est. Delivery Date:{' '}
                    {moment()
                      .add(
                        bestAvailableCarrier?.deliveryDays
                          ? bestAvailableCarrier?.deliveryDays
                          : 0,
                        'days',
                      )
                      .format('MM-DD-YYYY')}
                  </Typography>
                </CardContent>
              </Card>
            </MUIGrid>
            <MUIGrid item xs={6}>
              <Card>
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                      color="primary"
                      size="small"
                      checked={isFastestCarrierSelected}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        setIsFastestCarrierSelected(event.target.checked);
                        if (event.target.checked) {
                          setIsBestAvailableCarrierSelected(false);
                          setSelectionModel([]);
                        }
                      }}
                    />
                    <Typography variant="subtitle1" fontWeight="bold">
                      Fastest
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: '0px 15px',
                    }}
                  >
                    <Typography variant="h6" fontWeight="bold">
                      {fastestCarrier?.carrier} - {fastestCarrier?.service}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold">
                      $
                      {fastestCarrier?.price
                        ? fastestCarrier?.price.toFixed(2)
                        : `0.00`}
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" sx={{ marginLeft: '15px' }}>
                    Days in Transit: {fastestCarrier?.deliveryDays} days
                  </Typography>
                  <Typography variant="subtitle2" sx={{ marginLeft: '15px' }}>
                    Est. Delivery Date:{' '}
                    {moment()
                      .add(
                        fastestCarrier?.deliveryDays
                          ? fastestCarrier?.deliveryDays
                          : 0,
                        'days',
                      )
                      .format('MM-DD-YYYY')}
                  </Typography>
                </CardContent>
              </Card>
            </MUIGrid>
            <MUIGrid item xs={12}>
              <Typography variant="subtitle1" sx={{ marginTop: '25px' }}>
                More Options:
              </Typography>
              <DatagridPremium
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel((prevModel) =>
                    newSelectionModel.filter(
                      (newId) => !prevModel.includes(newId),
                    ),
                  );

                  if (newSelectionModel.length > 0) {
                    setIsBestAvailableCarrierSelected(false);
                    setIsFastestCarrierSelected(false);
                  }
                }}
                autoHeight
                rows={ratesOptionData}
                density="compact"
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                columns={RatesColumns}
                hideFooterPagination
                hideFooter
                getRowId={(row) => row?.id}
                sx={{
                  '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer':
                    {
                      display: 'none',
                    },
                }}
              />
            </MUIGrid>
          </MUIGrid>
        </MUIContent>

        <MUIGrid
          container
          direction="row"
          justifyContent="flex-end"
          sx={{
            borderTop: '1px solid #dbdbdb',
            width: '100% !important',
            marginLeft: '1px !important',
          }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <MUIGrid item xs={4}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                boxShadow: 'none',
                margin: '20px',
                padding: '0px',
              }}
            >
              <Button
                onClick={() => handleCloseRatesToComplete()}
                variant="outlined"
                size="small"
                sx={{ padding: 1, width: '100%' }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                size="small"
                sx={{
                  padding: 1,
                  width: '100%',
                  backgroundColor: ' rgb(75, 126, 254) !important',
                }}
                onClick={(e) => {
                  handleOnClickSaveRates(e);
                }}
              >
                Confirm
              </Button>
            </Box>
          </MUIGrid>
        </MUIGrid>
      </MUIContainer>
    </Modal>
  );
});
